import React, {useState, Fragment} from "react";
import {Switch} from "@headlessui/react";


export default function Question ({question_data, list, setMethod, parent}) {
    const [enabled, setEnabled] = useState(false)

    function get_parent(sections, parent_id) {
        return sections.find(function (sec) {
            return sec.id === parent_id
        })
    }

    function change_answer(answer) {
        const section = get_parent(list, parent)
        const section_index = list.indexOf(section)

        let question = get_parent(section.questions, question_data.id)
        const question_index = section.questions.indexOf(question)

        question.answer = answer

        section.questions[question_index] = question

        list[section_index] = section
        setMethod(list)
    }

    return (
        <div className="col-md-6 col-sm-12 mb-2">
            <div className="form-group">
                <div className="grid grid-cols-2">
                    <div className="col-md-6 col-sm-12">
                        <label htmlFor="firstName1 text-upper text-up mb--3 text-bold">
                            {question_data.question}:
                        </label>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        {
                            question_data.type === "dropdown" ?
                                <div className="col-md-12">
                                    <select id={question_data.id}
                                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-green-600 sm:text-sm sm:leading-6"
                                            defaultValue="Choose..."
                                            onChange={value => {
                                                change_answer(value)
                                            }}>
                                        {
                                            question_data.options.map((option, x) => {
                                                return <option key={x} value={option}>{option}</option>
                                            })}
                                    </select>

                                </div>
                                : null
                        }
                        {
                            question_data.type === "text" ?
                                <div className="col-md-6 col-sm-12">
                                    <input className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-green-600 sm:text-sm sm:leading-6"
                                           id={question_data.id}
                                           placeholder={question_data.question}
                                           onChange={event => {
                                               change_answer(event.target.value)
                                           }}
                                           style={{width: "100%"}}
                                    />
                                </div>
                                : null
                        }
                        {
                            question_data.type === "switch" ? 
                                <Switch checked={enabled} onChange={(checked) => {
                                    setEnabled(checked);
                                    change_answer(checked ? " " : "not completed");
                                }} as={Fragment}>
                                    {({checked}) => (
                                        <button
                                            className={`${
                                                checked ? 'bg-green-600' : 'bg-gray-200'
                                            } relative inline-flex h-6 w-11 items-center rounded-full`}
                                        >
                                            <span className="sr-only">Enable notifications</span>
                                            <span
                                                className={`${
                                                    checked ? 'translate-x-6' : 'translate-x-1'
                                                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                            />
                                        </button>
                                    )}
                                </Switch>
                            : null
                        }
                        {
                            question_data.type === "slider" ?
                                <div className="col-md-12 col-sm-12 -mt-3">
                                    <div className="col-md-12">
                                        <select id={question_data.id}
                                                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-green-600 sm:text-sm sm:leading-6"
                                                defaultValue="Choose..."
                                                onChange={value => {
                                                    change_answer(value)
                                                }}>
                                            {
                                                Object.entries(question_data.marks).map(([key, value]) => {
                                                    return <option key={key} value={key}>{value}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                : null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
