import React, {useEffect, useState} from "react";
import {ReferralForm} from "components/Forms";
import {useParams} from "react-router-dom";
import {useClavkooForms} from "components/Hooks/FormHook";
import axios from "axios";
import {CMS_URLS, PATIENT_URLS} from "components/Api/Config";
import * as Sentry from "@sentry/react";
import ReferralRecord from "pages/patient/refer/ReferralRecord";
import {Breadcrumbs} from "components/Breadcrumbs";
import Loader from "components/Loader";
import {FORMS} from "../../../constants/forms";

const Refer = () => {
    let {id} = useParams()
    const [breadcrumbs, setBreadcrumbs] = useState([])
    const [exists, setExists] = useState(false)
    let {recordExists} = useClavkooForms()
    const [loading, setLoading] = useState(true)
    const [record, setRecord] = useState()

    function LoadBreadcrumbs(){
        axios.get(CMS_URLS.USERS.DETAILS(id))
            .then(res => {
                setBreadcrumbs([{'detail': 'Patients', 'link': '/patients'},
                    {'detail': `${res.data?.first_name} ${res.data?.last_name}`, 'link': `/profile/${id}`},
                    {'detail': 'Patient Details', 'link': `/refer/${id}`}])
            })
            .catch(err => Sentry.captureException(err))
    }

    useEffect(() => {
        LoadBreadcrumbs()
        axios.get(PATIENT_URLS.PATIENT_RECORD(id, FORMS.PATIENT_DETAILS))
            .then(res => {
                setExists(recordExists(res.data))
                setRecord(res.data)
            })
            .catch(err => Sentry.captureException(err))
            .finally(() => setLoading(false))
    }, [id])
    return (
        <>
            <Breadcrumbs items={breadcrumbs}/>
            {
                loading ? <Loader/> : exists ? <ReferralRecord patient_record={record}/> :
                    <ReferralForm patient_id={id}/>
            }
        </>
    )
}
export default Refer;
