import React, {useState} from "react";
import {Button, Card, CardBody, Col, Container, Row} from "reactstrap";
import clsx from "clsx";
import Loki from "react-loki";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileMedicalAlt, faSignature} from '@fortawesome/free-solid-svg-icons'
import {Signature} from "../../SignaturePad/signature";
import {PsychiatricHxInfo} from "./psychiatricHxInfo";
import problem_questions from "../ClinicalExam/questions/problem_list.json";
import {BaseModel} from "../../models/BaseModel";
import axios from "axios";
import {PATIENT_URLS} from "../../Api/Config";
import {ErrorMsg, SuccessMsg} from "../../Notification";
import * as Sentry from "@sentry/react";
import {useAuthentication} from "../../Hooks/AuthHook";
import {ScreeningForm} from "components/Forms/Intake/screeningInfo";
import screening_questions from "components/Forms/Intake/questions/screening.json";
import {DiagnosisInfo} from "components/Forms/evaluation/diagnosisInfo";
import RiskAssessmentInfo from "components/Forms/evaluation/riskAssessment";
import risk_list from './questions/risk_list.json'

export const EvaluationForm = ({patient_id}) => {
    const [problemList, setProblemList] = useState(problem_questions);
    const [fillingUser, setUser] = useState({});
    const [psych, setPsych] = useState([]);
    const [screeningList, setScreeningList] = useState(screening_questions)
    const [riskList, setRiskList] = useState(risk_list)

    let {user} = useAuthentication()

    const formSteps = [
        {
            number: '1',
            label: 'Screening Questions',
            description: 'Fill in screening questions',
            component: <ScreeningForm patient_id={patient_id} list={screeningList} setMethod={setScreeningList}/>,
            icon: <FontAwesomeIcon icon={faFileMedicalAlt} size={'3x'}/>
        },
        {
            number: '2',
            label: 'Past Psychiatric Hx',
            description: 'Fill in patient information',
            component: <PsychiatricHxInfo psych={psych} setMethod={setPsych}/>,
            icon: <FontAwesomeIcon icon={faFileMedicalAlt} size={'3x'}/>
        },
        {
            number: '3',
            label: 'Diagnosis',
            description: 'Fill in patient diagnosis',
            component: <DiagnosisInfo patient_id={patient_id} psych={psych} setMethod={setPsych}/>,
            icon: <FontAwesomeIcon icon={faFileMedicalAlt} size={'3x'}/>
        },
        {
            number: '4',
            label: 'Risk Assessment',
            description: 'Fill in patient risk assessment',
            component: <RiskAssessmentInfo list={riskList} setMethod={setRiskList}/>,
            icon: <FontAwesomeIcon icon={faFileMedicalAlt} size={'3x'}/>
        },
        {
            number: '5',
            label: 'Confirmation',
            description: 'Sign & Confirm Referral',
            component: <Signature setUser={setUser}/>,
            icon: <FontAwesomeIcon icon={faSignature} size={'3x'}/>
        }
    ];


    const _customRenderer = ({currentStep}) => {
        const steps = formSteps.map((step, index) => {
            const isActive = currentStep === index + 1;
            return <Col key={index} lg="6" xl="2"><Card
                                            className={clsx("card-box card-stats mb-4 mb-xl-0", {'border border-primary': isActive})}><a
                href="#" onClick={e => e.preventDefault()}>
                <CardBody>
                    <Row>
                        <div className="col">
                            <div className="step-indicator">
                                <div className="d-flex">
                                    <div>
                                        <div className="font-weight-bold">{step.label}</div>
                                        <small className="text-black-50">{step.description}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Col className="col-auto">
                            {step.icon}
                        </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">

                    </p>
                </CardBody>
            </a></Card></Col>
        });

        return <div className="header pb-3 pt-5 pt-md-8">
            <Container fluid>
                <div className="header-body">
                    <Row className="justify-content-center align-self-center">
                        {steps}
                    </Row>
                </div>
            </Container>
        </div>
    };

    const _customActions = ({isComplete, cantBack, isInFinalStep, backHandler, nextHandler}) => {
        return (
            <div className="actions p-4">
                <Button outline color="primary" onClick={backHandler} disabled={cantBack || isComplete}>
                    Previous
                </Button>
                <Button className="float-right" color={!isInFinalStep ? 'primary' : 'success'} onClick={nextHandler}
                        disabled={isComplete}>
                    {isInFinalStep ? 'Finish' : 'Next'}
                </Button>
            </div>
        );
    };

    const _onFinish = () => {
        if (fillingUser !== null) {

            const patient_record = {
                records: [].concat(problemList).concat(psych),
                ...new BaseModel(user.id_number, user.first_name).getBase(),
            }

            axios.post(PATIENT_URLS.PATIENT_RECORD(patient_id, 8), patient_record)
                .then(() => {
                    SuccessMsg('Record Saved.')
                    window.location.reload()
                })
                .catch(error => {
                    Sentry.captureException(error);
                    ErrorMsg('We could not save your record. Please consult admin.')
                })
        }
    };

    return (
        <>
            <div className="wizard-alternate-2 horizontal" style={{marginTop: '-5em'}}>
                <Loki steps={formSteps}
                      renderSteps={_customRenderer}
                      renderActions={_customActions}
                      onFinish={_onFinish}/>
            </div>
        </>
    );
}