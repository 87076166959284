import React, {useState} from "react";
import {Button, Card, CardBody, Col, Container, Row} from "reactstrap";
import clsx from "clsx";
import Loki from "react-loki";
import {PatientInfo} from "./patientInfo";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileMedicalAlt, faHeartbeat, faHandHoldingMedical, faSignature} from '@fortawesome/free-solid-svg-icons'
import {Signature} from "components/SignaturePad/signature";
import {BaseModel} from "components/models/BaseModel";
import {PATIENT_URLS} from "components/Api/Config";
import {ErrorMsg, SuccessMsg} from "components/Notification";
import medical_list from "components/Forms/ClinicalExam/questions/medical_list.json";
import problem_list from "components/Forms/ClinicalExam/questions/problem_list.json";
import medical_aid from "components/Forms/MedicalAid/questions/medical_aid.json"
import {MedicalConditionForm} from "components/Forms/ClinicalExam/medicalConditionForm";
import * as Sentry from "@sentry/react";
import {ProblemListForm} from "../ClinicalExam/problemListForm";
import axios from "axios";
import {FORMS} from "../../../constants/forms";
import {FormPageTemplate} from "../../templates/form-page-template";
import {QuestionSectionTemplate} from "../../templates/question-section-template";


export const ReferralForm = ({patient_id}) => {
    const [patientInfo, setInfo] = useState({site_of_referral: 'Outpatient'});
    const [fillingUser, setUser] = useState({});
    const [patientRecord, setPatientRecord] = useState({});
    const [medicalConditions, setConditions] = useState(medical_list);
    const [problemList, setProblemList] = useState(problem_list);
    const [medicalAid, setMedicalAid] = useState(medical_aid);

    const formSteps = [
        {
            number: '1',
            label: 'Patient Details',
            //description: 'Fill in patient information',
            component: <PatientInfo patient_id={patient_id} setInfo={setInfo}/>,
            icon: <FontAwesomeIcon icon={faFileMedicalAlt} size={'3x'}/>
        },
        {
            number: '2',
            label: 'Medical Aid Details',
            //description: 'Medical Conditions',
            component: <QuestionSectionTemplate list={medicalAid} setMethod={setMedicalAid}/>,
            icon: <FontAwesomeIcon icon={faHandHoldingMedical} size={'3x'}/>
        },
        {
            number: '3',
            label: 'Problem List',
            //description: 'Fill in problem list',
            component: <QuestionSectionTemplate list={problemList} setMethod={setProblemList}/>,
            icon: <FontAwesomeIcon icon={faFileMedicalAlt} size={'3x'}/>
        },
        {
            number: '4',
            label: 'Medical Conditions',
            //description: 'Medical Conditions',
            component: <QuestionSectionTemplate list={medicalConditions} setMethod={setConditions}/>,
            icon: <FontAwesomeIcon icon={faHeartbeat} size={'3x'}/>
        },
        {
            number: '5',
            label: 'Sign & Confirm Referral',
            //description: 'Sign & Confirm Referral',
            component: <Signature setUser={setUser}/>,
            icon: <FontAwesomeIcon icon={faSignature} size={'3x'}/>
        }
    ];

    const _customRenderer = ({currentStep}) => {
        const steps = formSteps.map((step, index) => {
            const isActive = currentStep === index + 1;
            return <Col lg="3" xl="2">
                <Card key={index}
                      className={clsx("card-box card-stats mb-4 mb-xl-0", {'border border-primary': isActive})}>
                    <div onClick={e => e.preventDefault()}>
                        <CardBody>
                            <Row>
                                <div className="col">
                                    <div className="step-indicator">
                                        <div className="d-flex">
                                            <div>
                                                <div className="font-weight-bold">{step.label}</div>
                                                <small className="text-black-50">{step.description}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Col className="col-auto">
                                    {step.icon}
                                </Col>
                            </Row>
                        </CardBody>
                    </div>
                </Card>
            </Col>
        });

        return <div className="header pb-3 pt-5 pt-md-8">
            <Container fluid>
                <div className="header-body">
                    <Row className="justify-content-center align-self-center">
                        {steps}
                    </Row>
                </div>
            </Container>
        </div>
    };

    const _customActions = ({isComplete, cantBack, isInFinalStep, backHandler, nextHandler}) => {
        return (
            <div className="actions p-4">
                <Button outline color="primary" onClick={backHandler} disabled={cantBack || isComplete}>
                    Previous
                </Button>
                <Button className={`float-right ${!isInFinalStep ? 'bg-blue' : 'bg-green'}`}
                        color={!isInFinalStep ? 'primary' : 'success'} onClick={nextHandler}
                        disabled={isComplete}>
                    {isInFinalStep ? 'Finish' : 'Next'}
                </Button>
            </div>
        );
    };

    const _onFinish = () => {
        if (patientInfo !== null && fillingUser !== null) {
            const patient_record = {
                ...{"medical_aid": medicalAid},
                ...{"medical_conditions": medicalConditions},
                ...{"problem_list": problemList},
                ...{"details": patientInfo},
                ...new BaseModel(patientInfo.id_number, patientInfo.first_name).getBase(),
                completed_by: {...fillingUser}
            }
            axios.post(PATIENT_URLS.PATIENT_RECORD(patient_id, '1'),
                {records: patient_record})
                .then(() => {
                    window.location.reload()
                    SuccessMsg('Patient info saved!')
                })
                .catch(error => {
                    Sentry.captureException(error);
                    ErrorMsg('Patient info could not be saved. Please try again later.')
                })

        } else {
            ErrorMsg('Please complete forms')
        }
    }

    return (
        <>
            <FormPageTemplate formSteps={formSteps} form_type_id={FORMS.PATIENT_DETAILS} patient_id={patient_id}
                              patient_record={patientRecord}/>
        </>
    );
}
