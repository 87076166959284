import React, {useState} from "react";
import treatment_questions from "components/Forms/treatment-plan/questions/treatment-questions.json";
import medication_questions from "components/Forms/shared-questions/medications-questions.json";
import {useAuthentication} from "components/Hooks/AuthHook";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignature, faUserNurse, faTablets, faFileMedicalAlt} from "@fortawesome/free-solid-svg-icons";
import {Signature} from "components/SignaturePad/signature";
import {Button, Card, CardBody, Col, Container, Row} from "reactstrap";
import clsx from "clsx";
import {BaseModel} from "components/models/BaseModel";
import axios from "axios";
import {PATIENT_URLS} from "components/Api/Config";
import {ErrorMsg, SuccessMsg} from "components/Notification";
import * as Sentry from "@sentry/react";
import Loki from "react-loki";
import {TreatmentInfoForm} from "components/Forms/treatment-plan/treatment-info";
import {ProblemListForm} from "components/Forms/ClinicalExam/problemListForm";
import problem_list from "components/Forms/ClinicalExam/questions/problem_list.json";
import RiskAssessmentInfo from "components/Forms/evaluation/riskAssessment";
import risk_list from "components/Forms/evaluation/questions/risk_list.json";
import {DiagnosisInfo} from "components/Forms/evaluation/diagnosisInfo";

export default function ClinicalReport({patient_id}) {
    const [checklist, setChecklist] = useState(treatment_questions);
    const [problemList, setProblemList] = useState(problem_list);
    const [medicationList, setMedicationList] = useState(medication_questions);
    const [fillingUser, setUser] = useState({});
    const [treatment, setTreatment] = useState(treatment_questions);
    const [riskList, setRiskList] = useState(risk_list)
    const [psych, setPsych] = useState([]);

    let {user} = useAuthentication()

    const formSteps = [
        {
            number: '1',
            label: 'Problem List',
            description: 'Fill in problem list',
            component: <ProblemListForm list={problemList} setMethod={setProblemList}/>,
            icon: <FontAwesomeIcon icon={faFileMedicalAlt} size={'3x'}/>
        },
        {
            number: '2',
            label: 'Diagnosis',
            description: 'Fill in patient diagnosis',
            component: <DiagnosisInfo patient_id={patient_id} psych={psych} setMethod={setPsych}/>,
            icon: <FontAwesomeIcon icon={faFileMedicalAlt} size={'3x'}/>
        },
        {
            number: '3',
            label: 'Risk Assessment',
            description: 'Fill in patient risk assessment',
            component: <RiskAssessmentInfo list={riskList} setMethod={setRiskList}/>,
            icon: <FontAwesomeIcon icon={faFileMedicalAlt} size={'3x'}/>
        }, {
            number: '4',
            label: 'Referral to Other Services',
            description: 'Referral to Other Services',
            component: <TreatmentInfoForm list={treatment} setMethod={setTreatment}/>,
            icon: <FontAwesomeIcon icon={faFileMedicalAlt} size={'3x'}/>
        },
        {
            number: '5',
            label: 'Confirmation',
            description: 'Sign & Confirm Check-in',
            component: <Signature setUser={setUser}/>,
            icon: <FontAwesomeIcon icon={faSignature} size={'3x'}/>
        }
    ];

    const _customRenderer = ({currentStep}) => {
        const steps = formSteps.map((step, index) => {
            const isActive = currentStep === index + 1;
            return <Col lg="3" xl="2">
                <Card key={index}
                      className={clsx("card-box card-stats mb-4 mb-xl-0", {'border border-primary': isActive})}>
                    <div onClick={e => e.preventDefault()}>
                        <CardBody>
                            <Row>
                                <div className="col">
                                    <div className="step-indicator">
                                        <div className="d-flex">
                                            <div>
                                                <div className="font-weight-bold">{step.label}</div>
                                                <small className="text-black-50">{step.description}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Col className="col-auto">
                                    {step.icon}
                                </Col>
                            </Row>
                        </CardBody>
                    </div>
                </Card>
            </Col>
        });

        return <div className="header pb-3 pt-5 pt-md-8">
            <Container fluid>
                <div className="header-body">
                    <Row className="justify-content-center align-self-center">
                        {steps}
                    </Row>
                </div>
            </Container>
        </div>
    };

    const _customActions = ({isComplete, cantBack, isInFinalStep, backHandler, nextHandler}) => {
        return (
            <div className="actions p-4">
                <Button outline color="primary" onClick={backHandler} disabled={cantBack || isComplete}>
                    Previous
                </Button>
                <Button className="float-right"
                        color={!isInFinalStep ? 'primary' : 'success'} onClick={nextHandler}
                        disabled={isComplete}>
                    {isInFinalStep ? 'Finish' : 'Next'}
                </Button>
            </div>
        );
    };

    const _onFinish = () => {
            if (checklist !== null) {
                const patient_record = {
                    ...{"records": checklist.concat(medicationList)},
                    ...new BaseModel(user.id_number, user.first_name).getBase(),
                    completed_by: {...fillingUser}
                }

                axios.post(PATIENT_URLS.PATIENT_RECORD(patient_id, 13), patient_record)
                    .then(() => {
                        SuccessMsg('Record Saved.')
                        window.location.reload()
                    })
                    .catch(error => {
                        Sentry.captureException(error);
                        ErrorMsg('We could not save your record. Please consult admin.')
                    })

            } else {
                ErrorMsg('Please complete forms')
            }

        }
    ;

    return <>
        <div className="wizard-alternate-2 horizontal" style={{marginTop: '-5em'}}>
            <Loki steps={formSteps}
                  renderSteps={_customRenderer}
                  renderActions={_customActions}
                  onFinish={_onFinish}/>
        </div>
    </>
}