import React, { useRef, useState } from "react";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import clsx from "clsx";
import { toast, Zoom } from "react-toastify";
import Loki from "react-loki";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandHoldingMedical, faSignature } from '@fortawesome/free-solid-svg-icons'
import { Signature } from "../../SignaturePad/signature";
import medical_aid_questions from "./questions/medical_aid.json";
import { MedicalAidForm } from "./medicalAidForm";
import { BaseModel } from "../../models/BaseModel";
import axios from "axios";
import { PATIENT_URLS } from "../../Api/Config";
import { ErrorMsg, SuccessMsg } from "../../Notification";
import * as Sentry from "@sentry/react";
import { useAuthentication } from "../../Hooks/AuthHook";

export const MedicalAid = ({ patient_id }) => {
    const [details, setDetails] = useState(medical_aid_questions);
    const [fillingUser, setUser] = useState({});
    let { user } = useAuthentication()

    const formSteps = [
        {
            number: '1',
            label: 'Medical Aid',
            description: 'Fill in information',
            component: <MedicalAidForm list={details} setMethod={setDetails} />,
            icon: <FontAwesomeIcon icon={faHandHoldingMedical} size={'3x'} />
        },
        {
            number: '2',
            label: 'Confirmation',
            description: 'Sign & Confirm Referral',
            component: <Signature setUser={setUser} />,
            icon: <FontAwesomeIcon icon={faSignature} size={'3x'} />
        }
    ];


    const _customRenderer = ({ currentStep }) => {
        const steps = formSteps.map((step, index) => {
            const isActive = currentStep === index + 1;
            return <Col lg="6" xl="3"><Card key={index}
                className={clsx("card-box card-stats mb-4 mb-xl-0", { 'border border-success': isActive })}><a
                    href="#" onClick={e => e.preventDefault()}>
                    <CardBody>
                        <Row>
                            <div className="col">
                                <div className="step-indicator">
                                    <div className="d-flex">
                                        <div>
                                            <div className="font-weight-bold text-green">{step.label}</div>
                                            <small className="text-black-50 text-green">{step.description}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Col className="col-auto text-green">
                                {step.icon}
                            </Col>
                        </Row>
                        <p className="mt-3 mb-0 text-muted text-sm">

                        </p>
                    </CardBody>
                </a></Card></Col>
        });

        return <div className="header pb-3 pt-5 pt-md-8">
            <Container fluid>
                <div className="header-body">
                    <Row className="justify-content-center align-self-center">
                        {steps}
                    </Row>
                </div>
            </Container>
        </div>
    };

    const _customActions = ({ isComplete, cantBack, isInFinalStep, backHandler, nextHandler }) => {
        return (
            <div className="actions p-4">
                <Button outline color="primary" onClick={backHandler} disabled={cantBack || isComplete}>
                    Previous
                </Button>
                <Button className={`float-right ${!isInFinalStep ? 'bg-blue' : 'bg-green'}`} color={!isInFinalStep ? 'primary' : 'success'} onClick={nextHandler}
                    disabled={isComplete}>
                    {isInFinalStep ? 'Finish' : 'Next'}
                </Button>
            </div>
        );
    };

    const _onFinish = () => {
        if (fillingUser !== null) {
            const patient_record = {
                records: details,
                ...new BaseModel(user.id_number, user.first_name).getBase(),
            }

            axios.post(PATIENT_URLS.PATIENT_RECORD(patient_id, 11), patient_record)
                .then(() => {
                    SuccessMsg('Record Saved.')
                    window.location.reload()
                })
                .catch(error => {
                    Sentry.captureException(error);
                    ErrorMsg('We could not save your record. Please consult admin.')
                })
        }
    };

    return (
        <>
            <div className="wizard-alternate-2 horizontal" style={{ marginTop: '-5em' }}>
                <Loki steps={formSteps}
                    renderSteps={_customRenderer}
                    renderActions={_customActions}
                    onFinish={_onFinish} />
            </div>
        </>
    );
}