import Logo from "assets/img/brand/Icon/SVG/Icon.svg";
import React, {Fragment, useState} from "react";
import Sidebar from "components/Sidebar/Sidebar";
import Footer from "components/Footers/Footer";
import {Route, Switch} from "react-router-dom";
import Refer from "pages/patient/refer";
import Clinical from "pages/patient/clinical";
import Development from "pages/patient/development";
import Intake from "pages/patient/intake";
import Snap from "pages/patient/snap";
import Phq from "pages/patient/phq";
import DSMCluster from "pages/patient/dsmcluster";
import PsychEvaluation from "pages/patient/evaluation";
import Weiss from "pages/patient/weiss";
import Medical from "pages/patient/medical";
import Appointments from "pages/appointments";
import PrivateRoute from "components/PrivateComponents";
import ClinicianBoard from "pages/clinician/dashboard/board";
import Header from "components/Headers/Header";
import {ClinicianRoutes} from "routes/ClinicianRoutes";
import Patients from "pages/clinician/Patients";
import Profile from "pages/patient/profile";
import NurseWeeklyCheckinPage from "pages/patient/nurse-weekly-checkin";
import TreatmentFormPage from "pages/patient/treatment-form";
import RtosPage from "pages/patient/rtos";
import PreFollowupPage from "pages/patient/pre-followup";
import ClinicalReportForm from "pages/patient/clinical-report-form";
import PsychiatristFollowUpRxPlanForm from "pages/patient/psychiatrist-follow-up-rx-plan";
import PsychiatristFollowUp from "pages/patient/psychiatrist-follow-up";
import PsychiatristFollowUpForm from "pages/patient/psychiatrist-follow-up";
import WhodasForm from "pages/patient/whodas";
import MonthlyCheckinPage from "../../patient/monthly-checkin";
import {Bars3Icon, BriefcaseIcon, UserPlusIcon} from "@heroicons/react/24/outline";
import {ChevronDownIcon, MagnifyingGlassIcon} from "@heroicons/react/20/solid";
import {Menu, Transition} from "@headlessui/react";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
export default function Clinician() {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [open, setOpen] = useState(false)
    const userNavigation = [
        {name: 'Your profile', href: '#'},
        {name: 'Sign out', href: '#'},
    ]

    return <>

        <div>
            <Sidebar
                routes={ClinicianRoutes}
                logo={{
                    innerLink: "/clinician",
                    imgSrc: Logo,
                    imgAlt: "clavkoo",
                }}
            />
            <div className="lg:pl-72">

                <main className="py-10">
                    <div className="px-4 sm:px-6 lg:px-8">
                        <div className="main-content -mt-4">
                            <Switch>
                                <PrivateRoute path="/" exact component={ClinicianBoard}/>
                                <PrivateRoute path="/dashboard" exact component={ClinicianBoard}/>
                                <PrivateRoute path="/appointments" exact component={Appointments}/>
                                <PrivateRoute path="/refer/:id" component={Refer}/>
                                <PrivateRoute path="/clinical/:id" component={Clinical}/>
                                <PrivateRoute path="/development/:id" component={Development}/>*/
                                <PrivateRoute path="/intake/:id" component={Intake}/>
                                <PrivateRoute path="/snap/:id" component={Snap}/>
                                <PrivateRoute path="/phq/:id" component={Phq}/>
                                <PrivateRoute path="/dsmcluster/:id" component={DSMCluster}/>
                                <PrivateRoute path="/evaluation/:id" component={PsychEvaluation} exact/>
                                <PrivateRoute path="/evaluation/:id/new" component={PsychEvaluation}/>
                                <PrivateRoute path="/weiss/:id" component={Weiss}/>
                                <PrivateRoute path="/medicalaid/:id" component={Medical}/>
                                <PrivateRoute path="/appointments" component={Appointments}/>
                                <PrivateRoute path="/patients" component={Patients}/>
                                <PrivateRoute path="/profile/:id" component={Profile}/>
                                <PrivateRoute path="/nurse-weekly-checkin/:id" component={NurseWeeklyCheckinPage}/>
                                <PrivateRoute path="/rtos/:id" component={RtosPage}/>
                                <PrivateRoute path="/prefollowup/:id" component={PreFollowupPage}/>
                                <PrivateRoute path="/treatment/:id" component={TreatmentFormPage}/>
                                <PrivateRoute path="/clinical-report/:id" component={ClinicalReportForm}/>
                                <PrivateRoute path="/psychiatrist-followup-rx/:id"
                                              component={PsychiatristFollowUpRxPlanForm}/>
                                <PrivateRoute path="/psychiatrist-followup/:id" component={PsychiatristFollowUpForm}/>
                                <PrivateRoute path="/whodas/:id" component={WhodasForm}/>
                                <PrivateRoute path="/monthly-check-in/:id" component={MonthlyCheckinPage}/>
                            </Switch>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    </>
}
