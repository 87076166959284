import React, {useContext, useState} from "react";
import Logo from 'assets/img/brand/Icon/SVG/Icon.svg'
import {ErrorMsg, SuccessMsg} from "../../components/Notification";
import * as Sentry from "@sentry/react";
import {useAuthentication} from "../../components/Hooks/AuthHook";
import {AuthContext} from "../../components/Context/AuthContext";
import {useHistory} from "react-router-dom";
import {Spinner} from "reactstrap";
import {useSharedAuthentication} from "@clavkoo/shared-services/src";

export default function Login() {
    const [details, setDetails] = useState({})
    const [loading, setLoading] = useState(false)
    const [requested, setRequested] = useState(false)
    const { setAuth } = useAuthentication();
    const { setUser } = useContext(AuthContext)
    const { request_otp, login_user } = useSharedAuthentication()
    const history = useHistory();

    function start_login_user() {
        login_user(details?.username, details?.password).then(json => {
            setUser(json.data)
            setAuth(json.data)
            history.push(`/`);
            SuccessMsg('Welcome to Clavkoo')
        }).catch(error => {
            ErrorMsg('Credentials not valid, please try again')
            Sentry.captureException(error);
        }).finally(() => {
            setLoading(false)
        });
    }

    function start_request_otp() {
        request_otp(details?.username, details?.cellphone).then(()=>{
            setDetails((prevState) => ({
                ...prevState,
                [`password`]: ''
            }))
            setRequested(true)
            SuccessMsg('Please sign in with your OTP')
        }).catch(error => {
            ErrorMsg('Credentials not valid, please get a referral')
            Sentry.captureException(error);
        }).finally(() => {
            setLoading(false)
        });
    }

    return (
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <img
                        className="mx-auto h-20 w-auto"
                        src={Logo}
                        alt="Clavkoo"
                    />
                    <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-green-500">
                        Clinician Sign In
                    </h2>
                </div>

                <div
                    className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px] shadow-2xl border-2 border-green-500 rounded">
                    <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                        <form className="space-y-6" onSubmit={event => {
                            event.preventDefault()
                            setLoading(true)
                            requested ? start_login_user() : start_request_otp()
                        }}>
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-700">
                                    Email Address
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="email"
                                        name="email"
                                        type="text"
                                        disabled={requested}
                                        autoComplete="email"
                                        required
                                        onChange={event => {
                                            setDetails((prevState) => ({
                                                ...prevState,
                                                [`username`]: event.target.value,
                                            }))
                                        }}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-700">
                                    {requested ? 'OTP' : 'Cellphone Number'}
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="cellphone"
                                        name="cellphone"
                                        autoComplete="cellphone"
                                        type="tel"
                                        required
                                        onChange={event => {
                                            setDetails((prevState) => ({
                                                ...prevState,
                                                [requested ? `password` : `cellphone`]: event.target.value,
                                            }))
                                        }}
                                        value={requested ? details?.password : details?.cellphone}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div>
                                <button
                                    type="submit"
                                    className="flex w-full justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    {loading ? <Spinner size="sm" color="#fff" /> : `${requested ? 'Sign In' : 'Request Pin'}`}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}
