import React, {useEffect, useState} from "react";
import {useAuthentication} from "components/Hooks/AuthHook";
import axios from "axios";
import {CMS_URLS} from "components/Api/Config";
import Loader from "components/Loader";
import moment from "moment";
import {CalendarDaysIcon} from "@heroicons/react/24/outline";


export default function AppointmentsSummary() {
    const [appointments, setAppointments] = useState([])
    const [loading, setLoading] = useState(true)
    let {user} = useAuthentication()

    useEffect(() => {
        setLoading(true)
        axios.get(CMS_URLS.APPOINTMENTS(user().id))
            .then(res => setAppointments(res.data.slice(0, 4)))
            .catch(err => console.error(err))
            .finally(() => setLoading(false))
    }, [])

    return <>
        <div className="card shadow-2xl p-4 rounded border-2 border-green-500">
            {
                !loading ? appointments.length === 0 ? <>There are no upcoming appointments.</> :
                        <ul className="list-group">
                            {
                                appointments.map(appointment => {
                                    return <React.Fragment key={appointment?.id}>
                                        <li key={appointment?.id}
                                            className="flex justify-between gap-x-6 border-b-2 mb-4 border-green-500 cursor-pointer">
                                            <div className="flex min-w-0 gap-x-4">
                                                <CalendarDaysIcon className="h-8 w-8  text-green-500 "/>
                                                <div className="min-w-0 flex-auto">
                                                    <p className="text-sm font-semibold leading-6 text-gray-900">{appointment?.meeting?.meeting_name}</p>
                                                    <p className="text-xs leading-6 text-gray-500 -mt-4">Click to join</p>
                                                </div>
                                            </div>
                                            <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                                                <p className="text-sm leading-6 text-gray-900">{moment(appointment?.start_date).local().format('YYYY-MM-DD')} - {moment(appointment?.start_date).local().format('hh:mm A')}</p>

                                                <p className="-mt-3 text-xs leading-5 text-gray-500">
                                                    Ends <time
                                                    dateTime={moment(appointment?.end_date).local().format('hh:mm A')}>{moment(appointment?.end_date).local().format('hh:mm A')}</time>
                                                </p>

                                            </div>
                                        </li>
                                    </React.Fragment>
                                })}
                        </ul> :
                    <Loader/>
            }
        </div>
    </>
}
