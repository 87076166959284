import React, {useState} from "react";
import {Button, Card, CardBody, Col, Container, Row} from "reactstrap";
import clsx from "clsx";
import Loki from "react-loki";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileMedicalAlt, faSignature} from '@fortawesome/free-solid-svg-icons'
import {Signature} from "../../SignaturePad/signature";
import {FamilyInfo} from "./familyInfo";
import {LifeSkillsInfo} from "./lifeSkillsInfo";
import {SocialActivitiesInfo} from "./socialActivitiesInfo";
import {SchoolInfo} from "./schoolInfo";
import fam_list from "./questions/family_list.json";
import life_list from "./questions/life_list.json";
import social_list from "./questions/social_list.json";
import school_list from "./questions/school_list.json";
import risky_list from "./questions/risky_list.json";
import {useAuthentication} from "components/Hooks/AuthHook";
import {BaseModel} from "components/models/BaseModel";
import {PATIENT_URLS} from "components/Api/Config";
import {RiskyActivitiesInfo} from "./riskyActivitiesInfo";
import axios from "axios";
import {ErrorMsg, SuccessMsg} from "../../Notification";
import * as Sentry from "@sentry/react";
import {FORMS} from "../../../constants/forms";
import {FormPageTemplate} from "../../templates/form-page-template";
import {QuestionSectionTemplate} from "../../templates/question-section-template";

export const WeissForm = ({patient_id}) => {
    const [famQuestions, setFamQuestions] = useState(fam_list)
    const [lifeQuestions, setLifeQuestions] = useState(life_list)
    const [schoolQuestions, setSchoolQuestions] = useState(school_list)
    const [socialQuestions, setSocialQuestions] = useState(social_list)
    const [riskyQuestions, setRiskyQuestions] = useState(risky_list)
    const [fillingUser, setUser] = useState({});
    const [patientRecord, setPatientRecord] = useState({});
    let {user} = useAuthentication()

    const formSteps = [
        {
            number: '1',
            label: 'Family',
            description: 'Fill in patient information',
            component: <QuestionSectionTemplate list={famQuestions} setMethod={setFamQuestions}/>,
            icon: <FontAwesomeIcon icon={faFileMedicalAlt} size={'3x'}/>
        },
        {
            number: '2',
            label: 'School',
            description: 'Fill in patient information',
            component: <QuestionSectionTemplate list={schoolQuestions} setMethod={setSchoolQuestions}/>,
            icon: <FontAwesomeIcon icon={faFileMedicalAlt} size={'3x'}/>
        },
        {
            number: '3',
            label: 'Life Skills',
            description: 'Fill in patient information',
            component: <QuestionSectionTemplate list={lifeQuestions} setMethod={setLifeQuestions}/>,
            icon: <FontAwesomeIcon icon={faFileMedicalAlt} size={'3x'}/>
        },
        {
            number: '4',
            label: 'Social Activities',
            description: 'Fill in patient information',
            component: <QuestionSectionTemplate list={socialQuestions} setMethod={setSocialQuestions}/>,
            icon: <FontAwesomeIcon icon={faFileMedicalAlt} size={'3x'}/>
        },
        {
            number: '5',
            label: 'Risky Activities',
            description: 'Fill in patient information',
            component: <QuestionSectionTemplate list={riskyQuestions} setMethod={setRiskyQuestions}/>,
            icon: <FontAwesomeIcon icon={faFileMedicalAlt} size={'3x'}/>
        },
        {
            number: '6',
            label: 'Confirmation',
            description: 'Sign & Confirm Referral',
            component: <Signature setUser={setUser}/>,
            icon: <FontAwesomeIcon icon={faSignature} size={'3x'}/>
        }
    ]

    return <>
        <FormPageTemplate formSteps={formSteps} form_type_id={FORMS.WEISS} patient_id={patient_id}
                          patient_record={patientRecord}/>
    </>
}