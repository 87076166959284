import React, {useState, useEffect} from "react";
import PatientCard from "./card";
import {PATIENT_URLS} from "components/Api/Config";
import Loader from "components/Loader";
import {useAuthentication} from "components/Hooks/AuthHook";
import axios from "axios";
import {MagnifyingGlassIcon} from "@heroicons/react/20/solid";


export default function PatientsTable() {
    const [patients, setPatients] = useState([])
    const [term, setTerm] = useState('')
    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState('')

    let {get_practice} = useAuthentication();

    useEffect(() => {
        setLoading(true)
        const filters = {
            'search': search,
            'practice_no': get_practice()
        }

        axios.get(PATIENT_URLS.PATIENTS_LIST, {
            params: filters,
            paramSerializer: function (params) {
                return JSON.stringify(params)
            }
        })
            .then(res => {
                setPatients(res.data)
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [search])

    return <>
        <div className="wizard-alternate-2 horizontal">
            <div>
                <label htmlFor="search" className="block text-sm font-medium leading-6 text-gray-900">
                    Quick search
                </label>
                <div className="relative mt-2 flex items-center">
                    <input
                        type="text"
                        name="search"
                        id="search"
                        className="block w-full rounded-md border-0 py-1.5 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm sm:leading-6"
                        onChange={value => setTerm(value.target.value)}
                    />
                    <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5 cursor-pointer "
                         onClick={() => setSearch(term)}>
                        <kbd
                            className="bg-green-500 inline-flex items-center rounded border border-gray-200 px-1 font-sans text-xs text-gray-400">
                            <MagnifyingGlassIcon className='h-5 w-auto text-white'/>
                        </kbd>
                    </div>
                </div>
            </div>

            <ul role="list" className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-4 md:grid-cols-2 xl:gap-x-8 mt-6">
                {
                    loading ?
                        <Loader/> :
                        patients.length === 0
                            ? <p className='text-center col-12'>No patient records</p> :
                            patients.map(patient => {
                                return <PatientCard patient={patient} patient_id={patient.patient_id}/>
                            })
                }
            </ul>
        </div>
    </>
}
