import Loader from "components/Loader";
import React, {useEffect, useState} from "react";
import {useAuthentication} from "components/Hooks/AuthHook";
import {ApiConsumer} from "components/Api/ApiConsumer";
import {CLINICIAN_URLS} from "components/Api/Config";
import {UserPlusIcon} from "@heroicons/react/24/outline";
import {Link} from "react-router-dom";


export default function NewPatients() {
    const [patients, setPatients] = useState([])
    const [loading, setLoading] = useState(true)
    let {user} = useAuthentication()

    useEffect(() => {
        setLoading(true)
        ApiConsumer.get(CLINICIAN_URLS.PATIENT_LIST(user().id_number))
            .then(res => setPatients(res.data.splice(0, 4)))
            .catch(err => console.error(err))
            .finally(() => setLoading(false))
    }, [])

    return <>
        <div className="card shadow-2xl p-4 rounded border-2 border-green-500">

            <div className="card-body">
                {
                    !loading ? patients.length === 0 ? <>There are no new patients.</> :
                            <ul className="list-group">
                                {
                                    patients.map((patient, i) => {
                                        return <>
                                            <React.Fragment key={patient?.id}>
                                                <Link to={`/profile/${patient?.id_number}`}>
                                                    <li key={patient?.id}
                                                        className="flex justify-between gap-x-6 border-b-2 mb-4 border-green-500 cursor-pointer">
                                                        <div className="flex min-w-0 gap-x-4">
                                                            <UserPlusIcon className="h-8 w-8  text-green-500 "/>
                                                            <div className="min-w-0 flex-auto">
                                                                <p className="text-sm font-semibold leading-6 text-gray-900">{patient.title} {patient.first_name} {patient.last_name}</p>
                                                                <p className="text-xs leading-6 text-gray-500 -mt-4">
                                                                    View Patient Profile
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                                                            <p className="text-sm leading-6 text-gray-900">{patient.cellphone}</p>

                                                            <p className="-mt-3 text-xs leading-5 text-gray-500">
                                                                {patient.email}
                                                            </p>

                                                        </div>
                                                    </li>
                                                </Link>
                                            </React.Fragment>
                                        </>
                                    })
                                }
                            </ul> :
                        <Loader/>
                }
            </div>
        </div>
    </>
}