import React, {useRef, useState} from "react";
import {Button, Card, CardBody, Col, Container, Row} from "reactstrap";
import clsx from "clsx";
import Loki from "react-loki";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileMedicalAlt, faSignature} from '@fortawesome/free-solid-svg-icons'
import {Signature} from "../../SignaturePad/signature";
import {GadForm} from "./gad7Info";
import {PhqForm} from "./phq9Info";
import phq_list from "./questions/phq_list.json";
import glad_list from "./questions/glad_list.json";
import {useAuthentication} from "components/Hooks/AuthHook";
import {BaseModel} from "components/models/BaseModel";
import {FORM_URLS, PATIENT_URLS} from "components/Api/Config";
import axios from "axios";
import {ErrorMsg, SuccessMsg} from "../../Notification";
import * as Sentry from "@sentry/react";
import {FORMS} from "../../../constants/forms";
import {FormPageTemplate} from "../../templates/form-page-template";
import {QuestionSectionTemplate} from "../../templates/question-section-template";

export const GadPhqForm = ({patient_id}) => {
    const [phqQuestions, setPhqQuestions] = useState(phq_list)
    const [gladQuestions, setGladQuestions] = useState(glad_list)
    const [confirmation, setConfirmation] = useState({});
    const [fillingUser, setUser] = useState({});
    const [patientRecord, setPatientRecord] = useState({});
    let {user} = useAuthentication()
    const sigRef = useRef();
    const formSteps = [
        {
            number: '1',
            label: 'PHQ 9',
            description: 'Fill in patient information',
            component: <QuestionSectionTemplate list={phqQuestions} setMethod={setPhqQuestions}/>,
            icon: <FontAwesomeIcon icon={faFileMedicalAlt} size={'3x'}/>
        },
        {
            number: '2',
            label: 'GAD 7',
            description: 'Fill in patient information',
            component: <QuestionSectionTemplate list={gladQuestions} setMethod={setGladQuestions}/>,
            icon: <FontAwesomeIcon icon={faFileMedicalAlt} size={'3x'}/>
        },
        {
            number: '3',
            label: 'Confirmation',
            description: 'Sign & Confirm Referral',
            component: <Signature setUser={setUser}/>,
            icon: <FontAwesomeIcon icon={faSignature} size={'3x'}/>
        }
    ];

    return <>
        <FormPageTemplate formSteps={formSteps} form_type_id={FORMS.PHQ_GAD} patient_id={patient_id}
                          patient_record={patientRecord}/>
    </>
}