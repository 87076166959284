import React, {useEffect, useState} from "react";
import {Breadcrumbs} from "components/Breadcrumbs";
import {useParams} from "react-router-dom";
import {useClavkooForms} from "components/Hooks/FormHook";
import {CMS_URLS, PATIENT_URLS} from "components/Api/Config";
import Record from "components/Reports/Record";
import Loader from "components/Loader";
import * as Sentry from "@sentry/react";
import axios from "axios"
import {FORMS} from "constants/forms";
import {MonthlyCheckInForm} from "../../components/Forms/monthly-check-in";

export default function MonthlyCheckinPage() {
    let {id} = useParams()
    const [breadcrumbs, setBreadcrumbs] = useState([])
    const [exists, setExists] = useState(false)
    let {recordExists} = useClavkooForms()
    const [loading, setLoading] = useState(true)
    const [record, setRecord] = useState()

    function LoadBreadcrumbs(){
        axios.get(CMS_URLS.USERS.DETAILS(id))
            .then(res => {
                setBreadcrumbs([{'detail': 'Patients', 'link': '/patients'},
                    {'detail': `${res.data?.first_name} ${res.data?.last_name}`, 'link': `/profile/${id}`}, {'detail': 'Monthly Check-in', 'link': `/monthly-check-in/${id}`}])
            })
            .catch(err => Sentry.captureException(err))
    }

    useEffect(() => {
        axios.get(PATIENT_URLS.PATIENT_RECORD(id, FORMS.MONTHLY_CHECKIN))
            .then(res => {
                setExists(recordExists(res.data))
                setRecord(res.data)
            })
            .catch(err => Sentry.captureException(err))
            .finally(() => setLoading(false))
        LoadBreadcrumbs()

    }, [id])

    return <>
        <Breadcrumbs items={breadcrumbs}/>
        {
            loading ? <Loader/> : exists ? <Record patient_record={record}/> :
                <MonthlyCheckInForm patient_id={id}/>
        }
    </>
}
