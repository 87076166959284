import React, {useEffect, useState} from "react";
import {DatePicker, Input, Select} from "antd";
import moment from 'moment';
import {ApiConsumer} from "components/Api/ApiConsumer";
import {CMS_URLS} from "components/Api/Config";
import {useAuthentication} from "components/Hooks/AuthHook";

export const PatientInfo = ({setInfo, patient_id}) => {
    const [IdType, setIdType] = useState('ID Number');
    const [IdNumber, setIdNumber] = useState('');
    const [IdValid, setIdValid] = useState(false);
    const [displayCalValues, setDisplay] = useState(true);
    const [birthday, setBirthday] = useState(moment());
    const [gender, setGender] = useState('');
    const [referral, setReferral] = useState('Outpatient')
    const [clinicians, setClinicians] = useState([])

    const [details, setDetails] = useState({})
    let {user} = useAuthentication()

    function isIdNumber(str) {
        const num = Number(str);
        if (Number.isInteger(num) && num > 0) {
            return true;
        }
        return false;
    }

    const dateFormat = 'YYYY-MM-DD';

    function toggleIdType(selectedType) {
        setIdType(selectedType)
        if (selectedType === 'Passport Number') {
            setDisplay(false)
        } else {
            setDisplay(true)
        }
    }

    function verifyIdNumber(idNumber) {
        const expression = "(?<Year>[0-9][0-9])(?<Month>([0][1-9])|([1][0-2]))(?<Day>([0-2][0-9])|([3][0-1]))(?<Gender>[0-9])(?<Series>[0-9]{3})(?<Citizenship>[0-9])(?<Uniform>[0-9])(?<Control>[0-9])"
        let pattern = new RegExp(expression);
        const result = pattern.test(idNumber);
        setIdValid(!result)

        if (result) {
            const yy = idNumber.substring(0, 2),
                mm = idNumber.substring(2, 4),
                dd = idNumber.substring(4, 6);

            let fullyear = idNumber.substring(0, 2) < 20 ? 2000 + parseInt(idNumber.substring(0, 2)) : 1900 + parseInt(idNumber.substring(0, 2))

            const today = fullyear + "-" + (mm) + "-" + (dd);

            setBirthday(moment(today, dateFormat))
            setInfo(prevState => ({
                ...prevState,
                ['date_of_birth']: moment(today, dateFormat).format(dateFormat)
            }))

            fillInGender(idNumber);
        }
    }

    function fillInGender(idNumber) {
        const gender = idNumber.substring(6, 10);
        if (parseInt(gender) >= 5000) {
            setGender('M')
            setDetails(prevState => ({
                ...prevState,
                ['gender']: 'M'
            }))
        } else {
            setGender('F')
            setDetails(prevState => ({
                ...prevState,
                ['gender']: 'F'
            }))
        }
    }

    useEffect(() => {
        ApiConsumer.get(CMS_URLS.USERS.DETAILS(patient_id))
            .then(res => {
                setDetails(res.data)
                verifyIdNumber(res.data?.id_number)
                if (isIdNumber(res.data?.id_number)) {
                    toggleIdType('SA ID')
                    setDetails(prevState => ({
                        ...prevState,
                        ['id_type']: 'SA ID'
                    }))
                } else {
                    toggleIdType('Passport Number')
                    setDetails(prevState => ({
                        ...prevState,
                        ['id_type']: 'Passport Number'
                    }))
                }
            })
            .catch(err => {
                console.error(err)
            })

        ApiConsumer.get(CMS_URLS.USERS.CLINICIANS)
            .then(res => {
                setClinicians(res.data)
            })
            .catch(err => console.error(err))

    }, [patient_id])

    return (
        <>
            <div className="col-12">
                <div className="card">
                    <div className="card-body wizard-content">
                        <div className="tab-wizard wizard-circle">
                            <section>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor="firstName1">First Name:</label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Input type="text"
                                                           disabled={true}
                                                           onChange={e => {
                                                               setInfo(prevState => ({
                                                                   ...prevState,
                                                                   ['first_name']: e.target.value
                                                               }))
                                                           }}
                                                           value={details?.first_name}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor="lastName1">Last Name:</label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Input type="text"
                                                           disabled={true}
                                                           onChange={e => {
                                                               setInfo(prevState => ({
                                                                   ...prevState,
                                                                   ['last_name']: e.target.value
                                                               }))
                                                           }}
                                                           value={details?.last_name}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group m-b-30">
                                            <div className="row">
                                                <div className="col">
                                                    <label className="mr-sm-2" htmlFor="inlineFormCustomSelect">ID Doc
                                                        Type:</label>
                                                </div>
                                                <div className="col">
                                                    <select className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-green-600 sm:text-sm sm:leading-6"

                                                            disabled={true}
                                                            onChange={value => {
                                                                toggleIdType(value)
                                                            }} placeholder={'Choose...'}
                                                            style={{width: '120%'}}
                                                            value={details?.id_type}
                                                    >
                                                        {/*<option defaultValue>Choose...</option>*/}
                                                        <option value="ID Number">SA ID</option>
                                                        <option value="Passport Number">Passport</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor="phoneNumber1">{IdType}:</label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Input type="text"
                                                           disabled={true}
                                                           className={`${IdNumber !== '' ? (IdValid ? "is-invalid" : "is-valid") : undefined}`}
                                                           value={details?.id_number}
                                                           onChange={e => {
                                                               verifyIdNumber(e.target.value);
                                                               setIdNumber(e.target.value);
                                                               setInfo(prevState => ({
                                                                   ...prevState,
                                                                   ['id_number']: e.target.value
                                                               }))
                                                           }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label className="mr-sm-2"
                                                       htmlFor="inlineFormCustomSelect">Gender:</label>
                                            </div>
                                            <div className="col-md-6">
                                                <select className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-green-600 sm:text-sm sm:leading-6"
                                                        disabled={true}
                                                        value={details?.gender}
                                                        onChange={e => {
                                                            setGender(e.target.value);
                                                            setInfo(prevState => ({
                                                                ...prevState,
                                                                ['gender']: e.target.value
                                                            }));
                                                        }}
                                                >
                                                    {/*<option defaultValue>Choose...</option>*/}
                                                    <option value="M">Male</option>
                                                    <option value="F">Female</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor="date1">Date of Birth:</label>
                                                </div>
                                                <div className="col-md-6">
                                                    <DatePicker value={birthday} showTime
                                                                onChange={(value, dateString) => {
                                                                    setBirthday(dateString)
                                                                    setInfo(prevState => ({
                                                                        ...prevState,
                                                                        ['date_of_birth']: dateString
                                                                    }))
                                                                }}
                                                                onOk={(dateString) => setBirthday(dateString)}
                                                                format="YYYY-MM-DD"
                                                                disabled={displayCalValues ? 'disabled' : undefined}
                                                                className={'col-12'}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor="emailAddress1">Email Address :</label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Input type="email"
                                                           disabled={true}
                                                           onChange={e => {
                                                               setInfo(prevState => ({
                                                                   ...prevState,
                                                                   ['email']: e.target.value
                                                               }))
                                                           }}
                                                           value={details?.email}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor="phoneNumber1">Phone Number :</label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Input type="tel"
                                                           disabled={true}
                                                           onChange={e => {
                                                               setInfo(prevState => ({
                                                                   ...prevState,
                                                                   ['cellphone']: e.target.value
                                                               }))
                                                           }}
                                                           value={details?.cellphone}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor="emailAddress1">Vocation :</label>
                                                </div>
                                                <div className="col-md-6">
                                                    <select className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-green-600 sm:text-sm sm:leading-6"
                                                            placeholder={'Select vocation'}
                                                            onChange={value => {
                                                                setInfo(prevState => ({
                                                                    ...prevState,
                                                                    ['vocation']: value
                                                                }))
                                                            }}>
                                                        <option value={'Scholar'}>Scholar</option>
                                                        <option value={'Unemployed'}>Unemployed</option>
                                                        <option value={'Self-employed'}>Self-employed</option>
                                                        <option value={'Employed'}>Employed</option>
                                                        <option value={'Boarded'}>Boarded</option>
                                                        <option value={'Pensioned'}>Pensioned</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor="emailAddress1">Current Living Circumstances
                                                        :</label>
                                                </div>
                                                <div className="col-md-6">
                                                    <select className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-green-600 sm:text-sm sm:leading-6"
                                                            placeholder={'Select living circumstance'}
                                                            onChange={value => {
                                                                setInfo(prevState => ({
                                                                    ...prevState,
                                                                    ['living_circumstances']: value
                                                                }))
                                                            }}>
                                                        <option value={'Alone'}>Alone</option>
                                                        <option value={'With others'}>With others</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor="emailAddress1">Site of Referral :</label>
                                                </div>
                                                <div className="col-md-6">
                                                    <select className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-green-600 sm:text-sm sm:leading-6"
                                                            value={referral}
                                                            placeholder={'Select site of referral'}
                                                            onChange={value => {
                                                                setReferral(value)
                                                                setInfo(prevState => ({
                                                                    ...prevState,
                                                                    ['site_of_referral']: value
                                                                }))
                                                            }}>
                                                        <option value={'Outpatient'}>Outpatient</option>
                                                        <option value={'Inpatient'}>Inpatient</option>
                                                        <option value={'Medico-legal Assessment'}>Medico-legal
                                                            Assessment
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor="emailAddress1">Referral:</label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Select className="col-12"
                                                            placeholder={'Select Referral'}
                                                            onChange={value => {
                                                                setInfo(prevState => ({
                                                                    ...prevState,
                                                                    ['referral']: value
                                                                }))
                                                            }}>
                                                        <option value={`self`}>Self</option>
                                                        <option value={`school`}>School</option>
                                                        <option value={`work`}>Work</option>
                                                        <option value={`clinician`}>Clinician</option>
                                                        <option value={`casualty`}>Casualty</option>
                                                        <option value={`in-hospital`}>In-Hospital</option>
                                                        <option value={`ngo`}>NGO</option>
                                                        <option value={`other`}>Other</option>
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
