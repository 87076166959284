import React, {useEffect, useState} from "react";
import {Input, Switch} from "antd";
import axios from "axios";
import {PATIENT_URLS} from "components/Api/Config";
import * as Sentry from "@sentry/react";

export const DiagnosisInfo = ({patient_id, list, setMethod}) => {
    const [stressor, setStressors] = useState([])
    const [clinical, setClinical] = useState([])

    useEffect(() => {
        if (patient_id !== null) {
            axios.get(PATIENT_URLS.PATIENT_RECORD(patient_id, 4))
                .then(res => {
                    setStressors(res.data[0].answers[0].questions)
                })
                .catch(err => Sentry.captureException(err))

            axios.get(PATIENT_URLS.PATIENT_RECORD(patient_id, 2))
                .then(res => {
                    setClinical(res.data[0].answers[2].questions)
                })
                .catch(err => Sentry.captureException(err))
        }

    }, [patient_id])

    return (
        <>
            <div className="col-12">
                <div className="card">
                    <div className="card-body wizard-content">
                        <div className="tab-wizard wizard-circle">
                            <section>
                                <div className="row">
                                    <div className="col-md-12">
                                        <h4 className='text-uppercase'><strong>Diagnosis Psychiatric Axis I</strong>
                                        </h4>
                                        <br/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor="firstName1">Point 1:</label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Input className="col-12"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor="firstName1">Point 2:</label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Input className="col-12"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor="firstName1">Point 3:</label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Input className="col-12"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor="firstName1">Point 4:</label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Input className="col-12"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor="firstName1">Point 5:</label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Input className="col-12"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor="firstName1">Point 6:</label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Input className="col-12"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section>
                                <div className="row">
                                    <div className="col-md-12">
                                        <h4 className='text-uppercase'><strong>Diagnosis Axis II</strong>
                                        </h4>
                                        <br/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor="firstName1">Point 1:</label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Input className="col-12"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor="firstName1">Point 2:</label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Input className="col-12"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor="firstName1">Point 3:</label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Input className="col-12"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor="firstName1">Point 4:</label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Input className="col-12"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section>
                                <div className="row">
                                    <div className="col-md-12">
                                        <h4 className='text-uppercase'><strong>Diagnosis - Medical Axis III</strong>
                                        </h4>
                                        <br/>
                                    </div>
                                </div>
                                <div className="row">
                                    {
                                        clinical.map((exam, index) => {
                                            return <div className="col-md-6">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="firstName1">{exam.question}:</label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Input className="col-12" disabled
                                                                   value={!exam.answer ? 'N/A' : exam.answer}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                            </section>

                            <section>
                                <div className="row">
                                    <div className="col-md-12">
                                        <h4 className='text-uppercase'><strong>Diagnosis - Stressor IV</strong></h4>
                                        <br/>
                                    </div>
                                </div>
                                <div className="row">
                                    {
                                        stressor.map((stress, index) => {
                                            return <div key={index} className="col-md-6">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="firstName1">Stressor {index + 1}:</label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Input className="col-12" disabled value={stress.answer}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }
                                    {
                                        stressor.length === 0 ? <p className='px-3'>No stressors</p> : null
                                    }
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}