export class BaseModel {
    constructor(userId, username) {
        this.userId = userId;
        this.username = username;
    }

    getBase(){
        return {
            created_by : {
                id:this.userId,
                name: this.username
            },
            updated_by: {
                id:this.userId,
                name: this.username
            }
        }
    }
}
