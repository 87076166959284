import React, {useRef,useState,useEffect} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faTimesCircle, faFilePdf} from "@fortawesome/free-solid-svg-icons";
import AddPatientNote from "components/Forms/Note/AddPatientNote";
import PreviousNotes from "components/Forms/Note/PreviousNotes";
import {useAuthentication} from "../Hooks/AuthHook";
import {useUtils} from "../Hooks/utils";
import html2pdf from 'html2pdf.js';
import Logo from 'assets/img/brand/Icon/SVG/Icon.svg'
import {PrinterIcon} from "@heroicons/react/24/outline";
import {PatientAnswers} from "../record/patient-answers";
import {FileDrag} from "../Patient/fileDrag";
import { usePatient } from '../Hooks/PatientHook';

const Record = ({patient_record, show_no_answers = false}) => {
    const patient = patient_record && patient_record.length > 0 ? patient_record[0] : null;
    const [user, setUser] = useState(null);
    

    let { getSexFromIdNumber, getAgeFromIdNumber, getBirthdateFromIdNumber} = usePatient();
    let {ConvertDateToString, DownloadPdf, convertFullDateToString} = useUtils()
    const display_answer = (answer) => {
        if (answer === "Yes") {
            return <FontAwesomeIcon icon={faCheckCircle} style={{color: "#27ae60"}}/>;
        }
        if (answer === "No") {
            return <FontAwesomeIcon icon={faTimesCircle} style={{color: "#e74c3c"}}/>;
        }
        if (answer === "") return "N/A";
        return answer;
    };
    console.log(patient)

    

    let {hasPermission} = useAuthentication();
    let {exportPdf} = useUtils();
    const exportRef = useRef();

    function can_display(answer) {
        return !(answer === "No" && !show_no_answers);
    }


    function handleDownloadClick(filename) {
        const element = document.getElementById('printArea');
        const options = {
            margin: [10, 5, 0, 0], // top, right, bottom, left margins
            filename,
            image: { type: 'jpeg', quality: 1 },
            html2canvas: { dpi: 2000, letterRendering: true, disableFontFace: true },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
        };
        
        
        html2pdf().set(options).from(element).toPdf().get('pdf').then(function (pdf) {
            const totalPages = pdf.internal.getNumberOfPages();
    
            
            for (let i = 1; i <= totalPages; i++) {
                pdf.setPage(i);
                pdf.setFontSize(10);
                pdf.text('Page ' + i + ' of ' + totalPages, pdf.internal.pageSize.width / 2, pdf.internal.pageSize.height - 10, {
                    align: 'center'
                });
            }
        }).save();
    }
    


    return (
        <>
            <div className="grid grid-cols-12 gap-4">
                <div className='col-span-8'>
                    <div className='w-20 m-auto'>
                        <span className="isolate inline-flex rounded-md shadow-sm m-auto mb-6 mt-6 align-middle w-20">
                          <button
                              type="button"
                              className="relative inline-flex items-center gap-x-1.5 rounded-md bg-white px-10 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                              onClick={() => handleDownloadClick((patient?.record_patient?.id_number))}
                          >
                            <PrinterIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true"/>
                            Print
                          </button>
                        </span>
                    </div>
                    <div className='shadow-2xl w-full rounded border-2 border-r-2 mb-8 p-3'>
                        <div id={'printArea'}>
                        <div className="col-span-12 mt-5 ml-12 flex justify-between items-center">
                                {/* Logo on the left */}
                                <div className="col-span-3">
                                    <img
                                        className="mb-2 mt-8 h-18 w-auto"
                                        alt="Logo"
                                        src={patient?.record_patient?.practice[0]?.logo}
                                    />
                                </div>
                                
                                {/* Practice details on the right */}
                                <div className="col-span-5 mr-10 text-right">
                                    <h3 className="font-bold text-xs">
                                        {patient?.record_patient?.practice[0]?.name} ({patient?.record_patient?.practice[0]?.practice_no})
                                    </h3>
                                    <h3 className="text-xs">{patient?.record_patient?.practice[0]?.address}</h3>
                                    <h3 className="text-xs">{patient?.record_patient?.practice[0]?.email}</h3>
                                    <h3 className="text-xs">{patient?.record_patient?.practice[0]?.contact}</h3>
                                </div>
                            </div>

                            
                            <h2 className='text-center mt-2 text-xl font-bold'>{patient?.record_form?.name}</h2>
                            
                            <div className="col-span-4 ml-12 mt-3  mr-10  border-b border-gray-300 py-3">
                            
                                <h1 className="font-bold border-t border-b text-center border-gray-300 py-3">Patient Details</h1>
                                
                                <div className="grid grid-cols-2 gap-x-2 text-right">
                                    <span className="font-bold text-xs text-left">Name</span>
                                    <h3 className="text-xs">{patient?.record_patient?.first_name} {patient?.record_patient?.last_name}</h3>
                                    
                                    <span className="font-bold text-xs text-left">Birthdate</span>
                                    <h3 className="text-xs">{getBirthdateFromIdNumber(patient?.record_patient?.id_number)}</h3>
                                    
                                    <span className="font-bold text-xs text-left">Age</span>
                                    <h3 className="text-xs">{getAgeFromIdNumber(patient?.record_patient?.id_number)}</h3>
                                    
                                    <span className="font-bold text-xs text-left">Created</span>
                                    <h3 className="text-xs mb-5">{convertFullDateToString(patient?.created)}</h3>
                                </div>
                            </div>

                            <PatientAnswers answers={patient?.answers}/>
                        </div>
                    </div>
                </div>
                <div className='col-span-4'> 
                    <div className='w-20 m-auto h-20 mt-1'>
                        <span className="isolate inline-flex rounded-md shadow-sm m-auto mb-6 mt-6 align-middle w-20">

                        </span>
                    </div>
                    <section aria-labelledby="announcements-title">
                        <div className="overflow-hidden bg-white border-2 border-gray-200 rounded shadow-xl">
                            <div className="p-6">
                                <h2 className="text-base font-medium text-gray-600" id="announcements-title">
                                    Documents
                                </h2>
                                <div className="mt-6 flow-root">
                                    <FileDrag patient_id={patient?.record_patient?.id_number}/>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section aria-labelledby="recent-hires-title">
                        <div className="overflow-hidden bg-white border-2 border-gray-200 rounded shadow-xl mt-4">
                            <div className="p-6">
                                <h2 className="text-base font-medium text-gray-700" id="recent-hires-title">
                                    Notes
                                </h2>
                                <div className="mt-6 flow-root">
                                    <AddPatientNote patient_id={patient?.record_patient?.id_number}/>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>

        </>
    );
};
export default Record;
