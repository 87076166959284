import React, {useState} from "react";
import treatment_questions from "components/Forms/treatment-plan/questions/treatment-questions.json"
import medication_questions from "components/Forms/shared-questions/medications-questions.json"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faSignature, faUserNurse, faTablets, faFileMedicalAlt} from "@fortawesome/free-solid-svg-icons"
import {Signature} from "components/SignaturePad/signature"
import problem_list from "components/Forms/ClinicalExam/questions/problem_list.json"
import risk_list from "components/Forms/evaluation/questions/risk_list.json"
import {DiagnosisInfo} from "components/Forms/evaluation/diagnosisInfo"
import {FORMS} from "../../../constants/forms"
import {FormPageTemplate} from "../../templates/form-page-template"
import {QuestionSectionTemplate} from "../../templates/question-section-template"

export default function PsychiatristFollowUp({patient_id}) {
    const [checklist, setChecklist] = useState(treatment_questions);
    const [problemList, setProblemList] = useState(problem_list);
    const [medicationList, setMedicationList] = useState(medication_questions);
    const [fillingUser, setUser] = useState({})
    const [patientRecord, setPatientRecord] = useState({})
    const [riskList, setRiskList] = useState(risk_list)
    const [psych, setPsych] = useState([]);

    const formSteps = [
        {
            number: '1',
            label: 'Problem List',
            description: 'Fill in problem list',
            component: <QuestionSectionTemplate list={problemList} setMethod={setProblemList}/>,
            icon: <FontAwesomeIcon icon={faFileMedicalAlt} size={'3x'}/>
        },
        {
            number: '2',
            label: 'Treatment Plan',
            description: 'Treatment Plan',
            component: <QuestionSectionTemplate list={checklist} setMethod={setChecklist}/>,
            icon: <FontAwesomeIcon icon={faUserNurse} size={'3x'}/>
        },
        {
            number: '3',
            label: 'Medication',
            description: 'Medication',
            component: <QuestionSectionTemplate list={medicationList} setMethod={setMedicationList}/>,
            icon: <FontAwesomeIcon icon={faTablets} size={'3x'}/>
        },
        {
            number: '4',
            label: 'Diagnosis',
            description: 'Fill in patient diagnosis',
            component: <DiagnosisInfo patient_id={patient_id} psych={psych} setMethod={setPsych}/>,
            icon: <FontAwesomeIcon icon={faFileMedicalAlt} size={'3x'}/>
        },
        {
            number: '5',
            label: 'Risk Assessment',
            description: 'Fill in patient risk assessment',
            component: <QuestionSectionTemplate list={riskList} setMethod={setRiskList}/>,
            icon: <FontAwesomeIcon icon={faFileMedicalAlt} size={'3x'}/>
        },
        {
            number: '6',
            label: 'Confirmation',
            description: 'Sign & Confirm Check-in',
            component: <Signature setUser={setUser}/>,
            icon: <FontAwesomeIcon icon={faSignature} size={'3x'}/>
        }
    ]

    return <>
        <FormPageTemplate formSteps={formSteps} form_type_id={FORMS.PSYCHIATRIST_FOLLOWUP} patient_id={patient_id}
                          patient_record={patientRecord}/>
    </>
}