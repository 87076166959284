import React, { useEffect, useState } from "react";
import { Section } from "../Question/Section";
import axios from "axios";
import { PATIENT_URLS } from "components/Api/Config";
import Question from "components/Forms/Question";

export const ScreeningForm = ({ patient_id = null, list = [], setMethod }) => {
    const [lis, setLis] = useState([])
    let temp = [];
    useEffect(() => {
        if (patient_id !== null) {
            axios.get(PATIENT_URLS.PATIENT_RECORD(patient_id, 2))
                .then(res => {
                    let q = res.data[0].answers[0].questions
                    let base = res.data

                    list.map(item => {
                        try {
                            item.required_section.map(i => {
                                let record = q.find(function (sec) {
                                    return sec.id === i.toString()
                                })
                                if (record.answer === "Yes") {
                                    temp.push(item)
                                }
                            })
                        } catch (e) {
                            console.error("error")
                        }
                    })
                    setLis(temp)
                    setMethod(base)
                })
                .catch(err => {
                    console.error(err)
                })
        }

    }, [patient_id])

    return (
        <div className="col-12">
            <div className="card">
                <div className="card-body wizard-content">
                    <div className="tab-wizard wizard-circle">
                        <div className="row col-12 text-uppercase">
                            <h4><strong>INSTRUCTIONS</strong></h4>
                        </div>
                        <div className="row col-12 pb-5">

                            <br />
                            <p className='mb--4'>Please take the time to respond to the following questions as truthful
                                and as much accurate as
                                possible, reflecting on your observations for the <strong>last 3 months</strong>. It
                                will assist in focusing the assessment at your
                                next appointment and make your contact with the psychiatrist more meaningful. Make sure
                                to respond to all
                                questions.</p>
                            <hr className="col-12 pt--5" />
                        </div>
                        <section>
                            <div className="row">
                                {
                                    lis.map((section, index) => {
                                        return <>
                                            <div className="row col-12 text-uppercase mb-4">
                                                <h4 className='mb-4'><strong>{section.title}</strong></h4>
                                                <hr className="col-12 pt--5" />
                                            </div>
                                            {
                                                section.questions.map((question, key) => {
                                                    return (
                                                        <Question key={key} question_data={question} list={list}
                                                            setMethod={setMethod}
                                                            parent={section.id} />
                                                    )
                                                })
                                            }
                                        </>
                                    })
                                }
                                {
                                    patient_id === null ? list.map((section, index) => {
                                        return (
                                            <Section key={index} section={section} list={list} setMethod={setMethod} />
                                        )
                                    }) : null
                                }
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}