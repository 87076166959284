import React, {useState} from "react";
import treatment_questions from "components/Forms/treatment-plan/questions/treatment-questions.json";
import medication_questions from "components/Forms/shared-questions/medications-questions.json";
import referral_questions from "components/Forms/shared-questions/referral_services.json";
import {useAuthentication} from "components/Hooks/AuthHook";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignature, faUserNurse, faTablets, faBookMedical} from "@fortawesome/free-solid-svg-icons";
import {Signature} from "components/SignaturePad/signature";
import {TreatmentInfoForm} from "components/Forms/treatment-plan/treatment-info";
import {FormPageTemplate} from "../../templates/form-page-template";
import {QuestionSectionTemplate} from "../../templates/question-section-template";


export default function TreatmentPlan({patient_id}) {
    const [checklist, setChecklist] = useState(treatment_questions);
    const [medicationList, setMedicationList] = useState(medication_questions);
    const [referralList, setReferralList] = useState(referral_questions);
    const [fillingUser, setUser] = useState({});
    const [patientRecord, setPatientRecord] = useState({});
    const [progress, setProgress] = useState(0)
    let {user} = useAuthentication()

    const formSteps = [
        {
            number: '1',
            label: 'Treatment Plan',
            description: 'Suggested Management Plan',
            component: <QuestionSectionTemplate list={checklist} setMethod={setChecklist}/>,
            icon: <FontAwesomeIcon icon={faUserNurse} size={'3x'}/>
        },
        {
            number: '2',
            label: 'Prescription',
            description: 'List of Medication',
            component: <QuestionSectionTemplate list={medicationList} setMethod={setMedicationList}/>,
            // TODO: Add on above 19
            // {
            //     "id": "5",
            //     "question": "Workplace Accomodations",
            //     "answer": "Not Completed",
            //     "type": "dropdown",
            //     "branching": true,
            //     "options": [
            //         "N/A",
            //         "To Do",
            //         "Done"
            //     ]
            // }
            icon: <FontAwesomeIcon icon={faTablets} size={'3x'}/>
        },
        {
            number: '3',
            label: 'Referrals',
            description: 'Referral to Other Services',
            component: <QuestionSectionTemplate list={referralList} setMethod={setReferralList}/>,
            icon: <FontAwesomeIcon icon={faBookMedical} size={'3x'}/>
        },
        {
            number: '4',
            label: 'Confirmation',
            description: 'Sign & Confirm Check-in',
            component: <Signature setUser={setUser}/>,
            icon: <FontAwesomeIcon icon={faSignature} size={'3x'}/>
        }
    ];

    return <>
        <FormPageTemplate formSteps={formSteps} form_type_id={13} patient_id={patient_id}
                          patient_record={patientRecord}/>
    </>
}