import {useLocation} from "react-router-dom";
import {useEffect} from "react";
import * as ReactGA from "react-ga";

export default function GoogleAnalytics() {
    let location = useLocation();

    function scroll_up() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }

    useEffect(() => {
        ReactGA.initialize('G-TJ1KGXHEB1');
        scroll_up()
    }, []);

    useEffect(() => {
        ReactGA.pageview(location.pathname);
        scroll_up()
    }, [location]);

    return <></>
}
