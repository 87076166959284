import React, {useState} from "react";
import {Card, CardBody, Row} from "reactstrap";
import 'assets/css/switch.css'

import {Switch} from "antd";

export const CaregiversForm = ({childhood, setMethod}) => {

    function set_answer(id, question, answer) {
        let exists = childhood.find(function (sec) {
            return sec.id === id
        })

        if (exists) {
            let temp = childhood
            temp[childhood.indexOf(exists)] = {
                id: id,
                type: 'text',
                question: question,
                answer: answer
            }
            setMethod(temp)
        } else {
            setMethod([
                ...childhood,
                {
                    id: id,
                    type: 'text',
                    question: question,
                    answer: answer
                }
            ])
        }
    }

    return (
        <div className="col-12">
            <div className="card">
                <div className="card-body wizard-content">
                    <div className="tab-wizard wizard-circle">
                        <section>
                            <div className="row">
                                <div className="col-md-12">
                                    <h4>
                                        <strong>Main Caregiver/s (0-3 years)</strong>
                                    </h4>
                                    <br/>
                                </div>
                            </div>

                            <div className="row">
                                <Card className="mb-4 mb-xl-1 col-md-12">
                                    <CardBody>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="firstName1">Mother: </label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Switch checkedChildren="Yes"
                                                                    unCheckedChildren="No"
                                                                    defaultChecked={false}
                                                                    onChange={checked => set_answer(1, 'Mother', checked ? 'Yes' : 'No')}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="firstName1">Father: </label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Switch checkedChildren="Yes"
                                                                    unCheckedChildren="No"
                                                                    defaultChecked={false}
                                                                    onChange={checked => set_answer(2, 'Father', checked ? 'Yes' : 'No')}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="firstName1">Maternal Grandmother: </label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Switch checkedChildren="Yes"
                                                                    unCheckedChildren="No"
                                                                    defaultChecked={false}
                                                                    onChange={checked => set_answer(3, 'Maternal Grandmother', checked ? 'Yes' : 'No')}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="firstName1">Paternal Grandmother: </label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Switch checkedChildren="Yes"
                                                                    unCheckedChildren="No"
                                                                    defaultChecked={false}
                                                                    onChange={checked => set_answer(4, 'Paternal Grandmother', checked ? 'Yes' : 'No')}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="firstName1">Maternal Grandfather: </label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Switch checkedChildren="Yes"
                                                                    unCheckedChildren="No"
                                                                    defaultChecked={false}
                                                                    onChange={checked => set_answer(5, 'Maternal Grandfather', checked ? 'Yes' : 'No')}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="firstName1">Paternal Grandfather: </label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Switch checkedChildren="Yes"
                                                                    unCheckedChildren="No"
                                                                    defaultChecked={false}
                                                                    onChange={checked => set_answer(6, 'Paternal Grandfather', checked ? 'Yes' : 'No')}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="firstName1">Maternal Aunt: </label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Switch checkedChildren="Yes"
                                                                    unCheckedChildren="No"
                                                                    defaultChecked={false}
                                                                    onChange={checked => set_answer(7, 'Maternal Aunt', checked ? 'Yes' : 'No')}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="firstName1">Paternal Aunt: </label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Switch checkedChildren="Yes"
                                                                    unCheckedChildren="No"
                                                                    defaultChecked={false}
                                                                    onChange={checked => set_answer(8, 'Paternal Aunt', checked ? 'Yes' : 'No')}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="firstName1">Maternal Uncle: </label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Switch checkedChildren="Yes"
                                                                    unCheckedChildren="No"
                                                                    defaultChecked={false}
                                                                    onChange={checked => set_answer(9, 'Maternal Uncle', checked ? 'Yes' : 'No')}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="firstName1">Paternal Uncle: </label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Switch checkedChildren="Yes"
                                                                    unCheckedChildren="No"
                                                                    defaultChecked={false}
                                                                    onChange={checked => set_answer(10, 'Paternal Uncle', checked ? 'Yes' : 'No')}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="firstName1">Stepmother: </label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Switch checkedChildren="Yes"
                                                                    unCheckedChildren="No"
                                                                    defaultChecked={false}
                                                                    onChange={checked => set_answer(11, 'Stepmother', checked ? 'Yes' : 'No')}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="firstName1">Stepfather: </label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Switch checkedChildren="Yes"
                                                                    unCheckedChildren="No"
                                                                    defaultChecked={false}
                                                                    onChange={checked => set_answer(12, 'Stepfather', checked ? 'Yes' : 'No')}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="firstName1">Children's Home: </label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Switch checkedChildren="Yes"
                                                                    unCheckedChildren="No"
                                                                    defaultChecked={false}
                                                                    onChange={checked => set_answer(13, 'Childrens Home', checked ? 'Yes' : 'No')}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="firstName1">Adoptive Mother: </label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Switch checkedChildren="Yes"
                                                                    unCheckedChildren="No"
                                                                    defaultChecked={false}
                                                                    onChange={checked => set_answer(14, 'Adoptive Mother', checked ? 'Yes' : 'No')}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="firstName1">Adoptive Father: </label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Switch checkedChildren="Yes"
                                                                    unCheckedChildren="No"
                                                                    defaultChecked={false}
                                                                    onChange={checked => set_answer(15, 'Adaptive Father', checked ? 'Yes' : 'No')}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="firstName1">Forster Care: </label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Switch checkedChildren="Yes"
                                                                    unCheckedChildren="No"
                                                                    defaultChecked={false}
                                                                    onChange={checked => set_answer(16, 'Forster Care', checked ? 'Yes' : 'No')}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="firstName1">Sibling: </label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Switch checkedChildren="Yes"
                                                                    unCheckedChildren="No"
                                                                    defaultChecked={false}
                                                                    onChange={checked => set_answer(17, 'Sibling', checked ? 'Yes' : 'No')}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="firstName1">Nanny: </label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Switch checkedChildren="Yes"
                                                                    unCheckedChildren="No"
                                                                    defaultChecked={false}
                                                                    onChange={checked => set_answer(18, 'Nanny', checked ? 'Yes' : 'No')}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className='row'>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="lastName1">Notes: </label>
                                        <textarea className="form-control" rows="3" placeholder="Text Here..."/>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}