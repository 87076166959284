import React, {useState} from "react";
import {AutoComplete, DatePicker, Input, Select, Switch} from "antd";
import OriginalOptions from '../Intake/questions/clinicial_list.json'

export const PsychiatricHxInfo = ({psych, setMethod}) => {
    const [history, setHistory] = useState(false)
    const [contact, setContact] = useState(false)
    const [options, setOptions] = useState([])

    const [past, setPast] = useState([])
    const [hist, setHist] = useState([])

    const onSearch = (searchText) => {
        setOptions(
            !searchText ? [] : mockVal(searchText),
        );
    };

    function mockVal(search) {
        return OriginalOptions.filter(function (str) {
            return str.value.toLowerCase().includes(search.toLowerCase())
        })
    }

    function set_answer(id, question, answer, list, set) {
        let exists = list.find(function (sec) {
            return sec.id === id
        })

        if (exists) {
            let temp = list
            temp[list.indexOf(exists)] = {
                id: id,
                type: 'text',
                question: question,
                answer: answer
            }
            set(temp)
        } else {
            set([
                ...list,
                {
                    id: id,
                    type: 'text',
                    question: question,
                    answer: answer
                }
            ])
        }
        setMethod([{
            "id": "3",
            "title": "Past Psychiatric History",
            "questions": past
        }, {
            "id": "3",
            "title": "Clinical History",
            "questions": hist
        }])
    }

    return (
        <>
            <div className="col-12">
                <div className="card">
                    <div className="card-body wizard-content">
                        <div className="tab-wizard wizard-circle">
                            <section>
                                <div className="row">
                                    <div className="row col-12 text-uppercase">
                                        <h4><strong>Past Psychiatric History</strong></h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor="firstName1">Past Psychiatric
                                                        History:</label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Switch checkedChildren="Yes"
                                                            unCheckedChildren="No"
                                                            defaultChecked={history}
                                                            onChange={() => {
                                                                setHistory(!history)
                                                            }}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    history ?
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="firstName1">Age(in years) at first sign of
                                                                your illness:</label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <input 
                                                                type="number" 
                                                                className="form-control"
                                                                style={{ height: '33px' }} 
                                                                placeholder="Age" 
                                                                onChange={(event) => {
                                                                    const value = event.target.value;
                                                                    set_answer(1, 'Age (in years) at first sign of your illness', value, past, setPast);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="lastName1">Date of first contact:</label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <DatePicker className="col-12" onChange={(date, dateString)=>{
                                                                set_answer(2, 'Date of first contact', dateString, past, setPast)
                                                            }}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="firstName1">Number of previous psych
                                                                admissions:</label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Input type="text" className="col-12" id="firstName1" onChange={event=>{
                                                                set_answer(3, 'Number of previous psych admissions', event.target.value, past, setPast)
                                                            }}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="lastName1">Date of most recent
                                                                admission:</label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <DatePicker className="col-12" onChange={(date, dateString)=>{
                                                                set_answer(4, 'Date of most recent admission', dateString, past, setPast)
                                                            }}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="lastName1">Reason for admission:</label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Select className="col-12" onChange={value => set_answer(5, 'Reason for admission', value, past, setPast)}>
                                                                <option value={'1'}>Stopped Medication</option>
                                                                <option value={'2'}>Alcohol & Drugs</option>
                                                                <option value={'3'}>Stress</option>
                                                            </Select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : null
                                }
                                <div className="row">
                                    <div className="row col-12 text-uppercase">
                                        <h4><strong>Clinician History</strong></h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor="firstName1">Are there any current clinician(s)
                                                        contact in the past 6 months:</label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Switch checkedChildren="Yes"
                                                            unCheckedChildren="No"
                                                            defaultChecked={contact}
                                                            onChange={() => {
                                                                setContact(!contact)
                                                            }}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    contact ?
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="firstName1">Clinician:</label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <AutoComplete
                                                                dropdownClassName="certain-category-search-dropdown col-12"
                                                                dropdownMatchSelectWidth={500}
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                                options={options}
                                                                onSearch={onSearch}
                                                                onChange={value => set_answer(1, 'Clinician 1', value, hist, setHist)}
                                                            >
                                                                <Input.Search placeholder="Type Clinician"
                                                                              className="col-12"/>
                                                            </AutoComplete>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="lastName1">Frequency:</label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Select className="col-12" onChange={value => set_answer(2, 'Clinician 1 Frequency', value, hist, setHist)}>
                                                                <option value={1}>1</option>
                                                                <option value={2}>2</option>
                                                                <option value={3}>3</option>
                                                                <option value={4}>4</option>
                                                                <option value={5}>5</option>
                                                                <option value={6}>6</option>
                                                                <option value={7}>7</option>
                                                                <option value={8}>8</option>
                                                                <option value={9}>9</option>
                                                                <option value={10}>10</option>
                                                            </Select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="firstName1">Clinician:</label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <AutoComplete
                                                                dropdownClassName="certain-category-search-dropdown col-12"
                                                                dropdownMatchSelectWidth={500}
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                                options={options}
                                                                onSearch={onSearch}
                                                                onChange={value => set_answer(3, 'Clinician 2', value, hist, setHist)}
                                                            >
                                                                <Input.Search placeholder="Type Clinician"
                                                                              className="col-12"/>
                                                            </AutoComplete>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="lastName1">Frequency:</label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Select className="col-12" onChange={value => set_answer(4, 'Clinician 2 Frequency', value, hist, setHist)}>
                                                                <option value={1}>1</option>
                                                                <option value={2}>2</option>
                                                                <option value={3}>3</option>
                                                                <option value={4}>4</option>
                                                                <option value={5}>5</option>
                                                                <option value={6}>6</option>
                                                                <option value={7}>7</option>
                                                                <option value={8}>8</option>
                                                                <option value={9}>9</option>
                                                                <option value={10}>10</option>
                                                            </Select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="firstName1">Clinician:</label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <AutoComplete
                                                                dropdownClassName="certain-category-search-dropdown col-12"
                                                                dropdownMatchSelectWidth={500}
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                                options={options}
                                                                onSearch={onSearch}
                                                                onChange={value => set_answer(5, 'Clinician 3', value, hist, setHist)}
                                                            >
                                                                <Input.Search placeholder="Type Clinician"
                                                                              className="col-12"/>
                                                            </AutoComplete>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="lastName1">Frequency:</label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Select className="col-12" onChange={value => set_answer(6, 'Clinician 3 Frequency', value, hist, setHist)}>
                                                                <option value={1}>1</option>
                                                                <option value={2}>2</option>
                                                                <option value={3}>3</option>
                                                                <option value={4}>4</option>
                                                                <option value={5}>5</option>
                                                                <option value={6}>6</option>
                                                                <option value={7}>7</option>
                                                                <option value={8}>8</option>
                                                                <option value={9}>9</option>
                                                                <option value={10}>10</option>
                                                            </Select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="firstName1">Clinician:</label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <AutoComplete
                                                                dropdownClassName="certain-category-search-dropdown col-12"
                                                                dropdownMatchSelectWidth={500}
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                                options={options}
                                                                onSearch={onSearch}
                                                                onChange={value => set_answer(7, 'Clinician 4', value, hist, setHist)}
                                                            >
                                                                <Input.Search placeholder="Type Clinician"
                                                                              className="col-12"/>
                                                            </AutoComplete>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="lastName1">Frequency:</label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Select className="col-12" onChange={value => set_answer(8, 'Clinician 4 Frequency', value, hist, setHist)}>
                                                                <option value={1}>1</option>
                                                                <option value={2}>2</option>
                                                                <option value={3}>3</option>
                                                                <option value={4}>4</option>
                                                                <option value={5}>5</option>
                                                                <option value={6}>6</option>
                                                                <option value={7}>7</option>
                                                                <option value={8}>8</option>
                                                                <option value={9}>9</option>
                                                                <option value={10}>10</option>
                                                            </Select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="firstName1">Clinician:</label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <AutoComplete
                                                                dropdownClassName="certain-category-search-dropdown col-12"
                                                                dropdownMatchSelectWidth={500}
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                                options={options}
                                                                onSearch={onSearch}
                                                                onChange={value => set_answer(9, 'Clinician 5', value, hist, setHist)}
                                                            >
                                                                <Input.Search placeholder="Type Clinician"
                                                                              className="col-12"/>
                                                            </AutoComplete>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="lastName1">Frequency:</label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Select className="col-12" onChange={value => set_answer(10, 'Clinician 5 Frequency', value, hist, setHist)}>
                                                                <option value={1}>1</option>
                                                                <option value={2}>2</option>
                                                                <option value={3}>3</option>
                                                                <option value={4}>4</option>
                                                                <option value={5}>5</option>
                                                                <option value={6}>6</option>
                                                                <option value={7}>7</option>
                                                                <option value={8}>8</option>
                                                                <option value={9}>9</option>
                                                                <option value={10}>10</option>
                                                            </Select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="firstName1">Clinician:</label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <AutoComplete
                                                                dropdownClassName="certain-category-search-dropdown col-12"
                                                                dropdownMatchSelectWidth={500}
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                                options={options}
                                                                onSearch={onSearch}
                                                                onChange={value => set_answer(11, 'Clinician 6', value, hist, setHist)}
                                                            >
                                                                <Input.Search placeholder="Type Clinician"
                                                                              className="col-12"/>
                                                            </AutoComplete>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="lastName1">Frequency:</label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Select className="col-12" onChange={value => set_answer(12, 'Clinician 6 Frequency', value, hist, setHist)}>
                                                                <option value={1}>1</option>
                                                                <option value={2}>2</option>
                                                                <option value={3}>3</option>
                                                                <option value={4}>4</option>
                                                                <option value={5}>5</option>
                                                                <option value={6}>6</option>
                                                                <option value={7}>7</option>
                                                                <option value={8}>8</option>
                                                                <option value={9}>9</option>
                                                                <option value={10}>10</option>
                                                            </Select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : null
                                }
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}