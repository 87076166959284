import React, {useState, useEffect} from "react";
import {Row} from "reactstrap";
import {PATIENT_URLS} from "../../Api/Config";
import {useAuthentication} from "../../Hooks/AuthHook";
import axios from "axios";
import Loader from "../../Loader";

const PreviousNotes = ({patient_id}) => {
    const [notes, setNotes] = useState([])
    const [loaded, setLoaded] = useState(false)

    let {user} = useAuthentication();
    let {hasPermission} = useAuthentication();

    function get_notes() {
        axios.get(PATIENT_URLS.NOTES, {
            params: {
                patient_id: patient_id,
                clinician_id: user().id_number,
                clinical_note: hasPermission('can_view_medical_note')
            }
        })
            .then(function (response) {
                setNotes(response.data)
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => setLoaded(true))
    }

    useEffect(get_notes, [patient_id])

    return (
        <div className="card bg-gray shadow">
            <div className="card-header bg-transparent">
                <h3 className="mb-0 text-white text-center">Previous Notes</h3>
            </div>
            <div className="card-body">
                <Row>
                    {
                        loaded ?
                            <div className="col-md-12 text-white">
                                {
                                    notes.map((note, key) => {
                                        return <>
                                            <div dangerouslySetInnerHTML={{ __html: note.note }} />
                                            <hr className='col-8 bg-white'/>
                                        </>
                                    })
                                }
                                {
                                    notes.length === 0 ? <p>No Previous Notes</p> : null
                                }
                            </div> : <div className="col-md-12 text-white text-center"><Loader/></div>
                    }
                </Row>
            </div>
        </div>
    )
}
export default PreviousNotes;