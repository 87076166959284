import React from "react";
import {Container} from "reactstrap";
import AdminNavbar from "components/Navbar/AdminNavbar";

class Header extends React.Component {
    render() {
        return (
            <>
                <div className="header bg-dark pb-3 pt-0 pt-md-0">
                    <Container fluid>
                        <div className="header-body">
                            <AdminNavbar/>
                        </div>
                    </Container>
                </div>
            </>
        );
    }
}

export default Header;
