import React, {useState, useEffect} from "react";
import {Link,} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPhone} from "@fortawesome/free-solid-svg-icons";
import {CMS_URLS, FORM_URLS} from "components/Api/Config";
import Loader from "components/Loader";
import '../../assets/css/profile-card.css';
import {useAuthentication} from "../Hooks/AuthHook";
import {useUtils} from "../Hooks/utils";
import {ApiConsumer} from "components/Api/ApiConsumer";
import {Menu, Transition} from "@headlessui/react";
import {EllipsisHorizontalIcon} from "@heroicons/react/24/outline";
import { Fragment } from 'react'
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function PatientCard({patient}) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [patient_info, setPatientInfo] = useState({name: 'Loading', surname: '...', city: '...'})
    const [forms, setForms] = useState([])
    const [formTypes, setFormTypes] = useState([])
    let {hasPermission} = useAuthentication();

    let {formatPhoneNumber} = useUtils()

    useEffect(() => {
        async function fetchRecords() {
            const response = await fetch(FORM_URLS.PATIENT_FORMS(patient?.id_number));
            return await response.json();
        }

        async function fetchFormTypes() {
            const response = await fetch(FORM_URLS.FORM_TYPES);
            return await response.json();
        }

        async function fetchPatientInfo() {
            const response = await fetch(CMS_URLS.USERS.DETAILS(patient?.id_number))
            return await response.json()
        }

        fetchFormTypes().then(res => setFormTypes(res))
        fetchRecords().then(res => setForms(res))
        fetchPatientInfo().then(res => setPatientInfo(res))
    }, [patient])


    function form_exists(form_id) {
        if (!forms.length > 0) {
            return false
        }

        return forms.find(obj => {
            return obj.record_form.id === form_id
        }) !== undefined;
    }

    function send_form(name, cellphone, form_name, form_link) {
        const payload = {
            "name": patient?.first_name,
            "cellphone": patient?.cellphone,
            "form_name": form_name,
            "form_link": form_link,
            "id_number": patient?.id_number
        }

        ApiConsumer.post(FORM_URLS.FORM_COMMUNICATION, payload).catch(err => console.error(err))
    }

    const toggle = () => setDropdownOpen(prevState => !prevState);
    return <>
        <div className="col-xs-12 col-sm-12 col-md-12">
            <div className="card-container mb-4 bg-green-500 shadow-2xl border-2 border-green-600">

                <div className="flex items-center gap-x-4  pr-6">

                    <Menu as="div" className="relative ml-auto">
                        <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                            <span className="sr-only">Open options</span>
                            <EllipsisHorizontalIcon className="h-5 w-5 text-white" aria-hidden="true" />
                        </Menu.Button>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                {
                                    formTypes.filter(function (x) {
                                        return x.sendable;
                                    }).map((types, index) => {
                                        return <Menu.Item>
                                            {({ active }) => (
                                                <div
                                                    className={classNames(
                                                        active ? 'bg-gray-50' : '',
                                                        'block px-3 py-1 text-sm leading-6 text-gray-900'
                                                    )}
                                                    onClick={() => {
                                                        send_form(patient_info.name, patient_info.phone, types.name, types.url)
                                                    }}
                                                >
                                                    Send {types.name}
                                                </div>
                                            )}
                                        </Menu.Item>
                                    })
                                }



                            </Menu.Items>
                        </Transition>
                    </Menu>
                </div>




                {hasPermission('can_view_patient_profile') ?
                    <Link to={`/profile/${patient?.id_number}`}>
                        <>
                            <h3 className="text-white">{`${patient?.first_name} ${patient?.last_name}`}</h3>
                            <h5 className="text-white"><FontAwesomeIcon icon={faPhone}/> {`  ${formatPhoneNumber(patient?.cellphone)}`}</h5>
                        </>
                    </Link>
                    :
                    <>
                        <h3 className="text-white">{`${patient?.first_name} ${patient?.last_name}`}</h3>
                        <h5 className="text-white"><FontAwesomeIcon icon={faPhone}/> {`  ${formatPhoneNumber(patient?.cellphone)}`}</h5>
                    </>
                }
                <div className="skills">
                    <h6 className='text-white'>Patient Records</h6>
                    {
                        formTypes.length === 0 ? <Loader/> : null
                    }

                    <ul>
                        {
                            formTypes.filter(x => x.id !== 2).map((type, index) => {
                                return <li key={index}
                                           className={`inline-flex items-center rounded border-2  px-3 py-3 text-center text-xs font-medium ${form_exists(type.id) ? 'bg-green-400 text-black border-green-600' : 'bg-red-500 text-white border-red-600'}`}>
                                    <Link to={`/${type.url}/${patient?.id_number}`}
                                          className={`hover:text-white ${form_exists(type.id) ? 'text-gray-800' : 'bg-red-500 text-white'}`}>{type.name}</Link>
                                </li>
                            })
                        }
                    </ul>
                </div>
            </div>
        </div>


    </>
}
