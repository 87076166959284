import Loki from 'react-loki';
import React, { useState } from "react";
import { Container, Button, Card, CardBody, Col, Row } from "reactstrap";
import clsx from 'clsx';
import { StressesInfo } from "./stresses";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileMedicalAlt, faSignature } from "@fortawesome/free-solid-svg-icons";
import { ScreeningForm } from "./screeningInfo";
import screening_questions from './questions/screening.json'
import { PsychiatricHxInfo } from "../evaluation/psychiatricHxInfo";
import { Signature } from "../../SignaturePad/signature";
import { BaseModel } from "../../models/BaseModel";
import axios from "axios";
import { PATIENT_URLS } from "../../Api/Config";
import { ErrorMsg, SuccessMsg } from "../../Notification";
import * as Sentry from "@sentry/react";
import { useAuthentication } from "../../Hooks/AuthHook";
import Record from "../../Reports/Record"

const IntakeForm = ({ patient_id }) => {
    const [screeningList, setScreeningList] = useState(screening_questions)
    const [stressors, setStressors] = useState([])
    const [pysch, setPsych] = useState([])
    const [fillingUser, setUser] = useState({});

    let { user } = useAuthentication()

    const customSteps = [
        {
            label: 'Current Stressors',
            number: '1',
            description: 'Fill in current stresses',
            component: <StressesInfo stressors={stressors} setMethod={setStressors} />,
            icon: <FontAwesomeIcon icon={faFileMedicalAlt} size={'3x'} />
        },
        {
            label: 'Screening Questions',
            number: '2',
            description: 'Fill in screening questions',
            component: <ScreeningForm list={screeningList} setMethod={setScreeningList} />,
            icon: <FontAwesomeIcon icon={faFileMedicalAlt} size={'3x'} />
        },
        {
            label: 'Past History',
            number: '2',
            description: 'Fill in questions',
            component: <PsychiatricHxInfo psych={pysch} setMethod={setPsych} />,
            icon: <FontAwesomeIcon icon={faFileMedicalAlt} size={'3x'} />
        },
        {
            number: '2',
            label: 'Confirmation',
            description: 'Sign & Confirm Referral',
            component: <Signature setUser={setUser} />,
            icon: <FontAwesomeIcon icon={faSignature} size={'3x'} />
        }
    ];

    const _customRenderer = ({ currentStep }) => {
        const steps = customSteps.map((step, index) => {
            const isActive = currentStep === index + 1;
            return <Col lg="6" xl="3">
                <Card key={index} className={clsx("card-box card-stats mb-4 mb-xl-0", { 'current': isActive })}>
                    <div onClick={e => e.preventDefault()}>
                        <CardBody>
                            <Row>
                                <div className="col">
                                    <div className="step-indicator">
                                        <div className="d-flex">
                                            <div>
                                                <div className="font-weight-bold">{step.label}</div>
                                                <small className="text-black-50">{step.description}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Col className="col-auto text-primary" style={{ color: '#16a085' }}>
                                    {step.icon}
                                </Col>
                            </Row>
                        </CardBody>
                    </div>
                </Card>
            </Col>
        });

        return <div className="header pb-3 pt-5 pt-md-8">
            <Container fluid>
                <div className="header-body">
                    <Row className="justify-content-center align-self-center">
                        {steps}
                    </Row>
                </div>
            </Container>
        </div>
    };

    const _customActions = ({ isComplete, cantBack, isInFinalStep, backHandler, nextHandler }) => {
        return (
            <div className="actions p-4">
                <Button outline color="primary" onClick={backHandler} disabled={cantBack || isComplete}>
                    Previous
                </Button>
                <Button className={`float-right ${!isInFinalStep ? 'bg-blue' : 'bg-green'}`} color={!isInFinalStep ? 'primary' : 'success'} onClick={nextHandler}
                    disabled={isComplete}>
                    {isInFinalStep ? 'Finish' : 'Next'}
                </Button>
            </div >
        );
    };

    const _onFinish = () => {
        if (fillingUser !== null) {
            const saved_stressors = {
                "id": "1",
                "title": "Stressors",
                "questions": stressors.filter((item) => item.answer !== 'Not Completed')
            }
            const patient_record = {
                records: [].concat(saved_stressors).concat(screeningList.filter((item) => item.answer !== 'Not Completed')).concat(pysch.filter((item) => item.answer !== 'Not Completed')),
                ...new BaseModel(user.id_number, user.first_name).getBase(),
            }
            

            axios.post(PATIENT_URLS.PATIENT_RECORD(patient_id, 4), patient_record)
                .then(() => {
                    SuccessMsg('Record Saved.')
                    window.location.reload()
                })
                .catch(error => {
                    Sentry.captureException(error);
                    ErrorMsg('We could not save your record. Please consult admin.')
                })
        }
    };

    return (
        <>
            <div className="wizard-alternate-2 horizontal" style={{ marginTop: '-5em' }}>
                <Loki
                    steps={customSteps}
                    renderSteps={_customRenderer}
                    renderActions={_customActions}
                    onFinish={_onFinish} />
            </div>
            <Record patient={patient_id}  />
        </>
    );
};

export default IntakeForm;

