import React from "react"
import {HomeIcon} from "@heroicons/react/24/outline"
import {Link, NavLink} from "react-router-dom"

export const Breadcrumbs = ({items = []}) => {

    return <>
        <nav className="bg-white border-b border-gray-200 -mt-6 -ml-10 mb-5 -pb-5" aria-label="Breadcrumb">
            <ol role="list" className="max-w-screen-xl flex space-x-4 sm:px-6 lg:px-8">
                <li className="flex">
                    <div className="flex items-center">
                        <Link to="/" className="text-gray-400 hover:text-gray-500">
                            <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true"/>
                            <span className="sr-only">Home</span>
                        </Link>
                    </div>
                </li>
                <li className="flex">
                    <div className="flex items-center">
                        <svg
                            className="flex-shrink-0 w-6 text-gray-200"
                            viewBox="0 0 24 44"
                            preserveAspectRatio="none"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                        >
                            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z"/>
                        </svg>
                        <Link
                            to='/'
                            className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                        >
                            Dashboard
                        </Link>
                    </div>
                </li>
                {items.map((crumb) => (
                    <li key={crumb.detail} className="flex">
                        <div className="flex items-center">
                            <svg
                                className="flex-shrink-0 w-6 h-full text-gray-200"
                                viewBox="0 0 24 44"
                                preserveAspectRatio="none"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                aria-hidden="true"
                            >
                                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z"/>
                            </svg>
                            <NavLink
                                to={crumb.link}
                                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                                aria-current={crumb?.current ? 'page' : undefined}
                            >
                                {crumb.detail}
                            </NavLink>
                        </div>
                    </li>
                ))}
            </ol>
        </nav>
    </>
}