export const usePatient = () => {
    function getAgeFromIdNumber(idNumber) {
        if (idNumber?.length !== 13 || idNumber === undefined) {
            return 'Invalid ID number';
        }

        const year = parseInt(idNumber.substr(0, 2), 10);
        const currentYear = new Date().getFullYear();
        const prefix = (year <= currentYear - 2000) ? '20' : '19';
        const birthYear = parseInt(prefix + idNumber.substr(0, 2), 10);
        const birthMonth = parseInt(idNumber.substr(2, 2), 10);
        const birthDay = parseInt(idNumber.substr(4, 2), 10);

        const birthdate = new Date(birthYear, birthMonth - 1, birthDay);
        const ageDiff = Date.now() - birthdate.getTime();
        const ageDate = new Date(ageDiff);

        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    function getBirthdateFromIdNumber(idNumber) {
        if (idNumber?.length !== 13 || idNumber === undefined) {
            return 'Invalid ID number';
        }

        const year = parseInt(idNumber.substr(0, 2), 10);
        const currentYearTwoDigits = new Date().getFullYear() % 100;
        const prefix = (year <= currentYearTwoDigits) ? '20' : '19';
        const birthYear = parseInt(prefix + idNumber.substr(0, 2), 10);
        const birthMonth = parseInt(idNumber.substr(2, 2), 10) - 1; 
        const birthDay = parseInt(idNumber.substr(4, 2), 10);

    
    const birthdate = new Date(Date.UTC(birthYear, birthMonth, birthDay));

    
    if (birthdate.getUTCFullYear() === birthYear && birthdate.getUTCMonth() === birthMonth && birthdate.getUTCDate() === birthDay) {
        return birthdate.toISOString().split('T')[0];
    } else {
        return 'Invalid ID number';
    }
}


    function getSexFromIdNumber(idNumber) {
        if (idNumber?.length !== 13 || idNumber === undefined) {
            return 'Invalid ID number';
        }

        const seventhDigit = idNumber.charAt(6);
        const isMale = parseInt(seventhDigit, 10) >= 5;

        return isMale ? 'Male' : 'Female' ;
    }

    return {getSexFromIdNumber, getAgeFromIdNumber, getBirthdateFromIdNumber}
}
