import React, { useState } from "react";
import { Section } from "../Question/Section";
import { Switch } from "antd";


export const ProblemListForm = ({ list = [], setMethod }) => {
    const [suicidal, setSuicidal] = useState(false);
    return (
        <div className="col-12">
            <div className="card">
                <div className="card-body wizard-content">
                    <div className="tab-wizard wizard-circle">
                        <section>
                            <div className="row">
                                {
                                    list.map(section => {
                                        return (
                                            <Section section={section} list={list} setMethod={setMethod} />
                                        )
                                    })
                                }
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="firstName1 text-upper text-up mb--3">Suicidal Behaviour
                                                    Present?</label>
                                            </div>
                                            <div className="col-md-6">
                                                <Switch checkedChildren="Yes"
                                                    unCheckedChildren="No"
                                                    defaultChecked={false}
                                                    onChange={e => {
                                                        setSuicidal(e)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {
                                suicidal ? <>
                                    <div className="row col-12 text-uppercase">
                                        <h4 className='mb--4'><strong>Suicidal Behaviour</strong></h4>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label htmlFor="firstName1 text-upper text-up mb--3">Suicidal Thoughts:</label>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <Switch checkedChildren="Yes"
                                                            unCheckedChildren="No"
                                                            defaultChecked={false}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label htmlFor="firstName1 text-upper text-up mb--3">Suicide Attempt(s):</label>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <Switch checkedChildren="Yes"
                                                            unCheckedChildren="No"
                                                            defaultChecked={false}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label htmlFor="firstName1 text-upper text-up mb--3">Deliberate Self-Harm:</label>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <Switch checkedChildren="Yes"
                                                            unCheckedChildren="No"
                                                            defaultChecked={false}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </> : null
                            }
                            <div className='row'>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="lastName1">Notes: </label>
                                        <textarea className="form-control" rows="3" placeholder="Text Here..." />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}