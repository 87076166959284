import React, { useState } from "react";
import { Card, CardBody, Row } from "reactstrap";
import { Select, Switch } from "antd";

const { Option } = Select;
export const ProfessionalForm = ({ occupation, setMethod }) => {
    const [educationLevel, setEducationLevel] = useState('')
    const [primary, setPrimary] = useState(false)
    const [highSchool, setHighSchool] = useState(false)
    const [social, setSocial] = useState(false)
    const [academic, setAcademic] = useState(false)
    const [work, setWork] = useState(false)
    const [exposure, setExposure] = useState(false)
    const [socialMedia, setSocialMedia] = useState(false)
    const [tvGames, setTvGames] = useState(false)
    const [tv, setTv] = useState(false)

    function set_answer(id, question, answer) {
        let exists = occupation.find(function (sec) {
            return sec.id === id
        })

        if (exists) {
            let temp = occupation
            temp[occupation.indexOf(exists)] = {
                id: id,
                type: 'text',
                question: question,
                answer: answer
            }
            setMethod(temp)
        } else {
            setMethod([
                ...occupation,
                {
                    id: id,
                    type: 'text',
                    question: question,
                    answer: answer
                }
            ])
        }
    }

    return (
        <div className="col-12">
            <div className="card">
                <div className="card-body wizard-content">
                    <div className="tab-wizard wizard-circle">

                        <div className='row'>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label htmlFor="firstName1">
                                                <strong>Activities of Daily Living:</strong>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3">
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label htmlFor="firstName1">Exposure to Screen:</label>
                                        </div>
                                        <div className="col-md-6">
                                            <Switch checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked={exposure}
                                                onChange={() => setExposure(!exposure)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                exposure ? <>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor="firstName1">Social:</label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Switch checkedChildren="Yes"
                                                        unCheckedChildren="No"
                                                        defaultChecked={social}
                                                        onChange={() => setSocial(!social)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor="firstName1">Academic:</label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Switch checkedChildren="Yes"
                                                        unCheckedChildren="No"
                                                        defaultChecked={academic}
                                                        onChange={() => setAcademic(!academic)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor="firstName1">Work:</label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Switch checkedChildren="Yes"
                                                        unCheckedChildren="No"
                                                        defaultChecked={work}
                                                        onChange={() => setWork(!work)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </> : null
                            }
                            {
                                exposure ?
                                    <>
                                        {
                                            social ?
                                                <Card
                                                    className="card-box card-stats mb-4 mb-xl-1 border border-primary col-md-4">
                                                    <CardBody>
                                                        <Row>
                                                            <div className="col mb-3">
                                                                <div className="step-indicator">
                                                                    <div className="d-flex">
                                                                        <div>
                                                                            <div className="font-weight-bold">
                                                                                <strong>
                                                                                    Social Exposure
                                                                                </strong>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Row>
                                                        <Row>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <label htmlFor="firstName1">Social
                                                                                Media:</label>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <Switch checkedChildren="Yes"
                                                                                unCheckedChildren="No"
                                                                                defaultChecked={socialMedia}
                                                                                onChange={() => setSocialMedia(!socialMedia)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <label htmlFor="firstName1">TV
                                                                                Games:</label>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <Switch checkedChildren="Yes"
                                                                                unCheckedChildren="No"
                                                                                defaultChecked={tvGames}
                                                                                onChange={() => setTvGames(!tvGames)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <label
                                                                                htmlFor="firstName1">Television:</label>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <Switch checkedChildren="Yes"
                                                                                unCheckedChildren="No"
                                                                                defaultChecked={tv}
                                                                                onChange={() => setTv(!tv)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Row>
                                                        {
                                                            socialMedia ?
                                                                <Row>
                                                                    <div className="col-md-12">
                                                                        <div className="form-group">
                                                                            <div className="row">
                                                                                <div className="col-md-6">
                                                                                    <label htmlFor="firstName1">Social
                                                                                        Media
                                                                                        Exposure
                                                                                        Hours per Day:</label>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <Select
                                                                                        style={{ width: '100%' }}
                                                                                        placeholder="Choose..."
                                                                                        onChange={value => {
                                                                                            set_answer(1, 'Social media exposure hours per day', value)
                                                                                        }}
                                                                                    >
                                                                                        <Option
                                                                                            value='less than one hour'>Less
                                                                                            than
                                                                                            one
                                                                                            hour</Option>
                                                                                        <Option
                                                                                            value='one to three hours'>One
                                                                                            to
                                                                                            three
                                                                                            hours</Option>
                                                                                        <Option
                                                                                            value='three to five hours'>Three
                                                                                            to
                                                                                            five
                                                                                            hours</Option>
                                                                                        <Option
                                                                                            value='more than five hours'>More
                                                                                            than five
                                                                                            hours</Option>
                                                                                    </Select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Row> : null
                                                        }
                                                        {
                                                            tvGames ?
                                                                <Row>
                                                                    <div className="col-md-12">
                                                                        <div className="form-group">
                                                                            <div className="row">
                                                                                <div className="col-md-6">
                                                                                    <label htmlFor="firstName1">TV Games
                                                                                        Exposure Hours per Day:</label>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <Select
                                                                                        style={{ width: '100%' }}
                                                                                        placeholder="Choose..."
                                                                                        onChange={value => {
                                                                                            set_answer(2, 'TV games exposure hours per day', value)
                                                                                        }}
                                                                                    >
                                                                                        <Option
                                                                                            value='less than one hour'>Less
                                                                                            than
                                                                                            one
                                                                                            hour</Option>
                                                                                        <Option
                                                                                            value='one to three hours'>One
                                                                                            to
                                                                                            three
                                                                                            hours</Option>
                                                                                        <Option
                                                                                            value='three to five hours'>Three
                                                                                            to
                                                                                            five
                                                                                            hours</Option>
                                                                                        <Option
                                                                                            value='more than five hours'>More
                                                                                            than five
                                                                                            hours</Option>
                                                                                    </Select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Row> : null
                                                        }
                                                        {
                                                            tv ?
                                                                <Row>
                                                                    <div className="col-md-12">
                                                                        <div className="form-group">
                                                                            <div className="row">
                                                                                <div className="col-md-6">
                                                                                    <label htmlFor="firstName1">Television
                                                                                        Exposure Hours per Day:</label>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <Select
                                                                                        style={{ width: '100%' }}
                                                                                        placeholder="Choose..."
                                                                                        onChange={value => {
                                                                                            set_answer(3, 'Television exposure hours per day', value)
                                                                                        }}
                                                                                    >
                                                                                        <Option
                                                                                            value='less than one hour'>Less
                                                                                            than
                                                                                            one
                                                                                            hour</Option>
                                                                                        <Option
                                                                                            value='one to three hours'>One
                                                                                            to
                                                                                            three
                                                                                            hours</Option>
                                                                                        <Option
                                                                                            value='three to five hours'>Three
                                                                                            to
                                                                                            five
                                                                                            hours</Option>
                                                                                        <Option
                                                                                            value='more than five hours'>More
                                                                                            than five
                                                                                            hours</Option>
                                                                                    </Select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Row> : null
                                                        }

                                                    </CardBody>
                                                </Card>
                                                : null
                                        }
                                    </> : null
                            }
                            {
                                academic ?
                                    <Card
                                        className="card-box card-stats mb-4 mb-xl-1 border border-primary col-md-4">
                                        <CardBody>
                                            <Row>
                                                <div className="col mb-3">
                                                    <div className="step-indicator">
                                                        <div className="d-flex">
                                                            <div>
                                                                <div className="font-weight-bold">
                                                                    <strong>
                                                                        Academic Exposure
                                                                    </strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Row>
                                            <Row>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <label htmlFor="firstName1">Academic Exposure
                                                                    hours per Day:</label>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <Select
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Choose..."
                                                                    onChange={value => {
                                                                        set_answer(4, 'Academic exposure hours per day', value)
                                                                    }}
                                                                >
                                                                    <Option value='less than one hour'>Less than
                                                                        one
                                                                        hour</Option>
                                                                    <Option value='one to three hours'>One to
                                                                        three
                                                                        hours</Option>
                                                                    <Option value='three to five hours'>Three to
                                                                        five
                                                                        hours</Option>
                                                                    <Option value='more than five hours'>More
                                                                        than five
                                                                        hours</Option>
                                                                </Select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                    : null
                            }
                            {
                                work ?
                                    <Card className="card-box card-stats mb-4 mb-xl-1 border border-primary col-md-4">
                                        <CardBody>
                                            <Row>
                                                <div className="col mb-3">
                                                    <div className="step-indicator">
                                                        <div className="d-flex">
                                                            <div>
                                                                <div className="font-weight-bold">
                                                                    <strong>
                                                                        Work Exposure
                                                                    </strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Row>
                                            <Row>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <label htmlFor="firstName1">Work Exposure hours per
                                                                    Day:</label>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <Select
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Choose..."
                                                                    onChange={value => {
                                                                        set_answer(5, 'Work exposure hours per day', value)
                                                                    }}
                                                                >
                                                                    <Option value='less than one hour'>Less than one
                                                                        hour</Option>
                                                                    <Option value='one to three hours'>One to three
                                                                        hours</Option>
                                                                    <Option value='three to five hours'>Three to five
                                                                        hours</Option>
                                                                    <Option value='more than five hours'>More than five
                                                                        hours</Option>
                                                                </Select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                    : null
                            }
                        </div>
                        <Row className="pb-5">
                            <hr />
                        </Row>

                        <section>
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="firstName1">Level of Education: </label>
                                            </div>
                                            <div className="col-md-6">
                                                <Select className="custom-select mr-sm-2 form-horizontal"
                                                    id="inlineFormCustomSelect" value={educationLevel}
                                                    onChange={value => {
                                                        setEducationLevel(value)
                                                        parseInt(value) >= 3 ? setPrimary(true)
                                                            : setPrimary(false)
                                                        parseInt(value) >= 4 ? setHighSchool(true)
                                                            : setHighSchool(false)
                                                        set_answer(6, 'Level of education', value) //TODO :Change to reflect correct value
                                                    }}

                                                >
                                                    <option defaultValue>Choose...</option>
                                                    <option value="Unknown">Unknown</option>
                                                    <option value="No Formal Schooling">No Formal Schooling</option>
                                                    <option value="Primary School">Primary School</option>
                                                    <option value="High School">High School</option>
                                                    <option value="Tertiary">Tertiary</option>
                                                    <option value="Post Grad">Post Grad</option>
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="firstName1">Work Experience? </label>
                                            </div>
                                            <div className="col-md-6">
                                                <Switch checkedChildren="Yes"
                                                    unCheckedChildren="No"
                                                    defaultChecked={false}
                                                    onChange={checked => set_answer(7, 'Work experience', checked ? 'Yes' : 'No')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                {
                                    primary ? (
                                        <Card
                                            className="card-box card-stats mb-4 mb-xl-1 border border-primary col-md-6">
                                            <CardBody>
                                                <Row>
                                                    <div className="col mb-3">
                                                        <div className="step-indicator">
                                                            <div className="d-flex">
                                                                <div>
                                                                    <div className="font-weight-bold">
                                                                        <strong>
                                                                            Primary School Experience
                                                                        </strong>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Row>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <label htmlFor="firstName1">Emotional Neglect of
                                                                        Child: </label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <Switch checkedChildren="Yes"
                                                                        unCheckedChildren="No"
                                                                        defaultChecked={false}
                                                                        onChange={checked =>
                                                                            set_answer(8, 'Emotional neglect of child', checked ? 'Yes' : 'No')
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <label htmlFor="firstName1">Extreme
                                                                        Poverty: </label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <Switch checkedChildren="Yes"
                                                                        unCheckedChildren="No"
                                                                        defaultChecked={false}
                                                                        onChange={checked =>
                                                                            set_answer(9, 'Extreme poverty', checked ? 'Yes' : 'No')
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <label htmlFor="firstName1">Inadequate Family
                                                                        Support: </label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <Switch checkedChildren="Yes"
                                                                        unCheckedChildren="No"
                                                                        defaultChecked={false}
                                                                        onChange={checked =>
                                                                            set_answer(10, 'Inadequate family support', checked ? 'Yes' : 'No')
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <label htmlFor="firstName1">Absence of Family
                                                                        Member: </label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <Switch checkedChildren="Yes"
                                                                        unCheckedChildren="No"
                                                                        defaultChecked={false}
                                                                        onChange={checked =>
                                                                            set_answer(11, 'Absence of family member', checked ? 'Yes' : 'No')
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <label htmlFor="firstName1">Educational
                                                                        Maladjustment & Discord with Teachers &
                                                                        Classmates: </label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <Switch checkedChildren="Yes"
                                                                        unCheckedChildren="No"
                                                                        defaultChecked={false}
                                                                        onChange={checked =>
                                                                            set_answer(12, 'Educational maladjustment & discord with teachers & classmates', checked ? 'Yes' : 'No')
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <label htmlFor="firstName1">Problems Related to
                                                                        Alleged Physical Abuse of Child: </label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <Switch checkedChildren="Yes"
                                                                        unCheckedChildren="No"
                                                                        defaultChecked={false}
                                                                        onChange={checked =>
                                                                            set_answer(13, 'Problems related to alleged physical abuse of child', checked ? 'Yes' : 'No')
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <label htmlFor="firstName1">Inappropriate Parental
                                                                        Pressure & Other Abnormal Qualities of
                                                                        Upbringing: </label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <Switch checkedChildren="Yes"
                                                                        unCheckedChildren="No"
                                                                        defaultChecked={false}
                                                                        onChange={checked =>
                                                                            set_answer(14, 'Inappropriate parental pressure & other abnormal qualities of upbringing', checked ? 'Yes' : 'No')
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <label htmlFor="firstName1">Disruption of Family by
                                                                        Separation & Divorce Estrangement: </label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <Switch checkedChildren="Yes"
                                                                        unCheckedChildren="No"
                                                                        defaultChecked={false}
                                                                        onChange={checked =>
                                                                            set_answer(15, 'Disruption of family by separation & divorce estrangement', checked ? 'Yes' : 'No')
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <label htmlFor="firstName1">Altered Pattern of
                                                                        Family Relationships in Childhood: </label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <Switch checkedChildren="Yes"
                                                                        unCheckedChildren="No"
                                                                        defaultChecked={false}
                                                                        onChange={checked =>
                                                                            set_answer(16, 'Altered pattern of family relationships in childhood', checked ? 'Yes' : 'No')
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    ) : null
                                }
                                {
                                    highSchool ? (
                                        <Card
                                            className="card-box card-stats mb-4 mb-xl-1 border border-primary col-md-6">
                                            <CardBody>
                                                <Row>
                                                    <div className="col mb-3">
                                                        <div className="step-indicator">
                                                            <div className="d-flex">
                                                                <div>
                                                                    <div className="font-weight-bold">
                                                                        <strong>
                                                                            High School Experience
                                                                        </strong>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Row>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <label htmlFor="firstName1">Problems Related to
                                                                        Alleged Sexual Abuse of Child: </label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <Switch checkedChildren="Yes"
                                                                        unCheckedChildren="No"
                                                                        defaultChecked={false}
                                                                        onChange={checked =>
                                                                            set_answer(17, 'Problems related to alleged sexual abuse of child', checked ? 'Yes' : 'No')
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <label htmlFor="firstName1">Hostility Towards and
                                                                        Scapegoating of Child: </label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <Switch checkedChildren="Yes"
                                                                        unCheckedChildren="No"
                                                                        defaultChecked={false}
                                                                        onChange={checked =>
                                                                            set_answer(18, 'Hostility towards & scapegoating of child', checked ? 'Yes' : 'No')
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <label htmlFor="firstName1">Inadequate Parental
                                                                        Supervision and Control: </label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <Switch checkedChildren="Yes"
                                                                        unCheckedChildren="No"
                                                                        defaultChecked={false}
                                                                        onChange={checked =>
                                                                            set_answer(19, 'Inadequate parental supervision & control', checked ? 'Yes' : 'No')
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <label htmlFor="firstName1">Institutional
                                                                        Upbringing: </label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <Switch checkedChildren="Yes"
                                                                        unCheckedChildren="No"
                                                                        defaultChecked={false}
                                                                        onChange={checked =>
                                                                            set_answer(20, 'Institutional upbringing', checked ? 'Yes' : 'No')
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <label htmlFor="firstName1">Removal from Home in
                                                                        Childhood: </label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <Switch checkedChildren="Yes"
                                                                        unCheckedChildren="No"
                                                                        defaultChecked={false}
                                                                        onChange={checked =>
                                                                            set_answer(21, 'Removal from home in childhood', checked ? 'Yes' : 'No')
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <label htmlFor="firstName1">Loss of Love
                                                                        Relationship in Childhood: </label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <Switch checkedChildren="Yes"
                                                                        unCheckedChildren="No"
                                                                        defaultChecked={false}
                                                                        onChange={checked =>
                                                                            set_answer(22, 'Loss of love relationship in childhood', checked ? 'Yes' : 'No')
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    ) : null
                                }
                            </div>

                            <div className='row'>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="lastName1">Notes: </label>
                                        <textarea className="form-control" rows="3" placeholder="Text Here..." />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}