import React, {useState} from "react";
import weekly_checkin from './questions/weekly_checkin.json'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignature, faUserNurse} from "@fortawesome/free-solid-svg-icons";
import {Signature} from "components/SignaturePad/signature";
import {Button, Card, CardBody, Col, Container, Row} from "reactstrap";
import clsx from "clsx";
import {BaseModel} from "components/models/BaseModel";
import axios from "axios";
import {PATIENT_URLS} from "components/Api/Config";
import {ErrorMsg, SuccessMsg} from "components/Notification";
import * as Sentry from "@sentry/react";
import Loki from "react-loki";
import {WeeklyCheckinInfoForm} from "components/Forms/nurse-checkin/questions/weekly-checkin-info";
import {useAuthentication} from "components/Hooks/AuthHook";

export default function NurseWeeklyCheckinForm({patient_id}) {
    const [checklist, setChecklist] = useState(weekly_checkin);
    const [fillingUser, setUser] = useState({});
    let {user} = useAuthentication()

    const formSteps = [
        {
            number: '1',
            label: 'Weekly Check-in',
            description: 'Weekly Check-in',
            component: <WeeklyCheckinInfoForm list={checklist} setMethod={setChecklist}/>,
            icon: <FontAwesomeIcon icon={faUserNurse} size={'3x'}/>
        },
        {
            number: '2',
            label: 'Confirmation',
            description: 'Sign & Confirm Check-in',
            component: <Signature setUser={setUser}/>,
            icon: <FontAwesomeIcon icon={faSignature} size={'3x'}/>
        }
    ];

    const _customRenderer = ({currentStep}) => {
        const steps = formSteps.map((step, index) => {
            const isActive = currentStep === index + 1;
            return <Col lg="6" xl="3">
                <Card key={index}
                      className={clsx("card-box card-stats mb-4 mb-xl-0", {'border border-primary': isActive})}>
                    <div onClick={e => e.preventDefault()}>
                        <CardBody>
                            <Row>
                                <div className="col">
                                    <div className="step-indicator">
                                        <div className="d-flex">
                                            <div>
                                                <div className="font-weight-bold">{step.label}</div>
                                                <small className="text-black-50">{step.description}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Col className="col-auto">
                                    {step.icon}
                                </Col>
                            </Row>
                        </CardBody>
                    </div>
                </Card>
            </Col>
        });

        return <div className="header pb-3 pt-5 pt-md-8">
            <Container fluid>
                <div className="header-body">
                    <Row className="justify-content-center align-self-center">
                        {steps}
                    </Row>
                </div>
            </Container>
        </div>
    };

    const _customActions = ({isComplete, cantBack, isInFinalStep, backHandler, nextHandler}) => {
        return (
            <div className="actions p-4">
                <Button outline color="primary" onClick={backHandler} disabled={cantBack || isComplete}>
                    Previous
                </Button>
                <Button className="float-right"
                        color={!isInFinalStep ? 'primary' : 'success'} onClick={nextHandler}
                        disabled={isComplete}>
                    {isInFinalStep ? 'Finish' : 'Next'}
                </Button>
            </div>
        );
    };

    const _onFinish = () => {
        if (checklist !== null) {
            const patient_record = {
                ...{"records": checklist},
                ...new BaseModel(user.id_number, user.first_name).getBase(),
                completed_by: {...fillingUser}
            }

            axios.post(PATIENT_URLS.PATIENT_RECORD(patient_id, 12), patient_record)
                .then(() => {
                    SuccessMsg('Record Saved.')
                    window.location.reload()
                })
                .catch(error => {
                    Sentry.captureException(error);
                    ErrorMsg('We could not save your record. Please consult admin.')
                })

        } else {
            ErrorMsg('Please complete forms')
        }
    }

    return <>
        <div className="wizard-alternate-2 horizontal" style={{marginTop: '-5em'}}>
            <Loki steps={formSteps}
                  renderSteps={_customRenderer}
                  renderActions={_customActions}
                  onFinish={_onFinish}/>
        </div>
    </>
}