import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import AddPatientNote from "components/Forms/Note/AddPatientNote";
import PreviousNotes from "components/Forms/Note/PreviousNotes";
import { usePatient } from '../../../components/Hooks/PatientHook';

const ReferralRecord = ({ patient_record, show_no_answers = false }) => {
    
    const record = patient_record[0]
    let { getBirthdateFromIdNumber} = usePatient()

    const display_answer = (answer) => {
        if (answer === 'Yes') {
            return <FontAwesomeIcon icon={faCheckCircle} />
        }
        if (answer === 'No') {
            return <FontAwesomeIcon icon={faTimesCircle} />
        }
        if (answer === '')
            return 'N/A'
        return answer
    }

    function can_display(answer) {
        return !(answer === 'No' && !show_no_answers);
    }

    function has_positives(questions) {
        if(questions!=undefined){
            let answered_yes = questions.find(function (sec) {
                return sec.answer === 'Yes'
            })
            return !!answered_yes
        }
       return
        
    }

    return (
        <>
            <div className="row">
                <div className="col-lg-6">
                    <div className="card bg-success shadow">
                        <div className="card-header bg-transparent">
                            <h3 className="mb-0 text-center">Patient Record</h3>
                        </div>
                        <div className="card-body ">
                            <div className='grid gap-4 grid-cols-4'>
                                        <div className="mb-4 col-span-3">
                                            <h3 className="text-xs"><strong>Description</strong></h3>
                                        </div>
                                        <div className="mb-2">
                                            <h3 className="font-bold text-xs">
                                            <strong>Detail</strong>
                                            </h3>
                                        </div>
                                        <div className="mb-2 col-span-3">
                                            <h5 className="text-xs">ID Number</h5>
                                        </div>
                                        <div className="mb-2">
                                            <h5 className="font-bold text-xs">
                                            {record.record_patient.id_number}
                                            </h5>
                                        </div>
                                        <div className="mb-2 col-span-3">
                                            <h5 className="text-xs">Name</h5>
                                        </div>
                                        <div className="mb-2">
                                            <h5 className="font-bold text-xs">
                                            {record.record_patient.first_name}
                                            </h5>
                                        </div>
                                        <div className="mb-2 col-span-3">
                                            <h5 className="text-xs">Surname</h5>
                                        </div>
                                        <div className="mb-2">
                                            <h5 className="font-bold text-xs">
                                            {record.record_patient.last_name}
                                            </h5>
                                        </div>
                                        <div  className="mb-2 col-span-3">
                                            <h5 className="text-xs">Phone</h5>
                                        </div>
                                        <div className="mb-2">
                                            <h5 className="font-bold text-xs">
                                            {record.record_patient.cellphone}
                                            </h5>
                                        </div>
                                        <div className="mb-2 col-span-3">
                                            <h5 className="text-xs">Date of Birth</h5>
                                        </div>
                                        <div className="mb-2">
                                            <h5 className="font-bold text-xs">
                                            {getBirthdateFromIdNumber(record.record_patient.id_number)}
                                            </h5>
                                        </div>
                                        <div className="mb-2 col-span-3">
                                            <h5 className="text-xs">Email</h5>
                                        </div>
                                        <div className="mb-2">
                                            <h5 className="font-bold text-xs">
                                            {record.record_patient.email}
                                            </h5>
                                        </div>
                                        <div className="mb-2 col-span-3">
                                            <h5 className="text-xs">Vocation</h5>
                                        </div>
                                        <div className="mb-2">
                                            <h5 className="font-bold text-xs">
                                            {record?.answers?.details?.vocation}
                                            </h5>
                                        </div>
                                        <div className="mb-2 col-span-3">
                                            <h5 className="text-xs">Living Circumstances</h5>
                                        </div>
                                        <div className="mb-2">
                                            <h5 className="font-bold text-xs">
                                            {record?.answers?.details?.living_circumstances}
                                            </h5>
                                        </div>
                                        <div  className="mb-2 col-span-3">
                                            <h5 className="text-xs">Site of referral</h5>
                                        </div>
                                        <div className="mb-2">
                                            <h5 className="font-bold text-xs">
                                            {record?.answers?.details?.site_of_referral}
                                            </h5>
                                        </div>
                                        <div className="mb-2 col-span-3">
                                            <h5 className="text-xs">Referring Clinician</h5>
                                        </div>
                                        <div className="mb-2">
                                            <h5 className="font-bold text-xs">
                                            {record?.answers?.details?.clinician}
                                            </h5>
                                        </div>
                             </div>
                             <hr className='col-10' />
                            <h3 className="mb-1 mt-4  text-center">Medical Aid information</h3>
                            {record?.answers?.medical_aid[0].questions?.map((question, index) => {
                                return <div key={index} className="row">
                                    {can_display(question.answer) ? (<>
                                        <div className="col-md-6">
                                            <h5 className="">{question.question}</h5>
                                        </div>
                                        <div className="col-md-6">
                                            <h5 className="">{display_answer(question.answer)}</h5>
                                        </div>
                                    </>
                                    ) : null}
                                </div>
                            })
                            }
                            {
                                !has_positives(record?.answers?.medical_aid[0].questions) ?
                                    <div className='col-12'><p className='text-center'>No medical aid information is available</p>
                                    </div> : null
                            }

                             
                            <hr className='col-10' />
                            <h3 className="mb-1 mt--4  text-center">Problem List</h3>
                            {record?.answers?.problem_list[0].questions?.map((question, index) => {
                                return <div key={index} className="row">
                                    {can_display(question.answer) ? (<>
                                        <div className="col-md-6">
                                            <h5 className="">{question.question}</h5>
                                        </div>
                                        <div className="col-md-6">
                                            <h5 className="">{display_answer(question.answer)}</h5>
                                        </div>
                                    </>
                                    ) : null}
                                </div>
                            })
                            }
                            
                            {
                                !has_positives(record?.answers?.problem_list[0].questions) ?
                                    <div className='col-12'><p className='text-center'>No positive problems</p>
                                    </div> : null
                            }
                            <hr className='col-10' />
                            <h3 className="mb-1 mt-4  text-center">Medical Conditions</h3>
                            {record?.answers?.medical_conditions[0].questions?.map((question, index) => {
                                return <div key={index} className="row">
                                    {can_display(question.answer) ? (<>
                                        <div className="col-md-6">
                                            <h5 className="">{question.question}</h5>
                                        </div>
                                        <div className="col-md-6">
                                            <h5 className="">{display_answer(question.answer)}</h5>
                                        </div>
                                    </>
                                    ) : null}
                                </div>
                            })
                            }
                            {
                                !has_positives(record?.answers?.medical_conditions[0].questions) ?
                                    <div className='col-12'><p className='text-center'>No positive conditions</p>
                                    </div> : null
                            }
                            

                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <AddPatientNote patient_id={record.id_number} />
                    <br />
                    <PreviousNotes patient_id={record.id_number} />
                </div>

            </div>
        </>

    )
}
export default ReferralRecord;
