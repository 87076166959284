import React, {useState, useEffect} from "react";
import {EvaluationForm} from "components/Forms/evaluation";
import {useParams} from "react-router-dom";
import * as Sentry from "@sentry/react";
import Record from "../../components/Reports/Record";
import axios from "axios";
import {useClavkooForms} from "components/Hooks/FormHook";
import {CMS_URLS, PATIENT_URLS} from "components/Api/Config";
import {Breadcrumbs} from "components/Breadcrumbs";
import {FORMS} from "constants/forms";

const PsychEvaluation = () => {
    let {id} = useParams();
    const [breadcrumbs, setBreadcrumbs] = useState([])
    const [isNew, setNew] = useState(false)
    const [exists, setExists] = useState(false)
    let {recordExists} = useClavkooForms()
    const [loading, setLoading] = useState(true)
    const [record, setRecord] = useState()

    function LoadBreadcrumbs(){
        axios.get(CMS_URLS.USERS.DETAILS(id))
            .then(res => {
                setBreadcrumbs([{'detail': 'Patients', 'link': '/patients'},
                    {'detail': `${res.data?.first_name} ${res.data?.last_name}`, 'link': `/profile/${id}`},
                    {'detail': 'Psychiatric Evaluation', 'link': `/evaluation/${id}`}])
            })
            .catch(err => Sentry.captureException(err))
    }

    useEffect(() => {
        LoadBreadcrumbs()
        axios.get(PATIENT_URLS.PATIENT_RECORD(id, FORMS.PSYCHIATRIC_EVALUATION))
            .then(res => {
                setExists(recordExists(res.data))
                setRecord(res.data)
            }).then(() => {
            if (window.location.href.indexOf("new") > -1) {
                setNew(true)
            }
                setNew(false)
            }).catch(err => Sentry.captureException(err))
            .finally(() => setLoading(false))
    }, [id])


    useEffect(() => {
        if (window.location.href.indexOf("new") > -1) {
            setNew(true)
        }
        setNew(false)
    }, [id])

    return (
        <>
            <Breadcrumbs items={breadcrumbs}/>
            {
                isNew ? <EvaluationForm patient_id={id}/> :
                exists ? <Record patient_record={record}/> : <EvaluationForm patient_id={id}/>
            }
        </>
    )
}
export default PsychEvaluation;
