import React, {useEffect, useState} from "react";
import './styles/signaturePadStyle.css';
import {Switch, Input} from "antd";

const PersonFillingIn = ({client, setClient}) => {
    return (
        <>
            <div className='row'>
                <div className="col-md-12">
                    <div className="form-group">
                        <div className="row">
                            <div className="col-md-5">
                                <label htmlFor="firstName1">
                                    <strong>By accepting this, you confirm that the provided
                                        information is correct and accurate</strong> </label>
                            </div>
                            <div className="col-md-7">
                                <Switch checkedChildren="Yes"
                                        unCheckedChildren="No"
                                        onChange={value => {
                                            setClient(prevState => ({
                                                ...prevState,
                                                ['confirmed']: value ? 'Yes' : 'No'
                                            }))
                                        }}/>
                            </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-md-5">
                                <label htmlFor="firstName1">
                                    <strong>Are you over 18 years old?</strong>
                                </label>
                            </div>
                            <div className="col-md-7">
                                <Switch checkedChildren="Yes"
                                        unCheckedChildren="No"
                                        onChange={value => {
                                            setClient(prevState => ({
                                                ...prevState,
                                                ['adult']: value ? 'Yes' : 'No'
                                            }))
                                        }}/>
                            </div>
                        </div>
                        <div className="row">
                            {
                                client?.adult !== 'Yes' ? <>
                                    <br/>
                                    <div className="col-12">
                                        <label htmlFor="firstName1">
                                            <strong>Name of adult filling in?</strong>
                                        </label>
                                        <br/>
                                    </div>
                                    <div className="col-6">
                                        <Input className="col-12"
                                               placeholder="Name of Adult"
                                               onChange={value => {
                                                   setClient(prevState => ({
                                                       ...prevState,
                                                       ['adult_name']: value
                                                   }))
                                               }}
                                        />
                                    </div>
                                </> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export const Signature = ({setUser}) => {
    const [client, setClient] = useState({})

    useEffect(() => {
        setUser(client)
    }, [client])

    return (
        <div className="col-12">
            <div className="card">
                <div className="card-body wizard-content">
                    <div className="tab-wizard wizard-circle">
                        <section>
                            <div className="col-md-12">
                                <PersonFillingIn client={client} setClient={setClient}/>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}