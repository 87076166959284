import React, {useEffect, useState} from "react";
import {AssessmentGraph} from "components/Patient/assessmentGraph";
import {useClavkooForms} from "../Hooks/FormHook";
import AddPatientNote from "../Forms/Note/AddPatientNote";

export const PatientSummary = ({records, patient_id}) => {
    const [snap, setSnap] = useState({labels: [], datasets: []})
    const [phq, setPhq] = useState({labels: [], datasets: []})
    const [weiss, setWeiss] = useState({labels: [], datasets: []})
    const [dsm, setDsm] = useState({labels: [], datasets: []})
    let {find_record_by_type} = useClavkooForms()


    function calculate_snap() {
        let snap_record = find_record_by_type(records, 5)

        if (snap_record === undefined)
            return {labels: [], datasets: []}

        let attention = []
        let hyper = []
        let disruptive = []

        snap_record.answers[0].questions.map((question, index) => {
            if (index < 9) {
                let ans = question.options.find(obj => {
                    return obj.text === question.answer
                })
                attention.push(ans?.id)
            }
            if (index >= 9 && index < 19) {
                let ans = question.options.find(obj => {
                    return obj.text === question.answer
                })
                hyper.push(ans?.id)
            }
            if (index >= 19) {
                let ans = question.options.find(obj => {
                    return obj.text === question.answer
                })
                disruptive.push(ans?.id)
            }
        })
        let snap_details = {
            labels: [
                'Attention Deficit',
                'Hyperactivity & Impulsivity',
                'Disruptive Behaviour'
            ],
            datasets: [
                {
                    label: "Initial Visit",
                    backgroundColor: "rgba(200,0,0,0.2)",
                    data: [attention.reduce((a, b) => a + b) / attention.length,
                        hyper.reduce((a, b) => a + b) / hyper.length,
                        disruptive.reduce((a, b) => a + b) / disruptive.length],
                    borderColor: 'rgba(255, 99, 132, 1)',
                    borderWidth: 1,
                }
            ]
        }
        setSnap(snap_details)
    }

    function calculate_phq() {
        let phq_record = find_record_by_type(records, 6)

        if (phq_record === undefined)
            return {labels: [], datasets: []}

        let mood = []
        let anxiety = []

        phq_record.answers[0].questions.map(question => {
            let ans = question.options.find(obj => {
                return obj.text === question.answer
            })
            mood.push(ans?.id)
        })

        phq_record.answers[1].questions.map(question => {
            let ans = question.options.find(obj => {
                return obj.text === question.answer
            })
            anxiety.push(ans.id)
        })


        let phq_details = {
            labels: [
                'Mood',
                'Anxiety'
            ],
            datasets: [
                {
                    label: ["Mood", "Anxiety"],
                    backgroundColor: ['#95afc0', '#535c68'],
                    data: [mood.reduce((a, b) => a + b) / mood.length, anxiety.reduce((a, b) => a + b) / anxiety.length],
                    borderColor: ['#95afc0', '#535c68'],
                    borderWidth: 1,
                }
            ]
        }
        setPhq(phq_details)
    }

    function calculate_weiss() {
        let weiss_record = find_record_by_type(records, 10)

        if (weiss_record === undefined)
            return {labels: [], datasets: []}

        let family = []
        let learning = []
        let school = []
        let life = []
        let social = []
        let risky = []

        weiss_record.answers[0].questions.map(question => {
            let ans = question.options.find(obj => {
                return obj.text === question.answer
            })
            family.push(ans?.id)
        })
        weiss_record.answers[0].questions.map(question => {
            let ans = question.options.find(obj => {
                return obj.text === question.answer
            })
            learning.push(ans?.id)
        })
        weiss_record.answers[2].questions.map(question => {
            let ans = question.options.find(obj => {
                return obj.text === question.answer
            })
            school.push(ans?.id)
        })
        weiss_record.answers[3].questions.map(question => {
            let ans = question.options.find(obj => {
                return obj.text === question.answer
            })
            life.push(ans?.id)
        })
        weiss_record.answers[4].questions.map(question => {
            let ans = question.options.find(obj => {
                return obj.text === question.answer
            })
            social.push(ans?.id)
        })

        weiss_record.answers[5].questions.map(question => {
            let ans = question.options.find(obj => {
                return obj.text === question.answer
            })
            risky.push(ans?.id)
        })

        let weiss_details = {
            labels: [
                'Family',
                'Learning',
                'School Behaviour',
                'Life Skills',
                'Social Activities',
                'Risky Activities'
            ],
            datasets: [
                {
                    label: "Initial Visit",
                    backgroundColor: "rgba(200,0,0,0.2)",
                    data: [family.reduce((a, b) => a + b) / family.length,
                        learning.reduce((a, b) => a + b) / learning.length,
                        school.reduce((a, b) => a + b) / school.length,
                        life.reduce((a, b) => a + b) / life.length,
                        social.reduce((a, b) => a + b) / social.length,
                        risky.reduce((a, b) => a + b) / risky.length
                    ],

                    borderColor: 'rgba(255, 99, 132, 1)',
                    borderWidth: 1,
                }
            ]
        }
        setWeiss(weiss_details)
    }

    function calculate_dsm() {
        let dsm_record = find_record_by_type(records, 7)

        if (dsm_record === undefined)
            return {labels: [], datasets: []}

        let disturbed = []
        let behaviour = []
        let affective = []

        dsm_record.answers[0].questions.map((question, index) => {
            if (index === 0 || index === 2 || index === 4 || index === 6 || index === 8) {
                let ans = question.options.find(obj => {
                    return obj.text === question.answer
                })
                disturbed.push(ans?.id)
            }
            if (index === 1 || index === 5) {
                let ans = question.options.find(obj => {
                    return obj.text === question.answer
                })
                behaviour.push(ans?.id)
            }
            if (index === 3 || index === 7) {
                let ans = question.options.find(obj => {
                    return obj.text === question.answer
                })
                affective.push(ans?.id)
            }
        })
        let dsm_details = {
            labels: [
                'Disturbed Relatedness',
                'Behavioral Dysregulation',
                'Affective Dysregulation'
            ],
            datasets: [
                {
                    label: "Initial Visit",
                    backgroundColor: "rgba(200,0,0,0.2)",
                    data: [disturbed.reduce((a, b) => a + b) / disturbed.length,
                        behaviour.reduce((a, b) => a + b) / behaviour.length,
                        affective.reduce((a, b) => a + b) / affective.length],
                    borderColor: 'rgba(255, 99, 132, 1)',
                    borderWidth: 1,
                }
            ]
        }
        setDsm(dsm_details)
    }

    useEffect(() => {
        calculate_snap()
        calculate_phq()
        calculate_weiss()
        calculate_dsm()
    }, [patient_id])


    return <>
        <div className='grid grid-cols-2'>
            <button onClick={()=>calculate_snap()}>Generate</button>
        </div>
        <div className="mt-10 flex">
            <div className="8/12">
                <div className="grid sm:grid-cols-2 lg:grid-cols-2 gap-4 sm:gap-6">
                    <div>
                        {
                            snap.datasets.length > 0 ? <AssessmentGraph summary_details={snap} graph_type={'Radar'}/> : null
                        }
                    </div>
                    <div>
                        {
                            phq.datasets.length > 0 ? <AssessmentGraph summary_details={phq} graph_type={'Bar'}/> : null
                        }
                    </div>
                    <div>
                        {
                            weiss.datasets.length > 0 ? <AssessmentGraph summary_details={weiss} graph_type={'Radar'}/> : null
                        }
                    </div>
                    <div>
                        {
                            dsm.datasets.length > 0 ? <AssessmentGraph summary_details={dsm} graph_type={'Radar'}/> : null
                        }
                    </div>
                </div>
            </div>
            <div className="w-4/12">
                <AddPatientNote patient_id={patient_id}/>
            </div>
        </div>
    </>
}