import React, {Fragment} from "react";
import {Question} from "../../record/question";

export const TreatmentInfoForm = ({list = [], setMethod}) => {
    return <>
        <div className="col-12">
            <div className="card">
                <div className="card-body wizard-content">
                    <div className="tab-wizard wizard-circle">
                        <section>
                            <div className="row">
                                {
                                    list.map((section, key) => {
                                        return <Fragment key={key}>
                                            <div className="row col-12 text-uppercase mt-4">
                                                <h4 className='mb-4'><strong>{section?.title}</strong></h4>
                                                <hr className="col-12"/>
                                            </div>
                                            <div className='grid grid-cols-2 gap-4 mt-2'>
                                                {
                                                    section?.questions?.map((question, key) => {
                                                        return (
                                                            <Question key={key} question_data={question} list={list}
                                                                      setMethod={setMethod}
                                                                      parent={section.id}/>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </Fragment>
                                    })
                                }
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </>
}