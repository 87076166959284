import React, {useState} from "react"
import weekly_checkin from "../nurse-checkin/questions/weekly_checkin.json"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faSignature, faUserNurse} from "@fortawesome/free-solid-svg-icons"
import {Signature} from "../../SignaturePad/signature"
import {FORMS} from "../../../constants/forms"
import {FormPageTemplate} from "../../templates/form-page-template"
import {QuestionSectionTemplate} from "../../templates/question-section-template"

export const MonthlyCheckInForm = ({patient_id}) => {
    const [checklist, setChecklist] = useState(weekly_checkin);
    const [fillingUser, setUser] = useState({});
    const [patientRecord, setPatientRecord] = useState({})

    const formSteps = [
        {
            number: '1',
            label: 'Monthly Check-in',
            description: 'Monthly Check-in',
            component: <QuestionSectionTemplate list={checklist} setMethod={setChecklist}/>,
            icon: <FontAwesomeIcon icon={faUserNurse} size={'3x'}/>
        },
        {
            number: '2',
            label: 'Confirmation',
            description: 'Sign & Confirm Check-in',
            component: <Signature setUser={setUser}/>,
            icon: <FontAwesomeIcon icon={faSignature} size={'3x'}/>
        }
    ]

    return <>
        <FormPageTemplate formSteps={formSteps} form_type_id={FORMS.MONTHLY_CHECKIN} patient_id={patient_id}
                          patient_record={patientRecord}/>
    </>
}