import React from "react";
import {Spinner} from "reactstrap";

const Loader = () => {
    return (
        <p className="text-center">
            <br/>
            <br/>
            <Spinner className="text-center" color="success"/>
            <br/> loading...
        </p>
    )
}
export default Loader;