import React, { useEffect, useState } from "react";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import { AppLoadContext } from "components/Context/Appload";

import { AuthContext } from "components/Context/AuthContext";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import Terms from "pages/terms";
import TermsOfUse from "pages/termsofuse";
import { AppLoad } from "components/Loader/appload";
import Login from "pages/patient/login";
import { ToastContainer } from "react-toastify";
import GoogleAnalytics from "components/PageViews";
import Clinician from "pages/clinician/dashboard";
import PrivateRoute from "components/PrivateComponents";

export default function App() {
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(false)


    Sentry.init({
        dsn: "https://0b911b51de144c65866fe1d05b5721a8@o86758.ingest.sentry.io/5616328",
        environment: "production",
        integrations: [
            new Integrations.BrowserTracing(),
        ],
        tracesSampleRate: 1.0,
    });


    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [])

    return (
        <BrowserRouter>
            <ToastContainer />
            <AppLoadContext.Provider value={{ loading, setLoading }}>
                <AppLoad page={
                    <AuthContext.Provider value={{ user, setUser }}>
                        <GoogleAnalytics />
                        <Switch>
                            <Route path="/termsandconditions" component={Terms} />
                            <Route path="/termsofuse" component={TermsOfUse} />
                            <Route path="/login" exact component={Login} />
                            <PrivateRoute path="/*" exact component={Clinician} />
                            <Route path="/termsandconditions" component={Terms} />
                            <Route path="/termsofuse" component={TermsOfUse} />
                            <Redirect from="*" to="/" />
                        </Switch>
                    </AuthContext.Provider>
                } />
            </AppLoadContext.Provider>
        </BrowserRouter>
    );
}
