import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileMedicalAlt, faSignature } from '@fortawesome/free-solid-svg-icons'
import { Signature } from "../../SignaturePad/signature";
import dsm_list from "./questions/dsm_list.json";
import { useAuthentication } from "components/Hooks/AuthHook";
import {FORMS} from "../../../constants/forms";
import {FormPageTemplate} from "../../templates/form-page-template";
import {QuestionSectionTemplate} from "../../templates/question-section-template";

export const DsmClusterForm = ({ patient_id }) => {
    const [questions, setQuestions] = useState(dsm_list)
    const [patientRecord, setPatientRecord] = useState({});
    const [confirmation, setConfirmation] = useState({});
    const [fillingUser, setUser] = useState({});
    let { user } = useAuthentication()
    const sigRef = useRef();
    const formSteps = [
        {
            number: '1',
            label: 'DSM Cluster B',
            description: 'Fill in patient information',
            component: <QuestionSectionTemplate list={questions} setMethod={setQuestions} />,
            icon: <FontAwesomeIcon icon={faFileMedicalAlt} size={'3x'} />
        },
        {
            number: '2',
            label: 'Confirmation',
            description: 'Sign & Confirm Referral',
            component: <Signature setUser={setUser} />,
            icon: <FontAwesomeIcon icon={faSignature} size={'3x'} />
        }
    ]

    return (
        <>
            <FormPageTemplate formSteps={formSteps} form_type_id={FORMS.DSM} patient_id={patient_id}
                              patient_record={patientRecord}/>
        </>
    );
}