import React, {useState, useEffect} from "react";
import axios from "axios";
import {
    Card,
    Col,
    Container,
    Row,
    FormGroup,
    InputGroup,
    Input, Form, InputGroupText, Label
} from "reactstrap";
import {Calendar, momentLocalizer} from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import DatePicker from "react-datepicker";
import {Select, Modal} from "antd";

import "react-datepicker/dist/react-datepicker.css";
import {useAuthentication} from "components/Hooks/AuthHook";
import {CMS_URLS} from "components/Api/Config";
import {Breadcrumbs} from "components/Breadcrumbs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPassport} from "@fortawesome/free-solid-svg-icons";
import {Switch} from "antd";

const localizer = momentLocalizer(moment);

const Appointments = () => {
    const [popoverOpen, setPopoverOpen] = useState(false)
    const [startDate, setStartDate] = useState(new Date());
    const [platform, setPlatform] = useState(false);
    const [record, setRecord] = useState(false);
    const [clickedDate, setClickedDate] = useState(new Date());
    let {user} = useAuthentication()

    const [appointments, setAppointments] = useState([])

    const [practices, setPractices] = useState([])
    const [practice, setPractice] = useState('');

    const breadcrumb = [{'detail': 'Patients', 'link': '/patients'}, {
        'detail': 'Appointments',
        'link': '/appointments'
    }]

    function save_appointment() {
        let details = {
            'user_id': user().id,
            'clinician_id': practice,
            'start_date': startDate,
            'end_date': new Date(startDate.getTime() + 30 * 60000),
            'platform': platform,
            'meeting_id': "random-" + Math.floor(1000000 + Math.random() * 9000000),
            'meeting_name': user().first_name + " - " + practices.find((p, i) => {
                if (p.main_member_id === practice) return p
            })?.main_member_name,
            'attendee_pw': user().first_name,
            'moderator_pw': practices.find((p, i) => {
                if (p.main_member_id === practice) return p
            })?.main_member_name,
            'recording_id': record === true ? "random-" + Math.floor(1000000 + Math.random() * 9000000) : null,
            'record': record
        }

        fetch(CMS_URLS.APPOINTMENTS(user().id_number), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(details)
        })
            .then(res => res.json())
            .then(() => {
                window.location.reload();
            });
    }

    useEffect(() => {

        if (user()?.usertype?.name == "Patient") {
            axios.get(CMS_URLS.USER_APPOINTMENTS(user().id))
                .then(res => {
                    let temp = []
                    res?.data.map(event => {
                        let e = {
                            start: moment(event?.start_date).toDate(),
                            end: moment(event?.end_date).toDate(),
                            title: `${event?.attendees?.first_name} ${event?.attendees?.last_name}`
                        }
                        temp.push(e)
                    })
                    setAppointments(temp)
                })
                .catch(err => console.error(err))
        } else {
            axios.get(CMS_URLS.USER_APPOINTMENTS(user().id))
                .then(res => {
                    let temp = []
                    res?.data.map(event => {
                        let e = {
                            start: moment(event?.start_date).toDate(),
                            end: moment(event?.end_date).toDate(),
                            title: `${event?.attendees?.first_name} ${event?.attendees?.last_name}`
                        }
                        temp.push(e)
                    })
                    setAppointments(temp)
                })
                .catch(err => console.error(err))
        }

    }, [])

    useEffect(() => {
        axios.get(CMS_URLS.PRACTICES)
            .then(res => {
                let temp = []
                res.data.map(x => {
                    user().practice.find(element => {
                        if (element.id === x.id) {
                            temp.push(x)
                        }
                    });
                })
                temp.unshift({id: 0, main_member_id: 0, name: 'Select Practice'})
                setPractices(temp)
            })
            .catch(err => console.error(err))
    }, [])

    const toggle = () => setPopoverOpen(!popoverOpen);

    function eventStyleGetter(event, start, end, isSelected) {
        let style = {
            backgroundColor: '#2dce89',
            borderRadius: '0px',
            opacity: 0.8,
            color: 'white',
            border: '0px',
            display: 'block'
        };
        return {
            style: style
        };
    }

    return (
        <>
            <div className='grid grid-cols-2 gap-4'>
                <div>

                </div>
                <div>


                </div>
            </div>
            <Breadcrumbs items={breadcrumb}/>
            <Modal visible={popoverOpen} title="Request an Appointment" width={'20%'} footer={false} onCancel={toggle}>
                <Form role="form">
                    <FormGroup className="mb-3">
                        <InputGroup className="input-group">
                            <select
                                placeholder="Select Practice"
                                className="text-gray form-select border-2 input-group"
                                aria-label="form-select-lg example"
                                onChange={value => {
                                    setPractice(value.target.value)
                                    console.log("Selected: ", value.target.value)
                                }}>
                                {
                                    practices.map(p => {
                                        return <option key={p.id} value={p.main_member_id}>{p.name}</option>
                                    })
                                }
                            </select>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup className="mb-4 border-2">
                        <InputGroup className="input-group">
                            <InputGroupText className='border-2'>
                                <FontAwesomeIcon icon={faPassport}/>
                            </InputGroupText>
                            <DatePicker
                                className="text-gray form-select border-2"
                                selected={startDate}
                                onChange={date => {
                                    console.log('Date: ', date)
                                    setStartDate(date)
                                }}
                                showTimeSelect
                                timeFormat="p"
                                timeIntervals={30}
                                dateFormat="Pp"
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup className="mb-4">
                        <div className="mt-1">
                            <Switch checkedChildren="Online"
                                    unCheckedChildren="Physical"
                                    onChange={value => setPlatform(value)}
                                    defaultChecked={false}
                            />
                        </div>

                        {
                            platform === true ? <div className="mt-3">
                                <Label>Do you want to record the Meeting?</Label>&nbsp;
                                <Switch checkedChildren="Yes"
                                        unCheckedChildren="No"
                                        onChange={value => setRecord(value)}
                                        defaultChecked={false}
                                />
                            </div> : <></>
                        }
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <InputGroup className="input-group-alternative">
                            <button className='form-control btn-success col-12' onClick={event => {
                                event.preventDefault()
                                save_appointment()
                            }}>Request Booking
                            </button>
                        </InputGroup>
                    </FormGroup>
                </Form>
            </Modal>

            <div className="header pb-8 pt-2 pt-md-8" style={{marginTop: '-5em'}}>

                <Container className="mt--5" fluid>
                    <Row>
                        <div className='col-9'>
                            <div className="col">
                                <br/>
                                <h3 className="mb-0"><strong>Your Appointments</strong></h3>
                            </div>
                        </div>
                        <div className='col-3'>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <div className="input-group mb-4">
                                            <button id='book'
                                                    className='form-control btn-success col-12 align-items-center'
                                                    onClick={toggle}>Request an Appointment
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>

                    <br/>
                    <div className="header-body mt--3">
                        <Row className="justify-content-center align-self-center">
                            <Col lg="12" xl="12">
                                <Card className="card-stats mb-4 mb-xl-0 card-calendar">
                                    <div className="card-body" id='calendar'>
                                        <Calendar className='calendar'
                                                  localizer={localizer}
                                                  events={appointments}
                                                  startAccessor="start"
                                                  endAccessor="end"
                                                  style={{height: 700}}
                                                  selected={setClickedDate}
                                                  eventPropGetter={eventStyleGetter}
                                        />
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                        <br/><br/>

                    </div>
                </Container>
            </div>
        </>
    )
}
export default Appointments;
