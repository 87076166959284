import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileMedicalAlt, faSignature } from '@fortawesome/free-solid-svg-icons'
import { Signature } from "../../SignaturePad/signature";
import questions_list from "./questions/questions_list.json"
import {FORMS} from "../../../constants/forms";
import {FormPageTemplate} from "../../templates/form-page-template";
import {QuestionSectionTemplate} from "../../templates/question-section-template";

export const Snap26Form = ({ patient_id }) => {
    const [questions, setQuestions] = useState(questions_list)
    const [patientRecord, setPatientRecord] = useState({});
    const [fillingUser, setUser] = useState({});
    const formSteps = [
        {
            number: '1',
            label: 'Snap 26',
            description: 'Fill in patient information',
            component: <QuestionSectionTemplate list={questions} setMethod={setQuestions} />,
            icon: <FontAwesomeIcon icon={faFileMedicalAlt} size={'3x'} />
        },
        {
            number: '2',
            label: 'Confirmation',
            description: 'Sign & Confirm Referral',
            component: <Signature setUser={setUser} />,
            icon: <FontAwesomeIcon icon={faSignature} size={'3x'} />
        }
    ];


    return (
        <>
            <FormPageTemplate formSteps={formSteps} form_type_id={FORMS.RTOS} patient_id={patient_id}
                              patient_record={patientRecord}/>
        </>
    );
}