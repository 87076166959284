import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {FORM_URLS} from "components/Api/Config";
import {CheckCircleIcon} from "@heroicons/react/24/solid";
import {NoSymbolIcon} from "@heroicons/react/24/outline";

export default function PatientRecords({patient_id}) {
    const [forms, setForms] = useState([])
    const [records, setRecords] = useState([])
    const [loading, setLoading] = useState(true)

    function form_exists(form_id) {
        if (!records.length > 0) {
            return false
        }

        return records.find(obj => {
            return obj.record_form.id === form_id
        }) !== undefined;
    }

    function form_details(form_id){
        return records.find(obj => {
            return obj.record_form.id === form_id
        })
    }

    useEffect(() => {
        axios.get(FORM_URLS.FORM_TYPES)
            .then(res => {
                setForms(res.data)
            })
            .catch(err => {
                console.error(err)
            })

        axios.get(FORM_URLS.PATIENT_FORMS(patient_id))
            .then(res => {
                setRecords(res.data)
                console.log(res.data)
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => setLoading(false))
    }, [])
    return <>
        <div className="">
            <div className="flow-root">
                <div className="overflow-x-auto">
                    <div className="inline-block min-w-full align-middle ">
                        <div className="overflow-hidden shadow">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-100">
                                <tr>
                                    <th scope="col"
                                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-700 sm:pl-6">
                                        Form Name
                                    </th>
                                    <th scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-700">
                                        Completed
                                    </th>
                                    <th scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-700">
                                        Date Completed
                                    </th>
                                </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white w-full">
                                {
                                    forms.map((form, index) => {
                                        return <tr key={index}>
                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                {form_exists(form.id) ?
                                                    <Link className='text-green-500' to={`/${form.url}/${patient_id}`}>{form.name}</Link> :
                                                    <Link className='text-red-500' to={`/${form.url}/${patient_id}`}>{form.name}</Link>}
                                            </td>
                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                {form_exists(form.id) ?
                                                    <CheckCircleIcon className='text-green-500 h-5 w-auto'/> :
                                                    <NoSymbolIcon className='text-red-500 h-5 w-auto'/>}
                                            </td>
                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                {form_exists(form.id) ?
                                                    new Date(form_details(form?.id).created).toUTCString() : 'Not Completed'
                                                }
                                            </td>

                                        </tr>
                                    })
                                }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}
