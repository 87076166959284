import React, {useEffect, useState} from "react";
import {useParams} from 'react-router-dom';
import 'assets/css/timeline.css'
import {CMS_URLS, FORM_URLS} from "components/Api/Config";
import {PatientDetails} from "components/Patient/patientDetails";
import {PatientSummary} from "components/Patient/patientSummary";
import AddPatientNote from "components/Forms/Note/AddPatientNote";
import {useAuthentication} from "../Hooks/AuthHook";
import axios from "axios";
import Loader from "../Loader";
import {FileDrag} from "./fileDrag";
import PatientRecords from "pages/patient/dashboard/records";
import {useClavkooForms} from "../Hooks/FormHook";
import PatientSectionTabs from "./patient-section-tabs";
import {usePatient} from "../Hooks/PatientHook";
import {PatientDetailsCard} from "../cards/person-card";
import SummaryModal from "../modals/summary-modal";


export default function PatientProfile() {
    let {id} = useParams()
    const [records, setRecords] = useState([])
    const [profile, setProfile] = useState({})
    const [recordsLoaded, setRecordsLoaded] = useState(false)
    const [profileLoaded, setProfileLoaded] = useState(false)
    const [showSummary, setShowSummary] = useState(false)



    useEffect(() => {
        setProfileLoaded(false)
        setRecordsLoaded(false)
        axios.get(CMS_URLS.USER_DETAILS(id))
            .then(res => {
                setProfile(res.data)
            })
            .catch(error => console.error(error))
            .finally(() => setProfileLoaded(true))

        axios.get(FORM_URLS.PATIENT_FORMS(id))
            .then(res => {
                setRecords(res.data)
            })
            .catch(error => console.error(error))
            .finally(() => setRecordsLoaded(true))
    }, [id])

    return <>
        <PatientDetailsCard user={profile} />
        <SummaryModal open={showSummary} setOpen={setShowSummary} patient_id={id}/>
        <main className="mt-4 pb-8">
            <div className="mx-auto max-w-full lg:max-w-full">
                <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8">
                    <div className="grid grid-cols-1 gap-4 lg:col-span-2">
                        <section aria-labelledby="profile-overview-title">
                            <div className="overflow-hidden border-2 border-gray-200 rounded shadow-xl">
                                <div className="bg-white p-6">
                                    <div className="sm:flex sm:items-center sm:justify-between">
                                        <div className="sm:flex sm:space-x-5">
                                            <h2 className="text-base font-medium text-gray-600" id="announcements-title">
                                                Forms/Records
                                            </h2>
                                        </div>
                                        <div className="mt-5 flex justify-center sm:mt-0">
                                            <div onClick={()=>setShowSummary(!showSummary)}
                                                className="flex items-center justify-center rounded-md px-3 py-2 text-sm font-semibold text-white bg-green-500 shadow-sm  hover:bg-green-600 cursor-pointer"
                                            >
                                                View Summaries
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="divide-gray-200 border-t border-gray-200  sm:grid-cols-3 sm:divide-x sm:divide-y-0">
                                    <PatientRecords patient_id={id}/>
                                </div>
                            </div>
                        </section>
                    </div>

                    <div className="grid grid-cols-1 gap-4">
                        <section aria-labelledby="announcements-title">
                            <div className="overflow-hidden bg-white border-2 border-gray-200 rounded shadow-xl">
                                <div className="p-6">
                                    <h2 className="text-base font-medium text-gray-600" id="announcements-title">
                                        Documents
                                    </h2>
                                    <div className="mt-6 flow-root">
                                        <FileDrag patient_id={id}/>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section aria-labelledby="recent-hires-title">
                            <div className="overflow-hidden bg-white border-2 border-gray-200 rounded shadow-xl">
                                <div className="p-6">
                                    <h2 className="text-base font-medium text-gray-700" id="recent-hires-title">
                                        Notes
                                    </h2>
                                    <div className="mt-6 flow-root">
                                        <AddPatientNote patient_id={id}/>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </main>
    </>
}
