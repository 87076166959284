import React, {useState, useEffect} from "react";
import {ClinicalExamForm} from "components/Forms/ClinicalExam";
import {Breadcrumbs} from "components/Breadcrumbs";
import {useParams} from 'react-router-dom';
import {CMS_URLS, PATIENT_URLS} from "components/Api/Config";
import {useClavkooForms} from "components/Hooks/FormHook";
import Record from "components/Reports/Record";
import Loader from "components/Loader";
import * as Sentry from "@sentry/react";
import axios from "axios";
import {FORMS} from "constants/forms";

const Clinical = () => {
    let {id} = useParams();
    const [breadcrumbs, setBreadcrumbs] = useState([])
    const [exists, setExists] = useState(false)
    let {recordExists} = useClavkooForms()
    const [loading, setLoading] = useState(true)
    const [record, setRecord] = useState()

    useEffect(() => {
        axios.get(CMS_URLS.USERS.DETAILS(id))
            .then(res => {
                setBreadcrumbs([{'detail': 'Patients', 'link': '/patients'},
                    {'detail': `${res.data?.first_name} ${res.data?.last_name}`, 'link': `/profile/${id}`}, {'detail': 'Clinical Examination', 'link': `/clinical/${id}`}])
            })
            .catch(err => Sentry.captureException(err))

        axios.get(PATIENT_URLS.PATIENT_RECORD(id, FORMS.CLINICAL_EXAMINATION))
            .then(res => {
                setExists(recordExists(res.data))
                setRecord(res.data)
            })
            .catch(err => Sentry.captureException(err))
            .finally(() => setLoading(false))
    }, [id])

    return (
        <>
            <Breadcrumbs items={breadcrumbs}/>
            {
                loading ? <Loader/> : exists ? <Record patient_record={record}/> : <ClinicalExamForm patient_id={id}/>
            }
        </>
    )
}
export default Clinical;
