export const FORMS = {
    PATIENT_DETAILS: 1,
    CLINICAL_EXAMINATION: 2,
    DEVELOPMENT_ASSESSMENT: 3,
    INTAKE: 4,
    SNAP_26: 5,
    PHQ_GAD: 6,
    DSM: 7,
    PSYCHIATRIC_EVALUATION: 8,
    WEISS: 10,
    MEDICAL_AID: 11,
    CHECKIN: 12,
    TREATMENT: 13,
    RTOS: 14,
    PRE_FOLLOWUP: 15,
    CLINICAL_REPORT: 16,
    PSYCHIATRIST_FOLLOWUP_RX_PLAN: 17,
    PSYCHIATRIST_FOLLOWUP: 18,
    WHODAS_12: 19,
    MONTHLY_CHECKIN: 20,
}