import React, {useState} from "react"
import treatment_questions from "components/Forms/treatment-plan/questions/treatment-questions.json"
import medication_questions from "components/Forms/shared-questions/medications-questions.json"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignature, faUserNurse, faTablets, faFileMedicalAlt} from "@fortawesome/free-solid-svg-icons"
import {Signature} from "components/SignaturePad/signature"
import {FORMS} from "../../../constants/forms"
import {FormPageTemplate} from "../../templates/form-page-template"
import {QuestionSectionTemplate} from "../../templates/question-section-template"

export default function PsychiatristFollowUpRxPlan({patient_id}) {
    const [checklist, setChecklist] = useState(treatment_questions)
    const [medicationList, setMedicationList] = useState(medication_questions)
    const [fillingUser, setUser] = useState({})
    const [patientRecord, setPatientRecord] = useState({})
    const [treatment, setTreatment] = useState(treatment_questions)

    const formSteps = [
        {
            number: '1',
            label: 'Treatment Plan',
            description: 'Treatment Plan',
            component: <QuestionSectionTemplate list={checklist} setMethod={setChecklist}/>,
            icon: <FontAwesomeIcon icon={faUserNurse} size={'3x'}/>
        },
        {
            number: '2',
            label: 'Medication',
            description: 'Medication',
            component: <QuestionSectionTemplate list={medicationList} setMethod={setMedicationList}/>,
            icon: <FontAwesomeIcon icon={faTablets} size={'3x'}/>
        },
        {
            number: '3',
            label: 'Referral to Other Services',
            description: 'Referral to Other Services',
            component: <QuestionSectionTemplate list={treatment} setMethod={setTreatment}/>,
            icon: <FontAwesomeIcon icon={faFileMedicalAlt} size={'3x'}/>
        },
        {
            number: '4',
            label: 'Confirmation',
            description: 'Sign & Confirm Check-in',
            component: <Signature setUser={setUser}/>,
            icon: <FontAwesomeIcon icon={faSignature} size={'3x'}/>
        }
    ]

    return <>
        <FormPageTemplate formSteps={formSteps} form_type_id={FORMS.PSYCHIATRIST_FOLLOWUP_RX_PLAN} patient_id={patient_id}
                          patient_record={patientRecord}/>
    </>
}