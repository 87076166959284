import {Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import {useContext} from "react";
import {AppLoadContext} from "components/Context/Appload";

export const AppLoad = ({page}) => {
    let {loading} = useContext(AppLoadContext);
    const spinnerIcon = <LoadingOutlined style={{fontSize: 48}} spin/>;
    return <Spin spinning={loading} indicator={spinnerIcon} style={{zIndex: 1045}}>{page}</Spin>
}
