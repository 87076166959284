import React, {useEffect, useState} from 'react';
import {UploadOutlined} from '@ant-design/icons';
import axios from "axios";
import {CMS_URLS} from "components/Api/Config";
import Loader from "components/Loader";
import {useAuthentication} from "components/Hooks/AuthHook";
import {ChevronRightIcon, TrashIcon} from "@heroicons/react/24/outline";
import {PaperClipIcon} from "@heroicons/react/20/solid";

export const FileDrag = ({patient_id}) => {
    const [isLoading, setLoading] = useState(false)
    const [documents, setDocuments] = useState([])
    const [details, setDetails] = useState({})
    const [limit, setLimit] = useState(3)
    let {user} = useAuthentication()

    function upload() {
        let formData = new FormData()
        for (const key in details) {
            formData.append(key, details[key])
        }
        formData.append('attachment', details.attachment)
        formData.append('patient', patient_id)
        formData.append('uploaded_by', user().id_number)

        axios.post(CMS_URLS.DOCUMENTS.DOCUMENT, formData)
            .then(() => {
                load_documents()
                setDetails({})
            })
            .catch(err => console.error())
    }

    function load_documents() {
        setLoading(true)
        axios.get(CMS_URLS.DOCUMENTS.PATIENT(patient_id))
            .then(res => {
                setDocuments(res.data)
            })
            .catch(err => console.error(err))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        load_documents()
    }, [patient_id])

    return <>
        <form id='documents' onSubmit={event => {
            event.preventDefault()
            upload()
        }}>
            <input
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm sm:leading-6"
                type="text"
                placeholder="Document name"
                aria-label=".form-control-sm example"
                onChange={event => {
                    setDetails((prevState) => ({
                        ...prevState,
                        [`name`]: event.target.value.trim(),
                    }))
                }}/>
            <input
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm sm:leading-6 mt-2 p-2"
                id="formFileSm"
                type="file"
                onChange={event => {
                    setDetails((prevState) => ({
                        ...prevState,
                        [`attachment`]: event.target.files[0],
                    }))
                }}/>
            <div className="row position-relative">
                <div className="col-md-4 float-right end-0">
                    <div className="form-group">
                        <button
                            className="flex w-full justify-center mt-2 rounded-md bg-green-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            type="submit">Upload
                        </button>
                    </div>
                </div>
            </div>
        </form>


        <ul
            role="list"
            className="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded mt-14"
        >
            {
                !isLoading ? documents.length === 0 ? <div className='p-2'>
                        <p>There are no documents uploaded for this patient.</p>
                    </div> : documents.slice(0, limit).map((document) => (
                        <li key={document.id}
                            className="relative flex justify-between gap-x-6 px-4 py-2 hover:bg-gray-50 sm:px-6">
                            <div className="flex min-w-0 gap-x-2">
                                <PaperClipIcon className="h-6 w-6 text-gray-500"/>
                                <div className="min-w-0 flex-auto">
                                    <p className="text-sm font-semibold leading-6 text-gray-900">
                                        <a target='_blank' href={document.attachment} className='text-gray-500'>
                                            <span className="absolute inset-x-0 -top-px bottom-0"/>
                                            {document.name}
                                        </a>
                                    </p>
                                    <p className="-mt-3 flex text-xs leading-5 text-gray-500">
                                        <p className="relative truncate">
                                            By {document.uploaded_by?.first_name} {document.uploaded_by?.last_name} - {new Date(document?.created).toISOString().slice(0, 10)}
                                        </p>
                                    </p>
                                </div>
                            </div>
                            <div className="flex shrink-0 items-center -mt-4">
                                <TrashIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true"/>
                            </div>
                        </li>
                    )) :
                    <Loader/>

            }
        </ul>
        <div className="mt-6">
            <button
                className="flex w-full items-center justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm bg-green-500 hover:bg-green-600 cursor-pointer"
                onClick={event => {
                    event.preventDefault()
                    setLimit(documents.length)
                }}>
                View all ({documents.length})
            </button>
        </div>
    </>
}