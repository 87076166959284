import React, { useState } from "react";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import clsx from "clsx";
import Loki from "react-loki";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileMedicalAlt, faSignature, faUserFriends, faBriefcase, faBaby } from '@fortawesome/free-solid-svg-icons'
import { Signature } from "../../SignaturePad/signature";
import { HistoryForm } from "./devHistory";
import { ParentsForm } from "./parents";
import { ProfessionalForm } from "./professional";
import { CaregiversForm } from "./childhood";
import { useAuthentication } from "../../Hooks/AuthHook";
import { BaseModel } from "../../models/BaseModel";
import axios from "axios";
import { PATIENT_URLS } from "../../Api/Config";
import { ErrorMsg, SuccessMsg } from "../../Notification";
import * as Sentry from "@sentry/react";


export const DevelopmentalForm = ({ patient_id }) => {
    const [history, setHistory] = useState([])
    const [childhood, setChildhood] = useState([])
    const [parents, setParents] = useState([])
    const [occupation, setOccupation] = useState([])

    const [fillingUser, setUser] = useState({});

    let { user } = useAuthentication()

    const formSteps = [
        {
            number: '1',
            label: 'History',
            description: 'Developmental History',
            component: <HistoryForm history={history} setMethod={setHistory} />,
            icon: <FontAwesomeIcon icon={faFileMedicalAlt} size={'3x'} />
        },
        {
            number: '2',
            label: 'Childhood',
            description: 'Fill info on childhood',
            component: <CaregiversForm childhood={childhood} setMethod={setChildhood} />,
            icon: <FontAwesomeIcon icon={faBaby} size={'3x'} />
        },
        {
            number: '3',
            label: 'Parents',
            description: 'Fill info on parents',
            component: <ParentsForm parents={parents} setMethod={setParents} />,
            icon: <FontAwesomeIcon icon={faUserFriends} size={'3x'} />
        },
        {
            number: '4',
            label: 'School & Work',
            description: 'Fill info on Experience',
            component: <ProfessionalForm occupation={occupation} setMethod={setOccupation} />,
            icon: <FontAwesomeIcon icon={faBriefcase} size={'3x'} />
        },
        {
            number: '5',
            label: 'Signature',
            description: 'Sign & Confirm',
            component: <Signature setUser={setUser} />,
            icon: <FontAwesomeIcon icon={faSignature} size={'3x'} />
        }
    ];


    const _customRenderer = ({ currentStep }) => {
        const steps = formSteps.map((step, index) => {
            const isActive = currentStep === index + 1;
            return <Col lg="6" xl="2"><Card key={index}
                className={clsx("card-box card-stats mb-4 mb-xl-0", { 'border border-primary': isActive })}><a
                    href="#/" onClick={e => e.preventDefault()}>
                    <CardBody>
                        <Row>
                            <div className="col">
                                <div className="step-indicator">
                                    <div className="d-flex">
                                        <div>
                                            <div className="font-weight-bold">{step.label}</div>
                                            <small className="text-black-50">{step.description}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Col className="col-auto">
                                {step.icon}
                            </Col>
                        </Row>
                        <p className="mt-3 mb-0 text-muted text-sm">

                        </p>
                    </CardBody>
                </a></Card></Col>
        });

        return <div className="header pb-3 pt-2 pt-md-5 mt-5">
            <Container fluid>
                <div className="header-body">
                    <Row className="justify-content-center align-self-center">
                        {steps}
                    </Row>
                </div>
            </Container>
        </div>
    };

    const _customActions = ({ isComplete, cantBack, isInFinalStep, backHandler, nextHandler }) => {
        return (
            <div className="actions p-4">
                <Button outline color="primary hover:bg-blue" onClick={backHandler} disabled={cantBack || isComplete}>
                    Previous
                </Button>
                <Button className={`float-right ${!isInFinalStep ? 'bg-blue' : 'bg-green'}`} color={!isInFinalStep ? 'primary' : 'success'} onClick={nextHandler}
                    disabled={isComplete}>
                    {isInFinalStep ? 'Finish' : 'Next'}
                </Button>
            </div>
        );
    };

    const _onFinish = () => {
        if (fillingUser !== null) {
            const saved_history = {
                "id": "1",
                "title": "Developmental History",
                "questions": history
            }
            const saved_childhood = {
                "id": "2",
                "title": "Childhood Caregivers",
                "questions": childhood
            }
            const saved_parents = {
                "id": "3",
                "title": "Parents",
                "questions": parents
            }
            const saved_occupation = {
                "id": "4",
                "title": "School & Work",
                "questions": occupation
            }

            const patient_record = {
                records: [].concat(saved_history).concat(saved_childhood).concat(saved_parents).concat(saved_occupation),
                ...new BaseModel(user.id_number, user.first_name).getBase(),
            }

            axios.post(PATIENT_URLS.PATIENT_RECORD(patient_id, 3), patient_record)
                .then(() => {
                    SuccessMsg('Record Saved.')
                    window.location.reload()
                })
                .catch(error => {
                    Sentry.captureException(error);
                    ErrorMsg('We could not save your record. Please consult admin.')
                })
        }
    };

    return (
        <>
            <div className="wizard-alternate-2 horizontal" style={{ marginTop: '-5em' }}>
                <Loki steps={formSteps}
                    renderSteps={_customRenderer}
                    renderActions={_customActions}
                    onFinish={_onFinish} />
            </div>
        </>
    );
}