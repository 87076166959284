import React from "react";
import {Section} from "components/Forms/Question/Section";

const RiskAssessmentInfo = ({list=[], setMethod}) => {
    return (
        <>
            <div className="col-12">
                <div className="card">
                    <h5 className='px-2 text-center mt-2'>
                        <strong>
                            Answer the questions below as by stating whether you strongly disagree to strongly agree.
                            Kindly click appropriate responses on ALL the questions.
                        </strong>
                    </h5>
                    <div className="card-body wizard-content">
                        <div className="tab-wizard wizard-circle">
                            <section>
                                <div className="row">
                                    {
                                        list.map((section, key) => {
                                            return (
                                                <Section key={key} section={section} list={list} setMethod={setMethod}/>
                                            )
                                        })
                                    }
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default RiskAssessmentInfo;