import React, {useEffect, useState} from "react";
import {DevelopmentalForm} from "components/Forms/Developmental";
import {Breadcrumbs} from "components/Breadcrumbs";
import {useParams} from "react-router-dom";
import axios from "axios";
import * as Sentry from "@sentry/react";
import Loader from "../../components/Loader";
import Record from "../../components/Reports/Record";
import {useClavkooForms} from "components/Hooks/FormHook";
import {PATIENT_URLS} from "components/Api/Config";
import {useAuthentication} from "components/Hooks/AuthHook";
import {FORMS} from "constants/forms";

const Development = () => {
    let {id} = useParams();
    const [user, setUser] = useState(id)
    const breadcrumb = [{'detail': 'Patients', 'link': '/patients'}, {
        'detail': 'Developmental Assessment',
        'link': `/patients/${id}`
    }, {'detail': user, 'link': `/patients/development/${id}`}]
    const [exists, setExists] = useState(false)
    let {recordExists} = useClavkooForms()
    const [loading, setLoading] = useState(true)
    const [record, setRecord] = useState()

    useEffect(() => {
        axios.get(PATIENT_URLS.PATIENT_RECORD(id, FORMS.DEVELOPMENT_ASSESSMENT))
            .then(res => {
                setExists(recordExists(res.data))
                setRecord(res.data)
                setUser(res.data?.[0].record_patient.first_name + ' ' + res.data?.[0].record_patient.last_name)
            })
            .catch(err => Sentry.captureException(err))
            .finally(() => setLoading(false))
    }, [id])

    return (
        <>
            <Breadcrumbs items={breadcrumb}/>
            {loading ?
                <Loader/>
                : exists ? <Record patient_record={record}/> : <DevelopmentalForm patient_id={id}/>
            }
        </>
    )
}
export default Development;
