import React, {useState} from "react";
import {XCircleIcon} from "@heroicons/react/24/outline";

export function InviteForm({setOpen}) {
    const [details, setDetails] = useState({})
    const [loading, setLoading] = useState(false)

    return <>
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center ">
                <div
                    className="">
                    <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                        <form className="space-y-6" onSubmit={event => {
                            event.preventDefault()
                            setLoading(true)
                        }}>
                            <XCircleIcon className="absolute top-3 right-3 text-red-500 h-6 cursor-pointer" onClick={()=>{
                                setOpen(false)
                            }} />

                            <h3 className="text-base font-semibold leading-6 text-gray-900 text-center -mt-2">Invite New
                                Patient</h3>
                            <div>
                                <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-700">
                                    Patient Name
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="name"
                                        name="name"
                                        type="text"
                                        required
                                        onChange={event => {
                                            setDetails((prevState) => ({
                                                ...prevState,
                                                [`name`]: event.target.value,
                                            }))
                                        }}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-700">
                                    Patient Email
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        required
                                        onChange={event => {
                                            setDetails((prevState) => ({
                                                ...prevState,
                                                [`email`]: event.target.value,
                                            }))
                                        }}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="cellphone"
                                       className="block text-sm font-medium leading-6 text-gray-700">
                                    Patient Cellphone
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="cellphone"
                                        name="cellphone"
                                        type="tel"
                                        autoComplete="none"
                                        required
                                        onChange={event => {
                                            setDetails((prevState) => ({
                                                ...prevState,
                                                [`cellphone`]: event.target.value,
                                            }))
                                        }}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                <button
                                    type="button"
                                    className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                                    onClick={() => {
                                        setOpen(false)
                                    }}
                                >
                                    {loading ? "Loading..." : 'Invite Patient'}
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                    onClick={() => setOpen(false)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    </>
}
