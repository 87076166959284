import React, { useState } from 'react';
import axios from 'axios';
import { PATIENT_URLS } from 'components/Api/Config';
import { ErrorMsg, SuccessMsg } from 'components/Notification';
import * as Sentry from '@sentry/react';
import Loki from 'react-loki';

export function FormPageTemplate({ patient_id, patient_record, formSteps = [], form_type_id }) {
    const [progress, setProgress] = useState(0);
    const totalSteps = formSteps.length;

    const _customRenderer = ({ currentStep }) => {
        const currentProgress = ((currentStep - 1) / totalSteps) * 100;
        setProgress(currentProgress);

        const steps = formSteps.map((step, index) => {
            const isActive = currentStep === index + 1;
            return (
                <div key={index} className={`cursor-pointer text-green-600 ${isActive ? 'font-bold' : ''}`}>
                    <div className="grid grid-cols-6">
                        <div className="col-span-1">{step.icon}</div>
                        <div className="col-span-4">
                            <h1 className={`${isActive ? 'font-bold text-green-600' : ''}`}>{step?.label}</h1>
                            <h1>{step?.description}</h1>
                        </div>
                    </div>
                </div>
            );
        });

        return (
            <div>
                <div className="mt-24 bg-gray-100 p-6 rounded border-2 border-gray-200" aria-hidden="true">
                    <div className="overflow-hidden rounded-full bg-gray-200">
                        <div className="h-2 rounded-full bg-green-600" style={{ width: `${progress}%` }} />
                    </div>
                    <div className={`mt-6 hidden grid-cols-${formSteps.length} gap-2 text-sm font-medium text-gray-600 sm:grid`}>
                        {steps}
                    </div>
                </div>
            </div>
        );
    };

    const _customActions = ({ isComplete, cantBack, isInFinalStep, backHandler, nextHandler }) => {
        return (
            <div className="actions p-4 grid grid-cols-2 mb-20 mt-10">
                <hr className="col-span-2 mb-6" />
                <button
                    onClick={backHandler}
                    disabled={cantBack || isComplete}
                    className="flex w-48 justify-center rounded-md border border-transparent bg-gray-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                    Previous
                </button>
                <button
                    onClick={nextHandler}
                    className="flex w-48 justify-center align-right rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ml-auto"
                >
                    {isInFinalStep ? 'Finish' : 'Next'}
                </button>
            </div>
        );
    };

    const _onFinish = () => {
        axios.post(PATIENT_URLS.PATIENT_RECORD(patient_id, form_type_id), patient_record)
            .then(() => {
                SuccessMsg('Record Saved.');
                window.location.reload();
            })
            .catch(error => {
                Sentry.captureException(error);
                ErrorMsg('We could not save your record. Please consult admin.');
            });
    };

    return (
        <div className="wizard-alternate-2 horizontal" style={{ marginTop: '-5em' }}>
            <Loki steps={formSteps} renderSteps={_customRenderer} renderActions={_customActions} onFinish={_onFinish} />
        </div>
    );
}
