import React from "react";
import Question from "./index";

export const Section = ({ section, list, setMethod }) => {
    return (
        <>
            {
                section.title !== '' ?
                    <div className="row col-12 text-uppercase pb-5">
                        <hr className="col-12 pt--5" />
                        <h4 className='mb-4 text-base'>
                            <strong>{section.title}</strong>
                            {
                                section.guideline !== undefined ? ` - ${section.guideline}` : null
                            }
                        </h4>
                    </div>
                    : null
            }
            {
                section.questions.map((question, key) => {
                    return (
                        <Question key={key} question_data={question} list={list} setMethod={setMethod}
                            parent={section.id} />
                    )
                })
            }
        </>
    )
}