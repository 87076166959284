import React, { useState } from "react";
import { Card, CardBody, Row } from "reactstrap";
import { Switch, Input } from "antd";

export const ParentsForm = ({ parents, setMethod }) => {
    const [parentsAlive, setParentsAlive] = useState(true)
    const [separated, setSeparated] = useState(false)
    const [bothFinancial, setBothFinancial] = useState(true)
    const [parentsRelations, setParentRelations] = useState('Stable/Supportive')
    const [motherRelations, setMothersRelations] = useState('Stable/Supportive')
    const [fatherRelations, setFatherRelations] = useState('Stable/Supportive')
    const [momHistory, setMomHistory] = useState(false)
    const [dadHistory, setDadHistory] = useState(false)
    const [motherAlive, setMotherAlive] = useState(true)
    const [fatherAlive, setfatherAlive] = useState(true)

    function set_answer(id, question, answer) {
        let exists = parents.find(function (sec) {
            return sec.id === id
        })

        if (exists) {
            let temp = parents
            temp[parents.indexOf(exists)] = {
                id: id,
                type: 'text',
                question: question,
                answer: answer
            }
            setMethod(temp)
        } else {
            setMethod([
                ...parents,
                {
                    id: id,
                    type: 'text',
                    question: question,
                    answer: answer
                }
            ])
        }
    }

    return (
        <div className="col-12">
            <div className="card">
                <div className="card-body wizard-content">
                    <div className="tab-wizard wizard-circle">
                        <section>
                            <div className="row">
                                <div className="col-md-12">
                                    <h4 className='text-uppercase'><strong>Parental Background</strong></h4>
                                    <br />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="lastName1">
                                                    <strong>Marital Status: </strong>
                                                </label>
                                            </div>
                                            <div className="col-md-6">
                                                <select className="custom-select mr-sm-2 form-horizontal"
                                                    id="inlineFormCustomSelect" onChange={e => {
                                                        if (e.target.value === 'Separated/Divorced' || e.target.value === 'Never Married') {
                                                            setSeparated(true)
                                                        } else {
                                                            setSeparated(false)
                                                        }
                                                        set_answer(1, 'Marital Status', e.target.value)
                                                    }}>
                                                    <option defaultValue>Choose...</option>
                                                    <option value="Married">Married</option>
                                                    <option value="Cohabiting">Cohabiting</option>
                                                    <option value="Separated/Divorced">Separated/Divorced</option>
                                                    <option value="Never Married">Never Married</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {
                                separated ? (
                                    <>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="lastName1">Custody Arrangements: </label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <select className="custom-select mr-sm-2 form-horizontal"
                                                                id="inlineFormCustomSelect"
                                                                onChange={event => {
                                                                    set_answer(2, 'Custody Arrangements', event.target.value)
                                                                }}
                                                            >
                                                                <option defaultValue>Equal</option>
                                                                <option value="Mother - Father with Visitations">Mother - Father with Visitations
                                                                </option>
                                                                <option value="Father - Mother with Visitations">Father - Mother with Visitations
                                                                </option>
                                                                <option value="External Parties">External Parties</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="lastName1">Primary Residence: </label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <select className="custom-select mr-sm-2 form-horizontal"
                                                                id="inlineFormCustomSelect"
                                                                onChange={event => {
                                                                    set_answer(3, 'Primary Residence', event.target.value)
                                                                }}
                                                            >
                                                                <option defaultValue>Equal</option>
                                                                <option value="Mother - Father with Visitations">Mother - Father with Visitations
                                                                </option>
                                                                <option value="Father - Mother with Visitations">Father - Mother with Visitations
                                                                </option>
                                                                <option value="External Parties">External Parties</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h4><strong>Financial Support</strong></h4>
                                                <br />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="lastName1">Both Parents: </label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Switch checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                defaultChecked={bothFinancial}
                                                                onChange={() => {
                                                                    setBothFinancial(!bothFinancial)
                                                                    set_answer(4, 'Both Parents', bothFinancial ? 'Yes' : 'No')
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                !bothFinancial ?
                                                    <>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <label htmlFor="lastName1">Father's support
                                                                            ordered by court: </label>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <Switch checkedChildren="Yes"
                                                                            unCheckedChildren="No"
                                                                            defaultChecked={false}
                                                                            onChange={checked => set_answer(5, 'Fathers Support', checked ? 'Yes' : 'No')}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <label htmlFor="lastName1">Mother's support
                                                                            ordered by court: </label>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <Switch checkedChildren="Yes"
                                                                            unCheckedChildren="No"
                                                                            defaultChecked={false}
                                                                            onChange={checked => set_answer(6, 'Mothers Support', checked ? 'Yes' : 'No')}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </> : null
                                            }
                                        </div>
                                    </>
                                ) : null
                            }

                            <div className="row">
                                <div className="col-md-12 py-4">
                                    <hr />
                                    <br />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="firstName1">Both Biological Parents alive? </label>
                                            </div>
                                            <div className="col-md-6">
                                                <Switch checkedChildren="Yes"
                                                    unCheckedChildren="No"
                                                    defaultChecked={parentsAlive}
                                                    onChange={e => {
                                                        setParentsAlive(e)
                                                        if (e) {
                                                            setfatherAlive(true)
                                                            setMotherAlive(true)
                                                        }
                                                        set_answer(7, 'Both Biological Parents alive', e ? 'Yes' : 'No')
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    !parentsAlive ?
                                        (<>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="lastName1">Mother Deceased? </label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Switch checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                defaultChecked={!motherAlive}
                                                                onChange={e => {
                                                                    setMotherAlive(!e)
                                                                    set_answer(8, 'Mother Deceased', e ? 'Yes' : 'No')
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="firstName1">Father Deceased? </label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Switch checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                defaultChecked={!fatherAlive}
                                                                onChange={e => {
                                                                    setfatherAlive(!e)
                                                                    set_answer(9, 'Father Deceased', e ? 'Yes' : 'No')
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>) : null}

                            </div>

                            <div className="row">
                                {
                                    parentsAlive ?
                                        <div className="col-md-4">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor="firstName1">Relationship between parents
                                                    </label>
                                                </div>
                                                <div className="col-md-6">
                                                    <select className="custom-select mr-sm-2 form-horizontal"
                                                        id="inlineFormCustomSelect"
                                                        onChange={event => {
                                                            setParentRelations(event.target.value)
                                                            set_answer(10, 'Relationship between parents', event.target.value)
                                                        }}

                                                    >
                                                        <option defaultValue
                                                            value="Stable/Supportive">Stable/Supportive
                                                        </option>
                                                        <option value="Non-Existent">Non-Existent</option>
                                                        <option value="Conflictual">Conflictual</option>
                                                    </select>
                                                </div>
                                            </div>

                                            {
                                                parentsRelations === 'Conflictual' ?
                                                    <>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <label htmlFor="lastName1">Conflict
                                                                            Type: </label>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <select
                                                                            className="custom-select mr-sm-2 form-horizontal"
                                                                            id="inlineFormCustomSelect"
                                                                            onChange={event => {
                                                                                set_answer(11, 'Conflict type', event.target.value)
                                                                            }
                                                                            }
                                                                        >
                                                                            <option defaultValue>Choose...</option>
                                                                            <option value="Domestic Violence">Domestic Violence</option>
                                                                            <option value="Verbal Arguments">Verbal Arguments</option>
                                                                            <option value="Both">Both</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </> : null
                                            }
                                        </div> : null
                                }
                            </div>
                            <div className="row">
                                {
                                    !fatherAlive ?
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label htmlFor="firstName1">Patient's Age at Father's Passing(in
                                                            years): </label>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <Input
                                                            placeholder="Age in Years"
                                                            type="text"
                                                            autoComplete="new-email"
                                                            onChange={event => {
                                                                setParentRelations(event.target.value)
                                                                set_answer(11, 'Patients Age at Fathers passing in years', event.target.value)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : null
                                }
                                {
                                    !motherAlive ?
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label htmlFor="firstName1">Patient's Age at Mother's Passing(in
                                                            years): </label>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <Input
                                                            placeholder="Age in Years"
                                                            type="text"
                                                            autoComplete="new-email"
                                                            onChange={event => {
                                                                setParentRelations(event.target.value)
                                                                set_answer(12, 'Patients Age at mothers passing(in years)', event.target.value)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : null
                                }

                                {
                                    motherAlive ?
                                        <div className="col-md-4 mt-2">
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label htmlFor="firstName1">Mother's Relationship with
                                                            Patient: </label>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <select className="custom-select mr-sm-2 form-horizontal"
                                                            id="inlineFormCustomSelect"
                                                            onChange={event => {
                                                                set_answer(13, 'Mothers relationship with patient', event.target.value)
                                                                setMothersRelations(event.target.value)
                                                            }}
                                                        >
                                                            <option defaultValue value="Stable/Supportive">Stable &
                                                                Supportive
                                                            </option>
                                                            <option value="Non-Existent">Non-Existent</option>
                                                            <option value="Conflictual">Distant</option>
                                                            <option value="Conflictual">Conflictual</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : null
                                }

                                {
                                    fatherAlive ?
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label htmlFor="firstName1">Father's Relationship with
                                                            Patient: </label>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <select className="custom-select mr-sm-2 form-horizontal"
                                                            id="inlineFormCustomSelect"
                                                            onChange={event => {
                                                                setFatherRelations(event.target.value)
                                                                set_answer(14, 'Fathers relationship with patient', event.target.value)
                                                            }}
                                                        >
                                                            <option defaultValue value="Stable/Supportive">Stable &
                                                                Supportive
                                                            </option>
                                                            <option value="Non-Existent">Non-Existent</option>
                                                            <option value="Conflictual">Distant</option>
                                                            <option value="Conflictual">Conflictual</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                        </div> : null
                                }
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="py-4">
                                        <hr />
                                    </div>

                                    <h4 className='text-uppercase'><strong>Family Psychiatric History</strong></h4>
                                    <br />
                                </div>
                            </div>

                            <div className="row">
                                <Card className="card-box card-stats mb-4 mb-xl-1 border border-primary col-md-6">
                                    <CardBody>
                                        <Row>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="firstName1">
                                                                <strong>
                                                                    Mother's Side Psychiatric History Present:
                                                                </strong>
                                                            </label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Switch checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                defaultChecked={momHistory}
                                                                onChange={() => setMomHistory(!momHistory)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Row>
                                        {
                                            momHistory ? <>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <label htmlFor="firstName1">Depression: </label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <Switch checkedChildren="Yes"
                                                                        unCheckedChildren="No"
                                                                        defaultChecked={false}
                                                                        onChange={event => {
                                                                            set_answer(15, 'Mother has depression', event ? 'Yes' : 'No')
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <label htmlFor="firstName1">Anxiety: </label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <Switch checkedChildren="Yes"
                                                                        unCheckedChildren="No"
                                                                        defaultChecked={false}
                                                                        onChange={event => {
                                                                            set_answer(16, 'Mother has anxiety', event ? 'Yes' : 'No')
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <label htmlFor="firstName1">Bipolar
                                                                        Disorder: </label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <Switch checkedChildren="Yes"
                                                                        unCheckedChildren="No"
                                                                        defaultChecked={false}
                                                                        onChange={event => {
                                                                            set_answer(17, 'Mother has bipolar disorder', event ? 'Yes' : 'No')
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <label htmlFor="firstName1">Addiction: </label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <Switch checkedChildren="Yes"
                                                                        unCheckedChildren="No"
                                                                        defaultChecked={false}
                                                                        onChange={event => {
                                                                            set_answer(18, 'Mother has addiction', event ? 'Yes' : 'No')
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <label htmlFor="firstName1">ADHD: </label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <Switch checkedChildren="Yes"
                                                                        unCheckedChildren="No"
                                                                        defaultChecked={false}
                                                                        onChange={event => {
                                                                            set_answer(18, 'Mother has ADHD', event ? 'Yes' : 'No')
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <label htmlFor="firstName1">Learning
                                                                        Problems: </label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <Switch checkedChildren="Yes"
                                                                        unCheckedChildren="No"
                                                                        defaultChecked={false}
                                                                        onChange={event => {
                                                                            set_answer(19, 'Mother has learning problems', event ? 'Yes' : 'No')
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <label htmlFor="firstName1">Unknown: </label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <Switch checkedChildren="Yes"
                                                                        unCheckedChildren="No"
                                                                        defaultChecked={false}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-2">
                                                                    <label htmlFor="firstName1">Other: </label>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <Input
                                                                        placeholder="Other"
                                                                        type="text"
                                                                        autoComplete="new-email"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </> : null
                                        }
                                    </CardBody>
                                </Card>

                                <Card className="card-box card-stats mb-4 mb-xl-1 border border-primary col-md-6">
                                    <CardBody>
                                        <Row>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="firstName1">
                                                                <strong>
                                                                    Father's Side Psychiatric History Present:
                                                                </strong>
                                                            </label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Switch checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                defaultChecked={dadHistory}
                                                                onChange={() => setDadHistory(!dadHistory)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Row>
                                        {
                                            dadHistory ? <>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <label htmlFor="firstName1">Depression: </label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <Switch checkedChildren="Yes"
                                                                        unCheckedChildren="No"
                                                                        defaultChecked={false}
                                                                        onChange={event => {
                                                                            set_answer(20, 'Father has depression', event ? 'Yes' : 'No')
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <label htmlFor="firstName1">Anxiety: </label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <Switch checkedChildren="Yes"
                                                                        unCheckedChildren="No"
                                                                        defaultChecked={false}
                                                                        onChange={event => {
                                                                            set_answer(21, 'Father has anxiety', event ? 'Yes' : 'No')
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <label htmlFor="firstName1">Bipolar
                                                                        Disorder: </label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <Switch checkedChildren="Yes"
                                                                        unCheckedChildren="No"
                                                                        defaultChecked={false}
                                                                        onChange={event => {
                                                                            set_answer(22, 'Father has bipolar disorder', event ? 'Yes' : 'No')
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <label htmlFor="firstName1">Addiction: </label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <Switch checkedChildren="Yes"
                                                                        unCheckedChildren="No"
                                                                        defaultChecked={false}
                                                                        onChange={event => {
                                                                            set_answer(23, 'Father has addiction', event ? 'Yes' : 'No')
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <label htmlFor="firstName1">ADHD: </label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <Switch checkedChildren="Yes"
                                                                        unCheckedChildren="No"
                                                                        defaultChecked={false}
                                                                        onChange={event => {
                                                                            set_answer(24, 'Father has ADHD', event ? 'Yes' : 'No')
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <label htmlFor="firstName1">Learning
                                                                        Problems: </label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <Switch checkedChildren="Yes"
                                                                        unCheckedChildren="No"
                                                                        defaultChecked={false}
                                                                        onChange={event => {
                                                                            set_answer(25, 'Father has learning problems', event ? 'Yes' : 'No')
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <label htmlFor="firstName1">Unknown: </label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <Switch checkedChildren="Yes"
                                                                        unCheckedChildren="No"
                                                                        defaultChecked={false}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-2">
                                                                    <label htmlFor="firstName1">Other: </label>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <Input
                                                                        placeholder="Other"
                                                                        type="text"
                                                                        autoComplete="new-email"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </> : null
                                        }
                                    </CardBody>
                                </Card>
                            </div>
                            <div className='row'>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="lastName1">Notes: </label>
                                        <textarea className="form-control" rows="3" placeholder="Text Here..." />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}