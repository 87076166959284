import Loader from "components/Loader";
import React, {useEffect, useState} from "react";
import {faFileMedical} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import axios from "axios";
import {CLINICIAN_URLS} from "components/Api/Config";
import {useAuthentication} from "components/Hooks/AuthHook";
import {Link} from "react-router-dom";
import {CalendarIcon, DocumentDuplicateIcon, UserPlusIcon, UsersIcon} from "@heroicons/react/24/outline";

export default function StatCard({item, url, title, background, stat_prop = null, icon = faFileMedical, path}) {
    const [loading, setLoading] = useState(true)
    const [stat, setStat] = useState('')
    let {user} = useAuthentication()

    useEffect(() => {
        setLoading(true)
        axios.get(url)
            .then(res => {
                if (stat_prop === null)
                    setStat(res?.data?.length)
                else
                    setStat(res?.data[stat_prop])
            })
            .catch(err => console.error(err))
            .finally(() => setLoading(false))
    }, [url])

    return <>
        <div
            key={title}
            className="relative overflow-hidden rounded border-2 border-green-500 bg-white px-4 pb-12 pt-5 shadow-2xl sm:px-6 sm:pt-6"
        >
            <dt>
                <div className="absolute rounded-md bg-green-500 p-3">
                    {
                        icon === 'UsersIcon' ? <UsersIcon className="h-6 w-6 text-white" aria-hidden="true"/> :
                            icon === 'UserPlusIcon' ?
                                <UserPlusIcon className="h-6 w-6 text-white" aria-hidden="true"/> :
                                icon === 'CalendarIcon' ?
                                    <CalendarIcon className="h-6 w-6 text-white" aria-hidden="true"/> :
                                    <DocumentDuplicateIcon className="h-6 w-6 text-white" aria-hidden="true"/>
                    }

                </div>
                <p className="ml-16 truncate text-sm font-medium text-gray-500">{title}</p>
            </dt>
            <dd className="ml-16 flex items-baseline pb-4 sm:pb-5">
                <p className="text-2xl font-semibold text-gray-900 -mt-4">
                    {loading ? <Loader/> : stat ?? '...'}
                </p>
                <div className="absolute inset-x-0 bottom-0 bg-green-500 px-4 py-2 sm:px-6">
                    <div className="text-sm text-right">
                        <Link to={path} className="font-medium text-white text-right hover:text-green-700">
                            View all<span className="sr-only"> {title} stats</span>
                        </Link>
                    </div>
                </div>
            </dd>
        </div>
    </>
}