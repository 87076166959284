import axios from "axios";
import {ApiRoutes} from "@clavkoo/api-service";

export const useSharedAuthentication = () => {
    function request_otp(email, cellphone) {
        if (email === '' || cellphone === '')
            return

        return axios.get(ApiRoutes.AUTHENTICATION.USER_OTP, {
            params: {
                "email": email,
                "cellphone": cellphone
            }
        })
    }

    function login_user(username, password) {
        if (username === '' || password === '')
            return
        return axios.post(ApiRoutes.AUTHENTICATION.LOGIN, {
            "username": username,
            "password": password
        })
    }

    return {request_otp, login_user}
}
