import React from "react";
import {Radar, Bar} from 'react-chartjs-2';

export const AssessmentGraph = ({summary_details, graph_type = 'Radar'}) => {
    const options = {
        scale: {
            ticks: {beginAtZero: true},
        },
    }
    const config = {
        type: 'bar',
        data: summary_details.datasets,
        options: {
            indexAxis: 'y',
            // Elements options apply to all of the options unless overridden in a dataset
            // In this case, we are setting the border of each horizontal bar to be 2px wide
            elements: {
                bar: {
                    borderWidth: 2,
                }
            },
            responsive: true,
            plugins: {
                legend: {
                    position: 'right',
                },
                title: {
                    display: true,
                    text: 'Chart.js Horizontal Bar Chart'
                }
            }
        },
    }
    return (
        <>
            {
                graph_type === 'Radar' ? <Radar data={summary_details} options={options}/> : null
            }
            {
                graph_type === 'Bar' ? <Bar data={summary_details} options={config}  /> : null
            }
        </>
    )
}