import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useClavkooForms} from "components/Hooks/FormHook";
import {PATIENT_URLS} from "components/Api/Config";
import {Breadcrumbs} from "components/Breadcrumbs";
import Record from "components/Reports/Record";
import Loader from "components/Loader";
import * as Sentry from "@sentry/react";
import IntakeForm from "../../components/Forms/Intake";
import axios from "axios";
import {FORMS} from "constants/forms";

const Intake = () => {
    let {id} = useParams();
    const [user, setUser] = useState(id)
    const breadcrumb = [{'detail': 'Patients', 'link': '/patients'}, {
        'detail': 'Intake Form',
        'link': `/patients/${id}`
    }, {'detail': id, 'link': `/patients/intake/${id}`}]
    const [exists, setExists] = useState(false)
    let {recordExists} = useClavkooForms()
    const [loading, setLoading] = useState(true)
    const [record, setRecord] = useState()

    useEffect(() => {
        axios.get(PATIENT_URLS.PATIENT_RECORD(id, FORMS.INTAKE))
            .then(res => {
                setExists(recordExists(res.data))
                setRecord(res.data)
                setUser(res.data?.[0].record_patient.first_name + ' ' + res.data?.[0].record_patient.last_name)
            })
            .catch(err => Sentry.captureException(err))
            .finally(() => setLoading(false))
    }, [id])

    return (
        <>
            <Breadcrumbs items={breadcrumb}/>
            {
                loading ? <Loader/> : exists ? <Record patient_record={record}/> : <IntakeForm patient_id={id}/>
            }
        </>
    )
};
export default Intake;