import React, {useEffect, useRef, useState} from "react";
import {Button, Card, CardBody, Col, Container, Row} from "reactstrap";
import clsx from "clsx";
import Loki from "react-loki";
import {ProblemListForm} from "./problemListForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileMedicalAlt, faHeartbeat, faSignature, faStethoscope} from '@fortawesome/free-solid-svg-icons'
import {Signature} from "../../SignaturePad/signature";
import {MedicalConditionForm} from "./medicalConditionForm";
import problem_questions from "./questions/problem_list.json"
import medical_list from "./questions/medical_list.json"
import other_list from "./questions/other_conditions.json"
import {OtherConditionForm} from "components/Forms/ClinicalExam/otherConditionForm";
import {BaseModel} from "components/models/BaseModel";
import {useAuthentication} from "components/Hooks/AuthHook";
import {FORM_URLS, PATIENT_URLS} from "components/Api/Config";
import axios from "axios";
import {ErrorMsg, SuccessMsg} from "../../Notification";
import * as Sentry from "@sentry/react";
import {FORMS} from "../../../constants/forms";
import {FormPageTemplate} from "../../templates/form-page-template";
import {QuestionSectionTemplate} from "../../templates/question-section-template";

export const ClinicalExamForm = ({patient_id}) => {

    const [problemList, setProblemList] = useState(problem_questions)
    const [medicalConditions, setConditions] = useState(medical_list)
    const [otherConditions, setOtherConditions] = useState(other_list)
    const [fillingUser, setUser] = useState({});
    const [patientRecord, setPatientRecord] = useState({});
    let {user} = useAuthentication()


    const formSteps = [
        {
            number: '1',
            label: 'Problem List',
            description: 'Fill in problem list',
            component: <QuestionSectionTemplate list={problemList} setMethod={setProblemList}/>,
            icon: <FontAwesomeIcon icon={faFileMedicalAlt} size={'3x'}/>
        },
        {
            number: '2',
            label: 'Medical Conditions',
            description: 'Fill in medical conditions',
            component: <QuestionSectionTemplate list={medicalConditions} setMethod={setConditions}/>,
            icon: <FontAwesomeIcon icon={faHeartbeat} size={'3x'}/>
        },
        {
            number: '3',
            label: 'Examination',
            description: 'Fill in examination',
            component: <QuestionSectionTemplate list={otherConditions} setMethod={setOtherConditions}/>,
            icon: <FontAwesomeIcon icon={faStethoscope} size={'3x'}/>
        },
        {
            number: '4',
            label: 'Confirmation',
            description: 'Sign & Confirm Referral',
            component: <Signature setUser={setUser}/>,
            icon: <FontAwesomeIcon icon={faSignature} size={'3x'}/>
        }
    ];


    return (
        <>
            <FormPageTemplate formSteps={formSteps} form_type_id={FORMS.CLINICAL_EXAMINATION} patient_id={patient_id}
                              patient_record={patientRecord}/>
        </>
    );
}
