import React from "react";
import 'antd/dist/antd.min.css'
import {Section} from "components/Forms/Question/Section";

export const WeeklyCheckinInfoForm = ({list = [], setMethod}) => {
    return (
        <>
            <div className="col-12">
                <div className="card">
                    <div className="card-body wizard-content">
                        <div className="tab-wizard wizard-circle">
                            <section>
                                <div className="row">
                                    {
                                        list.map((section, key) => {
                                            return (
                                                <Section key={key} section={section} list={list} setMethod={setMethod}/>
                                            )
                                        })
                                    }
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
