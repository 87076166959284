import React, {useRef, useState} from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faBookMedical, faFileMedicalAlt, faSignature, faTablets} from '@fortawesome/free-solid-svg-icons'
import {Signature} from "../../SignaturePad/signature"
import {useAuthentication} from "components/Hooks/AuthHook"
import problem_list from "components/Forms/ClinicalExam/questions/problem_list.json"
import medication_questions from "components/Forms/shared-questions/medications-questions.json"
import {DiagnosisInfo} from "components/Forms/evaluation/diagnosisInfo"
import risk_list from "components/Forms/evaluation/questions/risk_list.json"
import {FORMS} from "constants/forms";
import referral_questions from "../shared-questions/referral_services.json";
import {FormPageTemplate} from "../../templates/form-page-template";
import {QuestionSectionTemplate} from "../../templates/question-section-template";

export const RtosForm = ({patient_id}) => {
    const [problemList, setProblemList] = useState(problem_list);
    const [medicationList, setMedicationList] = useState(medication_questions);
    const [psych, setPsych] = useState([]);
    const [referralList, setReferralList] = useState(referral_questions);
    const [riskList, setRiskList] = useState(risk_list)
    const [confirmation, setConfirmation] = useState({});
    const [patientRecord, setPatientRecord] = useState({});
    const [fillingUser, setUser] = useState({});
    let {user} = useAuthentication()
    const sigRef = useRef();
    const formSteps = [
        {
            number: '1',
            label: 'Problem List',
            description: 'Fill in problem list',
            component: <QuestionSectionTemplate list={problemList} setMethod={setProblemList}/>,
            icon: <FontAwesomeIcon icon={faFileMedicalAlt} size={'3x'}/>
        },
        {
            number: '2',
            label: 'Medication',
            description: 'Medication',
            component: <QuestionSectionTemplate list={medicationList} setMethod={setMedicationList}/>,
            icon: <FontAwesomeIcon icon={faTablets} size={'3x'}/>
        },
        {
            number: '3',
            label: 'Diagnosis',
            description: 'Fill in patient diagnosis',
            component: <DiagnosisInfo patient_id={patient_id} psych={psych} setMethod={setPsych}/>,
            icon: <FontAwesomeIcon icon={faFileMedicalAlt} size={'3x'}/>
        },
        {
            number: '4',
            label: 'Risk Assessment',
            description: 'Fill in patient risk assessment',
            component: <QuestionSectionTemplate list={riskList} setMethod={setRiskList}/>,
            icon: <FontAwesomeIcon icon={faFileMedicalAlt} size={'3x'}/>
        }, {
            number: '5',
            label: 'Referrals',
            description: 'Referral to Other Services',
            component: <QuestionSectionTemplate list={referralList} setMethod={setReferralList}/>,
            icon: <FontAwesomeIcon icon={faBookMedical} size={'3x'}/>
        },
        {
            number: '6',
            label: 'Confirmation',
            description: 'Sign & Confirm Check-in',
            component: <Signature setUser={setUser}/>,
            icon: <FontAwesomeIcon icon={faSignature} size={'3x'}/>
        }
    ];


    return <>
        <FormPageTemplate formSteps={formSteps} form_type_id={FORMS.RTOS} patient_id={patient_id}
                          patient_record={patientRecord}/>
    </>
}