import React, {useState} from "react";
import {Input, Select, Switch} from "antd";
import {Card, CardBody, Row} from "reactstrap";

const {Option} = Select;

export const HistoryForm = ({history, setMethod}) => {
    const [complicatedBirth, setComplicatedBirth] = useState(false)
    const [birthType, setBirthType] = useState('')
    const [birthTerm, setBirthTerm] = useState(true)
    const [complications, setComplications] = useState(false)
    const [milestone, setMilestone] = useState(false)
    const [hivStatus, setHivStatus] = useState(false)
    const [complicationsDelivery, setComplicationsDelivery] = useState(false)

    function set_answer(id, question, answer) {
        let exists = history.find(function (sec) {
            return sec.id === id
        })

        if (exists) {
            let temp = history
            temp[history.indexOf(exists)] = {
                id: id,
                type: 'text',
                question: question,
                answer: answer
            }
            setMethod(temp)
        } else {
            setMethod([
                ...history,
                {
                    id: id,
                    type: 'text',
                    question: question,
                    answer: answer
                }
            ])
        }
    }

    return (
        <div className="col-12">
            <div className="card">
                <div className="card-body wizard-content">
                    <div className="tab-wizard wizard-circle">
                        <section>
                            <div className='row'>
                                <div className='col-12'>
                                    <h4><strong>Pregnancy & Birth History</strong></h4>
                                    <br/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="firstName1">Planned pregnancy: </label>
                                            </div>
                                            <div className="col-md-6">
                                                <Switch checkedChildren="Yes"
                                                        unCheckedChildren="No"
                                                        defaultChecked={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4 col-sm-12">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="firstName1">Age of Mother During Pregnancy: </label>
                                            </div>
                                            <div className="col-md-6">
                                                <Select
                                                    style={{width: '100%'}}
                                                    defaultValue="Choose..."
                                                    onChange={value => {
                                                        set_answer(1, 'Age of mother during pregnancy', value)
                                                    }}
                                                >
                                                    {
                                                        Array.from(Array(55).keys()).slice(10, 54).map(age => {
                                                            return <Option key={age} value={age}>{age}</Option>
                                                        })
                                                    }
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="lastName1">How far along in pregnancy was the mother, when she realised she was pregnant: </label>
                                            </div>
                                            <div className="col-md-6">
                                                <Select className="col-12"
                                                        id="inlineFormCustomSelect"
                                                        onChange={value => {
                                                            set_answer(2, 'How far along in pregnancy was the mother, when she realised she was pregnant', value)
                                                        }}
                                                >
                                                    <option defaultValue>Choose...</option>
                                                    <option value="Less than 1 Month">Less than 1 Month</option>
                                                    <option value="1 - 3 Months">1 - 3 Months</option>
                                                    <option value="3 - 6 Months">3 - 6 Months</option>
                                                    <option value="More than 6 Months">More than 6 Months</option>
                                                    <option value="Unknown">Unknown</option>
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="lastName1">Exposure to Cigarettes before or after mother
                                                    realised she was pregnant: </label>
                                            </div>
                                            <div className="col-md-6">
                                                <Switch checkedChildren="Yes"
                                                        unCheckedChildren="No"
                                                        defaultChecked={false}
                                                        onChange={value => {
                                                            set_answer(3, 'Exposure to Cigarettes before or after mother realised she was pregnant', value ? 'Yes' : 'No')
                                                        }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="lastName1">Exposure to Alcohol before or after mother
                                                    realised she was pregnant: </label>
                                            </div>
                                            <div className="col-md-6">
                                                <Switch checkedChildren="Yes"
                                                        unCheckedChildren="No"
                                                        defaultChecked={false}
                                                        onChange={value => {
                                                            set_answer(4, 'Exposure to Cigarettes before or after mother realised she was pregnant', value ? 'Yes' : 'No')
                                                        }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-4">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="lastName1">Lots of Emotional Stress before or after mother
                                                    realised she was pregnant: </label>
                                            </div>
                                            <div className="col-md-6">
                                                <Switch checkedChildren="Yes"
                                                        unCheckedChildren="No"
                                                        defaultChecked={false}
                                                        onChange={value => {
                                                            set_answer(5, 'Lots of emotional stress before or after mother realised she was pregnant', value ? 'Yes' : 'No')
                                                        }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="lastName1">Hypertension during pregnancy: </label>
                                            </div>
                                            <div className="col-md-6">
                                                <Switch checkedChildren="Yes"
                                                        unCheckedChildren="No"
                                                        defaultChecked={false}
                                                        onChange={value => {
                                                            set_answer(6, 'Hypertension during pregnancy', value ? 'Yes' : 'No')
                                                        }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="lastName1">HIV Status during pregnancy: </label>
                                            </div>
                                            <div className="col-md-6">
                                                <Switch checkedChildren="Positive"
                                                        unCheckedChildren="Negative"
                                                        defaultChecked={false}
                                                        onChange={value => {
                                                            setHivStatus(!hivStatus)
                                                            set_answer(7, 'HIV status during pregnancy', value ? 'Positive' : 'Negative')

                                                        }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {
                                    hivStatus ?
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label htmlFor="lastName1">Mother on Medication: </label>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <Switch checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                defaultChecked={false}
                                                                onChange={value => {
                                                                    set_answer(8, 'Mother on medication', value ? 'Yes' : 'No')
                                                                }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                }


                                <div className="col-md-4">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="firstName1">Full Birth Term: </label>
                                            </div>
                                            <div className="col-md-6">
                                                <Switch checkedChildren="Yes"
                                                        unCheckedChildren="No"
                                                        defaultChecked={birthTerm}
                                                        onChange={value => {
                                                            setBirthTerm(!birthTerm)
                                                            set_answer(9, 'Full birth term', value ? 'Yes' : 'No')

                                                        }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    !birthTerm ?
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label htmlFor="lastName1">Gestation Period at Birth in
                                                            Weeks: </label>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <Select className="col-12"
                                                                id="inlineFormCustomSelect"
                                                                onChange={value => {
                                                                    set_answer(10, 'Gestation period at birth in weeks', value)
                                                                }}
                                                        >
                                                            <option defaultValue>Choose...</option>
                                                            <option value="Unknown">Unknown</option>
                                                            <option value="Less than 24">Less than 24</option>
                                                            <option value="24.1 - 28">24.1 – 28</option>
                                                            <option value="28.1 - 36">28.1 - 36</option>
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                }

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="firstName1">Mode of Delivery: </label>
                                            </div>
                                            <div className="col-md-6">
                                                <Select className="col-12"
                                                        id="inlineFormCustomSelect" onChange={value => {
                                                    (value === 'Caesarean Section') ? setComplicatedBirth(true) : setComplicatedBirth(false)
                                                    setBirthType(value)
                                                    set_answer(11, 'Mode of delivery', value)
                                                }} value={birthType}>
                                                    <option defaultValue>Choose...</option>
                                                    <option value="Normal Vaginal Delivery">Normal Vaginal Delivery</option>
                                                    <option value="Assisted with Devices">Assisted with Devices</option>
                                                    <option value="Caesarean Section">Caesarean Section</option>
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    complicatedBirth ? (
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label htmlFor="firstName1">Reason for Caesarean
                                                            Section: </label>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <Select className="col-12"
                                                                id="inlineFormCustomSelect"
                                                                onChange={value => {
                                                                    set_answer(12, 'Reason for ceasarean section', value)
                                                                }}
                                                        >
                                                            <option defaultValue>Choose...</option>
                                                            <option value="N/A">N/A</option>
                                                            <option value="Breech Presentation">Breech Presentation</option>
                                                            <option value="Foetal Distress">Foetal Distress</option>
                                                            <option value="Poor Labour Progress">Poor Labour Progress</option>
                                                            <option value="Hypertensive Crisis">Hypertensive Crisis</option>
                                                            <option value="Elective">Elective</option>
                                                            <option value="Emergency">Emergency</option>
                                                            <option value="Other">Other</option>
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null
                                }
                            </div>

                            <div className="row">
                                <Card className="card-box card-stats mb-4 mb-xl-1 border border-primary col-md-6">
                                    <CardBody>
                                        <Row>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="firstName1">
                                                                <strong>
                                                                    Complications During Delivery
                                                                </strong>
                                                            </label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Switch checkedChildren="Yes"
                                                                    unCheckedChildren="No"
                                                                    defaultChecked={complicationsDelivery}
                                                                    onChange={value => {
                                                                        setComplicationsDelivery(!complicationsDelivery)
                                                                        set_answer(13, 'Complications during delivery', value ? 'Yes' : 'No')
                                                                    }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Row>
                                        {
                                            complicationsDelivery ? <>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <label htmlFor="firstName1">Poor Breathing: </label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <Switch checkedChildren="Yes"
                                                                            unCheckedChildren="No"
                                                                            defaultChecked={false}
                                                                            onChange={value => {
                                                                                set_answer(14, 'Poor breathing', value ? 'Yes' : 'No')
                                                                            }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <label htmlFor="firstName1">Infection and
                                                                        Sepsis: </label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <Switch checkedChildren="Yes"
                                                                            unCheckedChildren="No"
                                                                            defaultChecked={false}
                                                                            onChange={value => {
                                                                                set_answer(15, 'Infection and sepsis', value ? 'Yes' : 'No')
                                                                            }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <label htmlFor="firstName1">Floppy: </label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <Switch checkedChildren="Yes"
                                                                            unCheckedChildren="No"
                                                                            defaultChecked={false}
                                                                            onChange={value => {
                                                                                set_answer(16, 'Floppy', value ? 'Yes' : 'No')
                                                                            }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <label htmlFor="firstName1">ICU Admission: </label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <Switch checkedChildren="Yes"
                                                                            unCheckedChildren="No"
                                                                            defaultChecked={false}
                                                                            onChange={value => {
                                                                                set_answer(17, 'ICU admission', value ? 'Yes' : 'No')
                                                                            }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <label htmlFor="firstName1">Maternal
                                                                        Complications: </label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <Switch checkedChildren="Yes"
                                                                            unCheckedChildren="No"
                                                                            defaultChecked={false}
                                                                            onChange={value => {
                                                                                set_answer(18, 'Maternal complications', value ? 'Yes' : 'No')
                                                                            }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-3">
                                                                    <label htmlFor="firstName1">Other: </label>
                                                                </div>
                                                                <div className="col-md-9">
                                                                    <Input type="text"
                                                                           className="form-control col-12"
                                                                           onChange={value => {
                                                                               set_answer(19, 'Other', value)
                                                                           }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </> : null
                                        }
                                    </CardBody>
                                </Card>


                                <Card className="card-box card-stats mb-4 mb-xl-1 border border-primary col-md-6">
                                    <CardBody>
                                        <Row>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="firstName1">
                                                                <strong>
                                                                    Neonatal Complications
                                                                </strong>
                                                            </label>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <Switch checkedChildren="Yes"
                                                                    unCheckedChildren="No"
                                                                    defaultChecked={complications}
                                                                    onChange={value => {
                                                                        setComplications(!complications)
                                                                        set_answer(20, 'Neonatal complications', value ? 'Yes' : 'No')
                                                                    }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Row>
                                        {
                                            complications ? <>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <label htmlFor="firstName1">ICU
                                                                        Ventilation: </label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <Switch checkedChildren="Yes"
                                                                            unCheckedChildren="No"
                                                                            defaultChecked={false}
                                                                            onChange={value => {
                                                                                set_answer(21, 'ICU ventilation', value ? 'Yes' : 'No')
                                                                            }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <label htmlFor="firstName1">Infection and
                                                                        Sepsis: </label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <Switch checkedChildren="Yes"
                                                                            unCheckedChildren="No"
                                                                            defaultChecked={false}
                                                                            onChange={value => {
                                                                                set_answer(22, 'Infection & sepsis', value ? 'Yes' : 'No')
                                                                            }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <label htmlFor="firstName1">Jaundice: </label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <Switch checkedChildren="Yes"
                                                                            unCheckedChildren="No"
                                                                            defaultChecked={false}
                                                                            onChange={value => {
                                                                                set_answer(23, 'Jaundice', value ? 'Yes' : 'No')
                                                                            }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <label htmlFor="firstName1">Meningitis: </label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <Switch checkedChildren="Yes"
                                                                            unCheckedChildren="No"
                                                                            defaultChecked={false}
                                                                            onChange={value => {
                                                                                set_answer(24, 'Meningitis', value ? 'Yes' : 'No')
                                                                            }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-3">
                                                                    <label htmlFor="firstName1">Other: </label>
                                                                </div>
                                                                <div className="col-md-9">
                                                                    <Input type="text"
                                                                           className="form-control col-12"
                                                                           onChange={value => {
                                                                               set_answer(25, 'Other', value)
                                                                           }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </> : null
                                        }
                                    </CardBody>
                                </Card>
                            </div>

                            <br/>

                            <div className="row">
                                <div className="col-md-12">
                                    <hr/>
                                    <h4><strong>Postpartum and Infancy Period</strong></h4>
                                    <br/>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="firstName1">Relatively Easy Baby: </label>
                                            </div>
                                            <div className="col-md-6">
                                                <Switch checkedChildren="Yes"
                                                        unCheckedChildren="No"
                                                        defaultChecked={false}
                                                        onChange={value => {
                                                            set_answer(26, 'Relatively easy baby', value ? 'Yes' : 'No')
                                                        }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="firstName1">Colicky: </label>
                                            </div>
                                            <div className="col-md-6">
                                                <Switch checkedChildren="Yes"
                                                        unCheckedChildren="No"
                                                        defaultChecked={false}
                                                        onChange={value => {
                                                            set_answer(27, 'Colicky', value ? 'Yes' : 'No')
                                                        }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="firstName1">Infection & Sepsis: </label>
                                            </div>
                                            <div className="col-md-6">
                                                <Switch checkedChildren="Yes"
                                                        unCheckedChildren="No"
                                                        defaultChecked={false}
                                                        onChange={value => {
                                                            set_answer(28, 'Infection & sepsis', value ? 'Yes' : 'No')
                                                        }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="firstName1">Meningitis: </label>
                                            </div>
                                            <div className="col-md-6">
                                                <Switch checkedChildren="Yes"
                                                        unCheckedChildren="No"
                                                        defaultChecked={false}
                                                        onChange={value => {
                                                            set_answer(29, 'Meningitis', value ? 'Yes' : 'No')
                                                        }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="firstName1">Poor Sleep: </label>
                                            </div>
                                            <div className="col-md-6">
                                                <Switch checkedChildren="Yes"
                                                        unCheckedChildren="No"
                                                        defaultChecked={false}
                                                        onChange={value => {
                                                            set_answer(30, 'Poor sleep', value ? 'Yes' : 'No')
                                                        }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="firstName1">Maternal Baby Blues: </label>
                                            </div>
                                            <div className="col-md-6">
                                                <Switch checkedChildren="Yes"
                                                        unCheckedChildren="No"
                                                        defaultChecked={false}
                                                        onChange={value => {
                                                            set_answer(31, 'Maternal baby blues', value ? 'Yes' : 'No')
                                                        }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="firstName1">Maternal Depression: </label>
                                            </div>
                                            <div className="col-md-6">
                                                <Switch checkedChildren="Yes"
                                                        unCheckedChildren="No"
                                                        defaultChecked={false}
                                                        onChange={value => {
                                                            set_answer(32, 'Maternal depression', value ? 'Yes' : 'No')
                                                        }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="firstName1">Maternal death: </label>
                                            </div>
                                            <div className="col-md-6">
                                                <Switch checkedChildren="Yes"
                                                        unCheckedChildren="No"
                                                        defaultChecked={false}
                                                        onChange={value => {
                                                            set_answer(33, 'Maternal death', value ? 'Yes' : 'No')
                                                        }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="firstName1">Seizures: </label>
                                            </div>
                                            <div className="col-md-6">
                                                <Switch checkedChildren="Yes"
                                                        unCheckedChildren="No"
                                                        defaultChecked={false}
                                                        onChange={value => {
                                                            set_answer(34, 'Seizures', value ? 'Yes' : 'No')
                                                        }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <label htmlFor="firstName1">Other: </label>
                                            </div>
                                            <div className="col-md-10">
                                                <input type="text"
                                                       className="form-control col-12"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <hr/>
                                    <h4><strong>Developmental Milestones</strong></h4>
                                    <br/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="firstName1">Developmental Milestones Concerns?: </label>
                                            </div>
                                            <div className="col-md-6">
                                                <Switch checkedChildren="Yes"
                                                        unCheckedChildren="No"
                                                        defaultChecked={milestone}
                                                        onChange={value => {
                                                            setMilestone(!milestone)
                                                            set_answer(35, 'Developmental milestone concerns', value ? 'Yes' : 'No')
                                                        }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    milestone ? <div className="col-md-4">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor="firstName1">Developmental Delays: </label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Select className="col-12"
                                                            id="inlineFormCustomSelect"
                                                            onChange={value => {
                                                                set_answer(36, 'Developmental Delays', value)
                                                            }}
                                                            placeholder={'Choose...'}
                                                    >
                                                        <option value="Unknown">Unknown</option>
                                                        <option value="Speech Delay">Speech Delay</option>
                                                        <option value="Delayed Motor">Delayed Motor</option>
                                                        <option value="Global Delays">Global Delays</option>
                                                        <option value="Regressed">Regressed</option>
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : null
                                }

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="firstName1">Temperament During Childhood: </label>
                                            </div>
                                            <div className="col-md-6">
                                                <Select className="col-12"
                                                        id="inlineFormCustomSelect"
                                                        onChange={value => {
                                                            set_answer(37, 'Temperament During Childhood', value ? 'Yes' : 'No')
                                                        }}
                                                        placeholder={'Choose...'}
                                                >
                                                    <option value="Easy">Easy</option>
                                                    <option value="Difficult">Difficult</option>
                                                    <option value="Shy">Shy</option>
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className='row'>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="lastName1">Notes: </label>
                                        <textarea className="form-control" rows="3" placeholder="Text Here..."/>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}