import {APP_ID} from "../Api/Config";

function store_value(key, value) {
    localStorage.setItem(key, JSON.stringify(value))
}

function get_value(key) {
    let res = localStorage.getItem(key)
    if (res !== undefined) {
        return JSON.parse(res)
    }
    return null
}

function clear_storage() {
    localStorage.clear()
}

export const isLogin = () => {
    if (localStorage.getItem(APP_ID)) {
        return true;
    }
    return false;
}


export const useAuthentication = () => {
    function user() {
        return get_value(APP_ID)
    }

    function get_practice() {
        const practices = user()?.practice;
        if (Array.isArray(practices) && practices.length) {
            return practices[0].practice_no
        }
    }

    function hasPermission(permission) {
        if (user() !== null) {
            let permissions = user().permissions;
            if (permissions === undefined) {
                clear_storage()
                return true
            }
            let perm = permissions.find(o => o.name === permission);
            return perm !== undefined;
        }
        return true
    }

    function setAuth(details) {
        store_value(APP_ID, details)
    }

    function logout() {
        clear_storage()
        window.location.href = "/";
    }

    function isPatient(){
        return user()?.user_type?.id === 4
    }

    return {user, setAuth, logout, hasPermission, get_practice, isLogin, isPatient}
}
