import React, { useState } from "react";
import { Input } from "antd";
import { AutoComplete } from 'antd';


const OriginalOptions = [
    { value: 'Personal health problems' },
    { value: 'Illiteracy and low-level literacy' },
    { value: 'Schooling unavailable and unattainable' },
    { value: 'Failed examinations' },
    { value: 'Underachievement in school' },
    { value: 'Educational maladjustment and discord with teachers and classmates' },
    { value: 'Unemployment, unspecified' },
    { value: 'Change of job' },
    { value: 'Threat of job loss' },
    { value: 'Stressful work schedule' },
    { value: 'Discord with boss and workmates' },
    { value: 'Difficult conditions at work' },
    { value: 'Inadequate housing' },
    { value: 'Discord with neighbours, lodgers and landlord' },
    { value: 'Problems related to living in residential institution Boarding-school resident' },
    { value: 'Extreme poverty' },
    { value: 'Problems of adjustment to life-cycle transitions' },
    { value: 'Problems related to a parenting situation (rearing of children) as a single parent' },
    { value: 'Living alone' },
    { value: 'Migration' },
    { value: 'Social exclusion and rejection' },
    { value: 'Target of perceived adverse discrimination and persecution' },
    { value: 'Loss of love relationship in childhood' },
    { value: 'Removal from home in childhood' },
    { value: 'Altered pattern of family relationships in childhood' },
    { value: 'Events resulting in loss of self-esteem in childhood' },
    { value: 'Problems related to alleged sexual abuse of child' },
    { value: 'Problems related to alleged physical abuse of child' },
    { value: 'Personal frightening experience in childhood' },
    { value: 'Other negative life events in childhood' },
    { value: 'Inadequate parental supervision and control' },
    { value: 'Hostility towards and scapegoating of child' },
    { value: 'Emotional neglect of child' },
    { value: 'Inappropriate parental pressure and other abnormal qualities of upbringing' },
    { value: 'Problems in relationship with spouse or partner' },
    { value: 'Domestic violence' },
    { value: 'Problems in relationship with parents and in-laws' },
    { value: 'Inadequate family support' },
    { value: 'Absence of family member' },
    { value: 'Disappearance and death of family member' },
    { value: 'Assumed death of family member' },
    { value: 'Disruption of family by separation and divorce Estrangement' },
    { value: 'Dependent relative needing care at home' },
    { value: 'Health problems within family' },
    { value: 'High expressed emotional level within family Inadequate or distorted communication within family' },
    { value: 'Problems related to other legal circumstances' }
]

export const StressesInfo = ({ stressors, setMethod }) => {
    const [options, setOptions] = useState([]);
    const onSearch = (searchText) => {
        setOptions(
            !searchText ? [] : mockVal(searchText),
        );
    };

    function mockVal(search) {
        return OriginalOptions.filter(function (str) {
            return str.value.toLowerCase().includes(search.toLowerCase())
        })
    }

    function set_answer(id, question, answer) {
        let exists = stressors.find(function (sec) {
            return sec.id === id
        })

        if (exists) {
            let temp = stressors
            temp[stressors.indexOf(exists)] = {
                id: id,
                type: 'text',
                question: question,
                answer: answer
            }
            setMethod(temp)
        } else {
            setMethod([
                ...stressors,
                {
                    id: id,
                    type: 'text',
                    question: question,
                    answer: answer
                }
            ])
        }
    }

    return (
        <>
            <div className="col-12">
                <div className="card">
                    <div className="card-body wizard-content">
                        <div className="tab-wizard wizard-circle">
                            <div className="row col-12 mt-1 mb-5 text-uppercase">
                                <h4 className='mb-4'><strong>Please list top 5 stressors</strong></h4>
                                <hr className="col-12 pt--5" />
                            </div>
                            <section>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <label htmlFor="firstName1">First Stressor:</label>
                                                </div>
                                                <div className="col-md-12">
                                                    <AutoComplete
                                                        dropdownClassName="certain-category-search-dropdown col-12"
                                                        dropdownMatchSelectWidth={500}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        options={options}
                                                        onSearch={onSearch}
                                                        onChange={value => {
                                                            set_answer(1 , value)
                                                        }}
                                                    >
                                                        <Input.Search size="large" placeholder="Type Stressor"
                                                            className="form-control col-12" />
                                                    </AutoComplete>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <label htmlFor="firstName1">Second Stressor:</label>
                                                </div>
                                                <div className="col-md-12">
                                                    <AutoComplete
                                                        dropdownClassName="certain-category-search-dropdown col-12"
                                                        dropdownMatchSelectWidth={500}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        options={options}
                                                        onSearch={onSearch}
                                                        onChange={value => {
                                                            set_answer(2, value)
                                                        }}
                                                    >
                                                        <Input.Search size="large" placeholder="Type Stressor"
                                                            className="form-control col-12" />
                                                    </AutoComplete>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <label htmlFor="firstName1">Third Stressor:</label>
                                                </div>
                                                <div className="col-md-12">
                                                    <AutoComplete
                                                        dropdownClassName="certain-category-search-dropdown col-12"
                                                        dropdownMatchSelectWidth={500}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        options={options}
                                                        onSearch={onSearch}
                                                        onChange={value => {
                                                            set_answer(3,  value)
                                                        }}
                                                    >
                                                        <Input.Search size="large" placeholder="Type Stressor"
                                                            className="form-control col-12" />
                                                    </AutoComplete>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <label htmlFor="firstName1">Fourth Stressor:</label>
                                                </div>
                                                <div className="col-md-12">
                                                    <AutoComplete
                                                        dropdownClassName="certain-category-search-dropdown col-12"
                                                        dropdownMatchSelectWidth={500}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        options={options}
                                                        onSearch={onSearch}
                                                        onChange={value => {
                                                            set_answer(4, value)
                                                        }}
                                                    >
                                                        <Input.Search size="large" placeholder="Type Stressor"
                                                            className="form-control col-12" />
                                                    </AutoComplete>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <label htmlFor="firstName1">Fifth Stressor:</label>
                                                </div>
                                                <div className="col-md-12">
                                                    <AutoComplete
                                                        dropdownClassName="certain-category-search-dropdown col-12"
                                                        dropdownMatchSelectWidth={500}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        options={options}
                                                        onSearch={onSearch}
                                                        onChange={value => {
                                                            set_answer(5, value)
                                                        }}
                                                    >
                                                        <Input.Search size="large" placeholder="Type Stressor"
                                                            className="form-control col-12" />
                                                    </AutoComplete>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}