import React, {useState} from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faFileMedicalAlt, faSignature, faTablets} from '@fortawesome/free-solid-svg-icons'
import {Signature} from "../../SignaturePad/signature"
import problem_list from "components/Forms/ClinicalExam/questions/problem_list.json"
import medication_questions from "components/Forms/shared-questions/medications-questions.json"
import {FORMS} from "constants/forms";
import {FormPageTemplate} from "../../templates/form-page-template";
import {QuestionSectionTemplate} from "../../templates/question-section-template";

export const PreFollowUpForm = ({patient_id}) => {
    const [problemList, setProblemList] = useState(problem_list)
    const [medicationList, setMedicationList] = useState(medication_questions)
    const [fillingUser, setUser] = useState({})
    const [patientRecord, setPatientRecord] = useState({})

    const formSteps = [
        {
            number: '1',
            label: 'Problem List',
            description: 'Fill in problem list',
            component: <QuestionSectionTemplate list={problemList} setMethod={setProblemList}/>,
            icon: <FontAwesomeIcon icon={faFileMedicalAlt} size={'3x'}/>
        },
        {
            number: '2',
            label: 'Medication',
            description: 'Medication',
            component: <QuestionSectionTemplate list={medicationList} setMethod={setMedicationList}/>,
            icon: <FontAwesomeIcon icon={faTablets} size={'3x'}/>
        },
        {
            number: '3',
            label: 'Confirmation',
            description: 'Sign & Confirm Check-in',
            component: <Signature setUser={setUser}/>,
            icon: <FontAwesomeIcon icon={faSignature} size={'3x'}/>
        }
    ]

    return <>
        <FormPageTemplate formSteps={formSteps} form_type_id={FORMS.PRE_FOLLOWUP} patient_id={patient_id}
                          patient_record={patientRecord}/>
    </>
}